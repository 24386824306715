import { useCallback, useEffect, useState } from "react";
import {
  ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
  useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation,
  UserType,
} from "../../../graphql/types";

type StartDateActivationInfo = Pick<
  ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
  "chamberMemberHasAcceptedAgreement"
>;

const useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement =
  ({
    activeMemberProfileAndPerksBannersSettings,
    userType,
  }: {
    activeMemberProfileAndPerksBannersSettings:
      | { activationInfo: StartDateActivationInfo }
      | undefined
      | null;
    userType: UserType | "";
  }) => {
    const [
      chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
      setChamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
    ] = useState(false);

    useEffect(() => {
      if (
        activeMemberProfileAndPerksBannersSettings?.activationInfo
          ?.chamberMemberHasAcceptedAgreement ||
        userType !== UserType.ChamberMemberAdmin
      ) {
        setChamberMemberMemberProfileAndPerksBannersAgreementIsAccepted(true);
      }
    }, [
      activeMemberProfileAndPerksBannersSettings?.activationInfo
        ?.chamberMemberHasAcceptedAgreement,
      userType,
    ]);

    const [
      acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember,
    ] =
      useAcceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMemberMutation();

    const handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement =
      useCallback(async () => {
        acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember(
          {
            refetchQueries: [
              "getAllDiscountsFromChamberId",
              "getAllDiscountsFromChamberMemberId",
            ],
          }
        );

        setChamberMemberMemberProfileAndPerksBannersAgreementIsAccepted(true);
      }, [
        acceptChamberMemberAgreementForActiveMemberProfileAndPerksBannersOfChamberMember,
      ]);

    return {
      chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
      handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
    };
  };

export default useHandleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement;

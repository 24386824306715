import React, { ReactElement } from "react";

const TextBlob = ({
  title,
  description,
}: {
  title: string;
  description: string[];
}): ReactElement => (
  <div>
    <span style={{ fontWeight: "bold" }}>{title}</span>
    {description.map((text, index) => (
      <div key={index} style={{ marginLeft: text.includes("•") ? 10 : 0 }}>
        <div style={{ height: 5 }} />
        <span>{text}</span>
      </div>
    ))}
    <div style={{ height: 20 }} />
  </div>
);

const PrivacyPolicy = (): ReactElement => {
  return (
    <div style={{ paddingTop: 10 }}>
      <div>
        <div style={{ padding: 10 }}>
          <span style={{ fontWeight: "bold", textAlign: "center" }}>
            Chamber Perks Privacy Policy
          </span>
          <div style={{ height: 20 }} />
          <span>Last modified: August 18, 2022</span>
          <div style={{ height: 20 }} />
          <TextBlob
            title="Introduction"
            description={[
              '1000270862 Ontario Limited, operating as Your Sales Company, and our affiliates, subsidiaries, employees, and contractors ("Company" or "We") respect your privacy and are committed to protecting it by complying with this policy.',
              "This policy describes:",
              '• The types of information we may collect or that app users ("you") may provide when you licence purchase, download, install, register with, access, or use the Chamber Perks App™ or the Chambers Perk Member Portal (collectively, the "App"); and',
              "• Our practices for collecting, using, maintaining, protecting, and disclosing that information.",
              "We will only use your personal information in accordance with this policy unless otherwise required by applicable law. We take steps to ensure that the personal information that we collect about you is adequate, relevant, not excessive, and used for limited purposes.",
              'Privacy laws in Canada generally define "personal information" as any information about an identifiable individual, which includes information that can be used on its own or with other information to identify, contact, or locate a single person. Personal information does not include business contact information, including your name, title, or business contact information.',
              "This policy applies only to information we collect in this App and, in email, text, and other electronic communications sent through or in connection with this App.",
              "This policy DOES NOT apply to information that:",
              "• We collect offline or on any other Company apps or websites, including websites you may access through this App. ",
              "• You provide to or is collected by any third party (see Third-Party Information Collection).",
              "Our websites and apps, and these third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.",
              "Please read this policy carefully to understand our policies and practices for collecting, processing, and storing your information. If you do not agree with our policies and practices, do not download, register with, or use this App. By downloading, registering with, or using this App, you indicate that you understand, accept, and consent to the practices described in this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this App after we make changes indicates that you accept and consent to those changes, so please check the policy periodically for updates.",
            ]}
          />

          <TextBlob
            title="INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT"
            description={[
              "We collect information about you through:",
              "• Direct interactions with you when you provide it to us, for example, by filling in forms or corresponding with us. ",
              "• Automated technologies or interactions, when you use the App, for example, usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.",
              "The types of information that we collect include:",
              '• Personal information that we can reasonably use to directly or indirectly identify you, such as your name, mailing address, email address, telephone number, internet protocol (IP) address, user name or other similar identifier, billing and account information, , and any other identifier we may use to contact you ("personal information"). ',
              "We provide an opportunity for any user opt-out of contact for marketing purposes on an ongoing basis by emailing to privacy@chamberperksapp.com.",
              "• Non-personal information that does not directly or indirectly reveal your identity or directly relate to an identified individual, such as, demographic information, or statistical or aggregated information. We may derive non-personal statistical or aggregated data from personal information. For example, we may aggregate personal information to calculate the percentage of users accessing a specific app feature.",
              "• Technical information, including your login information, device type, time zone setting, and usage details.",
            ]}
          />

          <TextBlob
            title="INFORMATION YOU PROVIDE TO US"
            description={[
              "When you download, register with, or use this App, we may ask you to provide:",
              "• Information by filling in forms in the App. This includes information you provide when registering to use the App, subscribing to our service, posting material, and/or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with the App.",
              "• Records and copies of your correspondence, including email addresses and phone numbers, if you contact us.",
              "• Your responses to surveys that we might ask you to complete for research or other purposes.",
              "• Details of transactions you carry out through the App and order fulfillment. We may require you to provide financial information before placing an order through the App.",
              "• Your search queries on the App.",
              'You may also provide information for publication or display ("posted") on public areas of websites you access through the App (collectively, "User Contributions"). You post and transmit User Contributions to others at your own risk. Please be aware that no security measures are perfect. We cannot control the actions of third parties with whom you may choose to share your User Contributions. Therefore, we do not guarantee that unauthorized persons will not view your User Contributions.',
            ]}
          />

          <TextBlob
            title="AUTOMATIC INFORMATION COLLECTION AND TRACKING TECHNOLOGIES"
            description={[
              "When you download, access, and use the App, it may automatically collect:",
              "• Usage details. When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App. ",
              "• Device information. We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, mobile network information, and the device's telephone number.",
              "• Stored information and files. The App also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.",
              "• Location information. This App collects real-time information about the location of your device.",
              "The technologies we use for this automatic data collection may include:",
              "• Cookies (or mobile cookies). A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting you may be unable to access certain parts of our App.",
              "• Web beacons. Pages of the App and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity). ",
              "The information we collect automatically is statistical information and may include personal information. We may maintain it or associate it with personal information we collect in other ways, that you provide to us, or receive from third parties. This usage information helps us to improve our App and to deliver a more personalized service, including by helping us to:",
              "• Estimate our audience size and usage patterns.",
              "• Store information about your preferences and customize our App according to your individual interests.",
              "• Speed up your searches.",
              "• Recognize you when you use the App.",
              "If you do not want us to collect this information do not download the App or delete it from your device. ",
            ]}
          />

          <TextBlob
            title="THIRD-PARTY INFORMATION COLLECTION"
            description={[
              "When you use the App or its content, certain third parties collect information about you or your device. These third parties may include: ",
              "• Advertisers, ad networks, and ad servers.",
              "• Analytics companies",
              "• Your mobile device manufacturer.",
              "• Your mobile service provider.",
              "These third parties may use cookies alone or in conjunction with other tracking technologies to collect information about you when you use the App. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps and other online services. They may use this information to provide you with behavioural advertising or other targeted content. In addition to helping advertisers reach the right people for their products and services, behavioural advertising helps support the App so that you can enjoy free content.",
              "You can opt-out of several third-party ad servers' and networks' cookies simultaneously by using an opt-out tool created by the Digital Advertising Alliance of Canada or an opt-out tool created by the Network Advertising Initiative. You can also access these websites to learn more about online behavioural advertising and how to stop websites from placing cookies on your device. Opting out of a network does not mean you will no longer receive online advertising. It does mean that the network from which you opted out will no longer deliver ads tailored to your web preferences and usage patterns.",
              "We do not control these third parties' tracking technologies or how they use them. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For more information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.",
            ]}
          />

          <TextBlob
            title="HOW WE USE YOUR INFORMATION"
            description={[
              "We may use information that we collect about you or that you provide to us, including any personal information:",
              "• To provide you with the App and its contents.",
              "• To provide you with information, products or services that you request from us.",
              "• To fulfill the purposes for which you provided it or that were described when it was collected or any other purpose for which you provide it.",
              "• To provide notices about your account or subscription, including expiration and renewal notices.",
              "• To carry out our obligations and enforce our rights in any contracts with you, including for billing and collection or to comply with legal requirements.",
              "• To notify you when App updates are available, and of changes to any products or services we offer or provide though it.",
              "• To improve our App, products or services, or customer relationships and experiences.",
              "• For marketing purposes.",
              "• To allow you to participate in interactive features, social media, or other features on our App.",
              "• To measure or understand the effectiveness of the advertising we serve to you and others, and to deliver relevant advertising to you.",
              "• For any other purpose with your consent.",
              "Usage information that we collect helps us to improve our App and to deliver a better and more personalized experience, including by enabling us to:",
              "• Estimate our audience size and usage patterns.",
              "• Store information about your preferences, allowing us to customize our App according to your individual interests.",
              "• Speed up your searches.",
              "• Recognize you when you use the App.",
              "We use live location information we collect to give you your current location through the App, but we do not store this information. We may also use your information to contact you about our own and third parties' goods and services that may be of interest to you, as permitted by law. If you do not want us to use your information in this way, please email us at subscriptions@chamberperksapp.com . For more information, see Choices About How We Use and Disclose Your Information.",
              "We may use the information we have collected from you to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.",
            ]}
          />

          <TextBlob
            title="DISCLOSURE OF YOUR INFORMATION"
            description={[
              "We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.",
              "We may disclose personal information that we collect, or you provide as described in this privacy policy:",
              "• To our subsidiaries and affiliates.",
              "• According to applicable law, to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of 1000270862 Ontario Limited 's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by 1000270862 Ontario Limited about our customers and users is among the assets transferred.",
              "• To advertisers and advertising networks that require the information to select and serve relevant advertisements to you and others. We do not disclose data about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that 400 women between 30 and 45 have clicked on their advertisement on a specific day). We may also use such aggregate information to help advertisers target a specific audience (for example, men in a specific location). We may use the personal information we collect from you to display our advertisers' advertisements to their target audiences.",
              "• To third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them. For more information, see Choices About How We Use and Disclose Your Information.",
              "• To contractors, service providers, and other third parties we use to support our business, such as analytics and search engine providers that help us optimize and improve our services. We contractually require these third parties to keep personal information confidential, use it only for the purposes for which we disclose it to them, and to process personal information following the same standards set out in this policy.",
              '• To fulfill the purpose for which you provide it. For example, if you give us an email address to use the "email a friend" feature, we will transmit the contents of that email and your email address to the recipients.',
              "• For any other purpose we disclose when you provide the information.",
              "• With your consent.",
              "• To comply with any court order, law, or legal process, including to respond to any government or regulatory request, according to applicable law.",
              "• To enforce our rights arising from any contracts between you and us, and for billing and collection.",
              "• If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of 1000270862 Ontario Limited, our customers, or others. This includes exchanging information with other companies and organizations for fraud prevention and credit risk reduction.",
            ]}
          />

          <TextBlob
            title="TRANSFERRING YOUR PERSONAL INFORMATION"
            description={[
              "We may transfer personal information that we collect or that you provide us to contractors, service providers, and other third parties we use to support the App (such as analytics providers that assist us with App improvement and optimization) and who are contractually obligated to keep personal information confidential, to use it only for the purposes for which we disclose it to them, and to process the personal information with the same standards set out in this policy.",
              "We may process, store, and transfer your personal information in and to other countries with different privacy laws that may or may not be as comprehensive as Canadian law. In these circumstances, the governments, courts, law enforcement, or regulatory agencies of that country may be able to obtain access to your personal information. Whenever we engage a service provider, we require that its privacy and security standards comply with this policy and applicable Canadian laws.",
              "By submitting your personal information or engaging with the App, you consent to this transfer, storage, or processing.",
            ]}
          />

          <TextBlob
            title="CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION"
            description={[
              "We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information: ",
              "• Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies some parts of this App may not be accessible or may not function properly. For more information about tracking technologies, see Automatic Information Collection and Tracking Technologies. ",
              "• Promotional Offers from the Company. If you have opted in to receive certain emails from us but no longer wish for us to use your contact information to promote our own or third parties' products or services, you can opt-out by emailing privacy@chamberperksapp.com. If we have sent you a promotional email, you may unsubscribe by clicking the unsubscribe link we have included in the email. This opt-out does not apply to information provided to the Company as part of a product purchase, warranty registration, product service experience, or other transactions. You can also always opt-out by logging into the App and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to privacy@chamberperksapp.com.",
              "We do not control third parties' collection or use of your information to serve behavioural advertising. These third parties may provide you with additional choices about how they use your information or ways to choose not to have your information collected or used in this way. You can opt out of several third-party ad servers' and networks' cookies simultaneously by using an opt-out tool created by the Digital Advertising Alliance of Canada or an opt-out tool created by the Network Advertising Initiative. You can also access these websites to learn more about online behavioural advertising and how to stop websites from placing cookies on your device. Opting out of a network does not mean you will no longer receive advertising. It does mean that the network from which you opted out will no longer deliver ads tailored to your web preferences and usage patterns.",
            ]}
          />

          <TextBlob
            title="DATA SECURITY"
            description={[
              "The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. ",
              "The safety and security of your information also depends on you. Where we have given you (or you have chosen) a password for access to certain parts of our App, you are responsible for keeping it confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the App like message boards, which any Website visitor can view.",
              "Unfortunately, the transmission of information via the Internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through the App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any App privacy settings or security measures. ",
            ]}
          />

          <TextBlob
            title="DATA RETENTION"
            description={[
              "Except as otherwise permitted or required by applicable law or regulation, we will only retain your personal information for as long as necessary to fulfill the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. Under some circumstances we may anonymize or aggregate your personal information so that it can no longer be associated with you. We reserve the right to use such anonymous and de-identified data for any legitimate business purpose without further notice to you or your consent. ",
            ]}
          />

          <TextBlob
            title="CHILDREN UNDER THE AGE OF 13"
            description={[
              "Our App is not intended for children under 13 years of age. No one under age 13 may provide any information to or on the App. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this App or on or through any of its features/register on the App, make any purchases through the App, use any of the interactive or public comment features of this App, or provide any information about yourself to us, including your name, address, telephone number, email address, or any user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at privacy@chamberperksapp.com.",
            ]}
          />

          <TextBlob
            title="ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION"
            description={[
              "It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes. By law you have the right to request access to and to correct the personal information that we hold about you",
              "If you want to review, verify, correct, or withdraw consent to the use of your personal information you may send us an email at privacy@chamberperksapp.com to request access to, correct, or delete any personal information that you have provided to us. We may not be able to delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.",
              "We may request specific information from you to help us confirm your identity and your right to access, and to provide you with the personal information that we hold about you or make your requested changes. Applicable law may allow or require us to refuse to provide you with access to some or all of the personal information that we hold about you, or we may have destroyed, erased, or made your personal information anonymous in accordance with our record retention obligations and practices. If we cannot provide you with access to your personal information, we will inform you of the reasons why, subject to any legal or regulatory restrictions.",
              "We will provide access to your personal information, subject to exceptions set out in applicable privacy legislation. Examples of such exceptions include:",
              "• Information protected by solicitor-client privilege.",
              "• Information that is part of a formal dispute resolution process.",
              "• Information that is about another individual that would reveal their personal information or confidential commercial information.",
              "• Information that is prohibitively expensive to provide",
              "If you are concerned about our response or would like to correct the information provided, you may contact our Privacy Officer at privacy@chamberperksapp.com.",
              "If you delete your User Contributions from the App, copies of your User Contributions may remain viewable in cached and archived pages or might have been copied or stored by other App users.",
            ]}
          />

          <TextBlob
            title="WITHDRAWING YOUR CONSENT"
            description={[
              "Where you have provided your consent to the collection, use, and transfer of your personal information, you may have the legal right to withdraw your consent under certain circumstances. To withdraw your consent, if applicable, contact us at privacy@chamberperksapp.com. Please note that if you withdraw your consent, we may not be able to provide you with a particular product or service. We will explain the impact to you at the time to help you make your decision. ",
            ]}
          />

          <TextBlob
            title="CHANGES TO OUR PRIVACY POLICY"
            description={[
              "We may update our privacy policy from time to time. It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page. ",
              "We include the date the privacy policy was last revised at the top of the page. You are responsible for ensuring we have an up-to-date, active, and deliverable email address for you, and for periodically visiting this privacy policy to check for any changes.",
            ]}
          />

          <TextBlob
            title="CONTACT INFORMATION AND CHALLENGING COMPLIANCE"
            description={[
              "We welcome your questions, comments, and requests regarding this privacy policy and our privacy practices. Please contact our Privacy Officer at privacy@chamberperksapp.com.",
              "We have procedures in place to receive and respond to complaints or inquiries about our handling of personal information and our compliance with this policy and with applicable privacy laws. To discuss our compliance with this policy please contact our Privacy Officer using the contact information listed above.",
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useEffect } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useGetSupportCenterUrlMutation } from "../../../graphql/types";
import { freshworksLoginUrl } from "../../common/utilities/getFreshworksInfo";
import enumStrings from "../../enumStrings";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    zIndex: 1000000,
    background: "white",
    position: "absolute",
    left: 0,
    right: 0,
  },
  loader: {
    color: "#E1A731", // Using the gold color that matches the app's theme
  },
}));

const Support: React.FC = () => {
  const classes = useStyles();
  const [getSupportCenterUrl] = useGetSupportCenterUrlMutation();

  useEffect(() => {
    // check query params, if they contain nonce and state, call the mutation asyncly in a function inside this useeffect
    const checkQueryParams = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const nonce = urlParams.get("nonce");
      const state = urlParams.get("state");
      if (nonce && state) {
        const response = await getSupportCenterUrl({
          variables: { nonce, state },
        });
        // if the response is successful, redirect to the url
        if (response.data?.getSupportCenterUrl) {
          localStorage.setItem(
            enumStrings.localStorageKey.userHasAccessedExternalKnowledgeBase,
            "true"
          );
          window.location.href = response.data.getSupportCenterUrl;
        }
      } else {
        window.location.href = freshworksLoginUrl;
      }
    };

    checkQueryParams();
  }, [getSupportCenterUrl]);

  return (
    <div className={classes.container}>
      <CircularProgress className={classes.loader} size={60} />
    </div>
  );
};

export default Support;

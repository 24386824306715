import React, { ReactElement } from "react";
import { makeStyles, Button } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import theme from "../../themes";
import Loader from "./Loader/Loader";
import { Data, Headers } from "react-csv/components/CommonPropTypes";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  exportButton: {
    fontSize: "12px",
    backgroundColor: "white",
    fontWeight: "bold",
    color: "#000000",
    borderRadius: "10px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
    },
    "&:hover": {
      backgroundColor: "#e7e7e7",
    },
  },
}));

const ExportButton = ({
  loading,
  csvData,
  csvHeaders,
  label,
  icon,
  disablePadding,
  customOnClick,
}: {
  loading?: boolean;
  csvData?: string | Data | undefined | null;
  csvHeaders?: Headers | undefined;
  label: string;
  icon?: ReactElement;
  disablePadding?: boolean;
  customOnClick?: () => void;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div
      className={classes.buttonContainer}
      style={{ ...(disablePadding ? { padding: 0, margin: 0 } : {}) }}
    >
      {loading ? (
        <Button
          type="submit"
          size="large"
          variant="contained"
          className={classes.exportButton}
          style={{
            backgroundColor: "white",
            ...(disablePadding ? { margin: 0 } : {}),
          }}
          disabled
        >
          <div
            style={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {label}
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "-50%",
              }}
            >
              <Loader />
            </div>
          </div>
        </Button>
      ) : customOnClick ? (
        <Button
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          className={classes.exportButton}
          onClick={customOnClick}
          style={{
            backgroundColor: "white",
            ...(disablePadding ? { margin: 0 } : {}),
          }}
        >
          {icon ? <>{icon}</> : null}
          {label}
        </Button>
      ) : (
        <>
          <CSVLink
            data={csvData || []}
            headers={csvHeaders}
            style={{
              textDecoration: "none",
            }}
            filename={`${label.split(" ").join("-")}.csv`}
          >
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              className={classes.exportButton}
              style={{
                backgroundColor: "white",
                ...(disablePadding ? { margin: 0 } : {}),
              }}
            >
              {label}
            </Button>
          </CSVLink>
        </>
      )}
    </div>
  );
};

export default ExportButton;

import React, { createContext, ReactElement, useMemo, useState } from "react";
import { UserType } from "./graphql/types";

interface InitialState {
  userType: UserType | "";
}

const initialState: InitialState = {
  userType: "",
};

interface Setters {
  setUserType: React.Dispatch<React.SetStateAction<UserType | "">>;
}

const UserTypeContext = createContext<{
  state: typeof initialState;
  setters: Setters;
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
}>({});

const UserTypeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): ReactElement => {
  const [userType, setUserType] = useState(initialState.userType);
  const value = useMemo(() => {
    return {
      state: {
        userType,
      },
      setters: {
        setUserType,
      },
    };
  }, [userType]);

  return (
    <UserTypeContext.Provider value={value}>
      {children}
    </UserTypeContext.Provider>
  );
};

export { UserTypeContext, UserTypeProvider };

import {
  useGetActiveCategoryAdvertisingBannersForChamberMemberQuery,
  UserType,
} from "../../../graphql/types";

const useCheckIfCategoryAdvertisingBannersActiveForChamberMember = ({
  userType,
}: {
  userType: UserType | null | undefined | "";
}) => {
  const { data: getActiveCategoryAdvertisingBannersForChamberMember } =
    useGetActiveCategoryAdvertisingBannersForChamberMemberQuery({
      skip: userType !== UserType.ChamberMemberAdmin,
    });

  return (
    (
      getActiveCategoryAdvertisingBannersForChamberMember?.getActiveCategoryAdvertisingBannersForChamberMember ||
      []
    ).length > 0
  );
};

export default useCheckIfCategoryAdvertisingBannersActiveForChamberMember;

import { makeStyles, TextField } from "@material-ui/core";
import theme from "../../../themes";
import { v4 as uuidv4 } from "uuid";
import { ChamberMemberInput } from "../../../graphql/types";
import { useCallback } from "react";
import PhoneNumberFormat from "./PhoneNumberFormat";
import HoverToolTip from "./HoverToolTip";

const useStyles = makeStyles(() => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
    marginLeft: "12px",
  },
  companyInput: {
    backgroundColor: "white",
    paddingBottom: "10px",
    marginBottom: "5px",
    borderRadius: "15px",
    marginTop: "10px",
    height: "55px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    font: "inherit",
    fontSize: "1.18676em",
  },
}));

const jobTitles = [
  {
    jobTitle: "Sales Manager",
    tooltipText:
      "The individual in this company who would be the designated contact responsible for driving sales and would oversee the implementation of company perks.\n\nThis contact will not be shown in the Chamber Perks App.",
  },
  {
    jobTitle: "HR/Employee Wellness Personnel",
    tooltipText:
      "The individual in this company who is most appropriate for informing employees about saving money locally and Canada-wide by downloading and redeeming perks on the Chamber Perks App, as well as private employee perks.\n\nThis contact will not be shown in the Chamber Perks App.",
  },
  {
    jobTitle: "Marketing/Advertising",
    tooltipText:
      "The individual in this company responsible for marketing and ensuring proper brand representation on the Chamber Perks App to attract potential customers locally and Canada-wide.\n\nThis contact will not be shown in the Chamber Perks App.",
  },
];

export const getInitialStateOfCustomContacts = (
  customContacts: ChamberMemberInput["customContacts"]
) => {
  if (customContacts && customContacts.length > 0) {
    let resObj = [...customContacts];

    // if a job title is missing from existing custom contacts, add it
    for (const { jobTitle } of jobTitles) {
      if (!customContacts.some((contact) => contact.jobTitle === jobTitle)) {
        resObj.push({
          id: uuidv4(),
          name: "",
          email: "",
          jobTitle,
          phoneNumber: "",
        });
      }
    }

    return resObj;
  }

  return jobTitles.map(({ jobTitle }) => ({
    id: uuidv4(),
    name: "",
    email: "",
    jobTitle,
    phoneNumber: "",
  }));
};

const MultiContactForm = ({
  formikValuesCustomContacts,
  formikSetFieldValue,
}: {
  formikValuesCustomContacts: ChamberMemberInput["customContacts"];
  formikSetFieldValue: (
    a: string,
    b: ChamberMemberInput["customContacts"]
  ) => void;
}) => {
  const classes = useStyles();

  const handleContactChange = useCallback(
    (event: React.ChangeEvent<any>, id: string) => {
      const newContacts = (formikValuesCustomContacts || []).map((contact) => {
        if (contact.id === id) {
          return {
            ...contact,
            [event.target.name]: event.target.value,
          };
        }
        return contact;
      });
      formikSetFieldValue("customContacts", newContacts);
    },
    [formikSetFieldValue, formikValuesCustomContacts]
  );

  return (
    <>
      {(formikValuesCustomContacts || []).map((contact, index) => (
        <div
          style={{ display: "flex", flexDirection: "row", paddingLeft: "37px" }}
        >
          <div
            key={contact.id}
            className={classes.root}
            style={{
              borderRadius: "15px",
              marginTop: "50px",
              display: "flex",
              flexDirection: "column",
              padding: "15px",
              border: "1px solid #9e9e9e",
              backgroundColor: "#F2F2F6",
              color: "#6F6F71",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2
                style={{
                  marginBottom: "0px",
                  marginTop: "0px",
                  padding: 0,
                  color: "#6F6F71",
                }}
              >
                {contact.jobTitle.length > 0
                  ? `${contact.jobTitle} Contact`
                  : `Contact ${index + 1}`}
              </h2>
            </div>
            <div style={{ marginTop: "5px" }}>
              <TextField
                type="text"
                label="Contact Name"
                className={classes.companyInput}
                variant="outlined"
                id="name"
                name="name"
                value={contact.name}
                onChange={(e) => handleContactChange(e, contact.id)}
              />
              <TextField
                type="text"
                label="Contact Email"
                className={classes.companyInput}
                variant="outlined"
                id="email"
                name="email"
                value={contact.email}
                onChange={(e) => handleContactChange(e, contact.id)}
              />
              <PhoneNumberFormat
                formikErrors={""}
                formikVal={contact.phoneNumber || ""}
                handleChange={(e: any): any =>
                  handleContactChange(
                    {
                      target: { name: "phoneNumber", value: e.target.value },
                    } as any,
                    contact.id
                  )
                }
                label="Contact Phone Number"
                error={false}
                helperText={""}
                customContactForm
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "60px",
            }}
          >
            <HoverToolTip
              description={
                jobTitles.find(({ jobTitle }) => jobTitle === contact.jobTitle)
                  ?.tooltipText ||
                "This contact will not show in the Chamber Perks App."
              }
              showIt
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default MultiContactForm;

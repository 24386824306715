import { FormHelperText, makeStyles, TextField } from "@material-ui/core";
import { ReactElement } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme: any) => ({
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    height: "55px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const PhoneNumberFormatCustom = (
  props: NumberFormatCustomProps
): ReactElement => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
    />
  );
};

const PhoneNumberFormat = ({
  formikVal,
  formikErrors,
  handleChange,
  label,
  error,
  helperText,
  customContactForm,
}: {
  formikVal: any;
  formikErrors: any;
  handleChange: any;
  label: string;
  error?: any;
  helperText?: any;
  customContactForm?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        type="text"
        label={label}
        className={classes.companyInput}
        style={{
          ...(customContactForm
            ? {
                width: "100%",
                margin: 0,
                marginTop: "10px",
                backgroundColor: "white",
              }
            : {}),
        }}
        variant="outlined"
        id="phoneNumber"
        name="phoneNumber"
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: PhoneNumberFormatCustom as any,
        }}
        value={formikVal}
        onChange={handleChange}
        error={formikVal && Boolean(formikErrors)}
        helperText={formikVal && formikErrors}
      />
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "5px",
          marginBottom: "-5px",
          textAlign: "center",
        }}
      >
        {formikErrors}
      </FormHelperText>
    </div>
  );
};

export default PhoneNumberFormat;

import React, { useCallback } from "react";
import {
  makeStyles,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";

import "react-toastify/dist/ReactToastify.css";

import {
  DiscountInput,
  RedeemSettingsInput,
  WhereToRedeemEnum,
} from "../../../graphql/types";
import { FormikErrors } from "formik";
import enumStrings from "../../enumStrings";
import PhoneNumberFormat from "./PhoneNumberFormat";
import HoverToolTip from "../utilities/HoverToolTip";

const useStyles = makeStyles((theme) => ({
  notificationRadioGroup: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "5px",
    width: "100%",
  },
  radio: {
    color: "#E1A731!important",
  },
  inputField: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  inputContainer: {
    margin: "auto",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const RedeemOfferErrorHelperText = ({
  errorText,
}: {
  errorText: string | undefined;
}) => {
  return errorText && errorText.length > 0 ? (
    <FormHelperText
      component="legend"
      style={{
        color: "#f44336",
        paddingLeft: "10px",
        paddingTop: "5px",
        textAlign: "center",
        marginBottom: "-28px",
      }}
    >
      {errorText}
    </FormHelperText>
  ) : (
    <></>
  );
};

const RedeemOfferForm = ({
  formikSetFieldValue,
  formikVal,
  formikErrors,
}: {
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<DiscountInput>>;
  formikVal: RedeemSettingsInput;
  formikErrors: FormikErrors<DiscountInput>;
}) => {
  const classes = useStyles();
  const handleWhereToRedeemChange = useCallback(
    (whereToRedeem: WhereToRedeemEnum) => {
      formikSetFieldValue(
        "redeemSettings.howToRedeem",
        {
          code: "",
          contactEmail: "",
          contactNumber: "",
          contactName: "",
          websiteToEnterCodeAt: "",
        },
        false
      );
      formikSetFieldValue("redeemSettings.whereToRedeem", whereToRedeem);
    },
    [formikSetFieldValue]
  );

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "600px",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "0px",
          marginTop: "40px",
        }}
      >
        <h1 style={{ marginBottom: "0px" }}>
          {enumStrings.redeemOfferText.redeemOfferTitle}
        </h1>
        <HoverToolTip
          description={enumStrings.redeemOfferText.tooltip.title}
          showIt
        />
      </div>
      <RadioGroup
        row
        aria-label="AvailableToGroup"
        name="AvailableToGroup"
        className={classes.notificationRadioGroup}
        onChange={(e) => {
          handleWhereToRedeemChange(e.target.value as WhereToRedeemEnum);
        }}
        value={formikVal.whereToRedeem}
      >
        {formikErrors.redeemSettings?.whereToRedeem &&
        formikVal.whereToRedeem === undefined ? (
          <FormHelperText
            component="legend"
            style={{
              color: "#f44336",
              textAlign: "center",
            }}
          >
            {"Please select an option"}
          </FormHelperText>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value={WhereToRedeemEnum.InPerson}
            labelPlacement="end"
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                In Person
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={enumStrings.redeemOfferText.tooltip.inPerson}
                  showIt
                />
              </div>
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value={WhereToRedeemEnum.Online}
            control={<Radio className={classes.radio} />}
            labelPlacement="end"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Online
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={enumStrings.redeemOfferText.tooltip.online}
                  showIt
                />
              </div>
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            style={{ marginRight: "0px" }}
            value={WhereToRedeemEnum.ContactCompany}
            control={<Radio className={classes.radio} />}
            labelPlacement="end"
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Contact Company
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={
                    enumStrings.redeemOfferText.tooltip.contactCompany
                  }
                  showIt
                />
              </div>
            }
          />
        </div>
        <FormHelperText
          component="legend"
          style={{
            color: "#f44336",
            paddingLeft: "10px",
            paddingTop: "5px",
            textAlign: "center",
          }}
        ></FormHelperText>
      </RadioGroup>
      {formikVal.whereToRedeem === WhereToRedeemEnum.ContactCompany && (
        <div className={classes.inputContainer}>
          <TextField
            type="text"
            label="Department or Contact name *"
            className={classes.inputField}
            variant="outlined"
            id="name"
            name="name"
            value={formikVal?.howToRedeem?.contactName}
            onChange={(e) => {
              formikSetFieldValue(
                "redeemSettings.howToRedeem.contactName",
                e.target.value
              );
            }}
          />
          <RedeemOfferErrorHelperText
            errorText={
              (formikErrors.redeemSettings?.howToRedeem as any)?.contactName
            }
          />

          <TextField
            type="text"
            label="Company Contact Email"
            className={classes.inputField}
            variant="outlined"
            id="name"
            name="name"
            value={formikVal?.howToRedeem?.contactEmail}
            onChange={(e) => {
              formikSetFieldValue(
                "redeemSettings.howToRedeem.contactEmail",
                e.target.value
              );
            }}
          />
          <RedeemOfferErrorHelperText
            errorText={
              (formikErrors.redeemSettings?.howToRedeem as any)?.contactEmail
            }
          />

          <PhoneNumberFormat
            formikErrors={null}
            formikVal={formikVal?.howToRedeem?.contactNumber}
            handleChange={(e: { target: { value: string } }) => {
              formikSetFieldValue(
                "redeemSettings.howToRedeem.contactNumber",
                e.target.value
              );
            }}
            label="Contact number"
          />
          <RedeemOfferErrorHelperText
            errorText={
              (formikErrors.redeemSettings?.howToRedeem as any)?.contactNumber
            }
          />
          <RedeemOfferErrorHelperText
            // @ts-ignore
            errorText={formikErrors?.oneOfContactEmailOrContactNumberRequired}
          />
        </div>
      )}
      {formikVal.whereToRedeem === WhereToRedeemEnum.Online && (
        <div className={classes.inputContainer}>
          <TextField
            type="text"
            label="Your Discount Code *"
            className={classes.inputField}
            variant="outlined"
            id="name"
            name="name"
            value={formikVal?.howToRedeem?.code}
            onChange={(e) => {
              formikSetFieldValue(
                "redeemSettings.howToRedeem.code",
                e.target.value
              );
            }}
          />
          <RedeemOfferErrorHelperText
            errorText={(formikErrors.redeemSettings?.howToRedeem as any)?.code}
          />
          <TextField
            type="text"
            label="Company E-commerce Website URL *"
            className={classes.inputField}
            variant="outlined"
            id="website"
            name="website"
            value={formikVal?.howToRedeem?.websiteToEnterCodeAt}
            onChange={(e) => {
              formikSetFieldValue(
                "redeemSettings.howToRedeem.websiteToEnterCodeAt",
                e.target.value
              );
            }}
          />
          <RedeemOfferErrorHelperText
            errorText={
              (formikErrors.redeemSettings?.howToRedeem as any)
                ?.websiteToEnterCodeAt
            }
          />
        </div>
      )}
    </div>
  );
};

export default RedeemOfferForm;

import { GetAllRewardCampaignsForChamberQuery } from "../../../graphql/types";

export const checkIfRewardCampaignIsActive = ({
  campaign,
}: {
  campaign: NonNullable<
    GetAllRewardCampaignsForChamberQuery["getAllRewardCampaignsForChamber"]
  >[number];
}): {
  campaignIsUpcoming: boolean;
  campaignIsActive: boolean;
} => {
  let campaignIsUpcoming = false;
  let campaignIsActive = false;

  if (campaign.startDateUtcMilli > Date.now()) {
    campaignIsUpcoming = true;
  }

  if (
    campaign.startDateUtcMilli <= Date.now() &&
    campaign.endDateUtcMilli >= Date.now()
  ) {
    campaignIsActive = true;
  }

  return {
    campaignIsUpcoming,
    campaignIsActive,
  };
};

import { createTheme } from "@material-ui/core";

const theme = (createTheme as (options?: any) => any)({
  palette: {
    primary: {
      main: "#E1A731",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E1A731",
      contrastText: "#fff",
    },
    delete: {
      main: "red",
      contrastText: "#fff",
      width: "300px",
      height: "48px",
      fontSize: "25px",
      fontWeight: "bold",
      hoverColor: "#cb0000",
    },
  },
});
theme.props = {
  MuiButton: {
    delete: {},
  },
  MuiInput: {
    disableUnderline: true,
  },
};

theme.overrides = {
  MuiButton: {
    root: {
      fontWeight: "bold",
      width: "255px",
      fontSize: "33px!important",
      borderRadius: "15px!important",
    },
  },
  MuiInputBase: {
    root: {
      backgroundColor: "#F2F2F6!important",
      borderRadius: "15px!important",
    },
  },
  MuiTextField: {
    root: {
      top: theme.spacing(2),
      backgroundColor: "#F2F2F6!important",
      borderRadius: "15px!important",
      "&:hover fieldset": {
        borderColor: theme.palette.primary.main + "!important",
      },
    },
    "&:hover": {
      backgroundColor: "#E1A731",
      color: "#fff",
    },
    "&focused": {},
  },
  MuiInputLabel: {
    root: {},
    "&focused": {},
  },
  MuiOutlinedInput: {
    root: {
      "&hover": {},
    },
  },
  MuiSwitch: {
    root: {
      top: theme.spacing(3),
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "white",
          borderColor: "13px solid white",
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      backgroundColor: "white",
    },
    track: {
      border: `1px solid black`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#BDBDBD",
    },
    checked: {},
  },
};

export default theme;

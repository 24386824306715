import React, { ReactElement, useState } from "react";
import { ThemeProvider, Button, Grid } from "@material-ui/core";
import theme from "../../../themes";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../images/Gold_Logo.png";
import { Auth } from "aws-amplify";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { commonCognitoPasswordRegex } from "../../common/utilities/commonPasswordRegex";
import PasswordField from "../../common/PasswordField";
import {
  UserType,
  useSetHasSignedUpForChamberMemberMutation,
} from "../../../graphql/types";

interface NewPasswordInput {
  newPassword: string;
  confirmPassword: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  loginForm: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "250px",
    display: "block",
    margin: "auto",
    paddingTop: "9.2px",
  },
  heading: {},
  formFields: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    height: "500px",
    background: "white",
    padding: "29px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    margin: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "22px",
    paddingBottom: "9px",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    backgroundColor: "white",
  },
  textFieldLabel: {
    fontSize: "18px",
  },
  formBody: {
    width: "90%",
    maxWidth: "585px",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  icon: {
    margin: "auto",
  },
}));
const NewPassword = (): ReactElement => {
  const classes = useStyles();
  const initialValues: NewPasswordInput = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .required("Please Enter your password")
      .matches(
        commonCognitoPasswordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });
  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState("");
  const { email, tempPassword } = JSON.parse(
    sessionStorage?.getItem(
      enumStrings.sessionStorageKey.loggedInWithTemporaryPassword
    ) || `{"email":"", "tempPassword": ""}`
  );
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [setHasSignedUpForChamberMember] =
    useSetHasSignedUpForChamberMemberMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: NewPasswordInput) => {
      setLoader(true);
      try {
        try {
          const user = await Auth.signIn(email, tempPassword);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            await Auth.completeNewPassword(user, input.confirmPassword);

            if (
              user &&
              user?.signInUserSession &&
              user?.signInUserSession?.accessToken &&
              user?.signInUserSession?.accessToken?.payload &&
              "cognito:groups" in
                user?.signInUserSession?.accessToken?.payload &&
              user?.signInUserSession?.accessToken?.payload["cognito:groups"]
                .length > 0 &&
              user?.signInUserSession?.accessToken?.jwtToken &&
              user?.signInUserSession?.accessToken?.jwtToken.length > 0
            ) {
              const userType =
                user.signInUserSession.accessToken.payload["cognito:groups"][0];
              const customAccessTokenForSettingHasSignedUp =
                user.signInUserSession.accessToken.jwtToken;

              if (userType === UserType.ChamberMemberAdmin) {
                await setHasSignedUpForChamberMember({
                  context: {
                    customAccessTokenForSettingHasSignedUp,
                  },
                });
              }
            }

            sessionStorage.removeItem(
              enumStrings.sessionStorageKey.loggedInWithTemporaryPassword
            );
            window.location.href = RedirectUri.home;
          }
        } catch (error) {
          setLoader(false);
          setButtonDisabled(false);
        }
        setButtonDisabled(true);
      } catch (error) {
        setInvalidError(true);
        if (error?.code === "NetworkError") {
          setCustomError("Please connect to the internet");
        }
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <form
          style={{
            height: "600px",
          }}
          onSubmit={formik.handleSubmit}
          className={classes.formFields}
        >
          <img
            src={Logo}
            width="180px"
            height="180px"
            className={classes.icon}
            alt="capIcon"
          />
          <br />
          <div style={{ textAlign: "center" }}>
            <h3>{enumStrings.newPasswordText.pleaseEnterNewPasswordText}</h3>
          </div>
          <div style={{ margin: "-10px" }} />
          <Grid item xs={12}>
            <PasswordField
              id="newPassword"
              fieldName="newPassword"
              label="New Password *"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.errors.newPassword}
              touched={formik.touched.newPassword}
              hideText
              showVisibilityEyeball
            />
            <PasswordField
              id="confirmPassword"
              fieldName="confirmPassword"
              label="Confirm Password *"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={formik.errors.confirmPassword}
              touched={formik.touched.confirmPassword}
              hideText
              showVisibilityEyeball
            />
          </Grid>
          <div className={classes.buttonContainer}>
            <br />
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              disabled={buttonDisabled || loader}
            >
              {enumStrings.newPasswordText.changePasswordText}
            </Button>
            <br />
            <span
              style={{
                textAlign: "center",
                color: "#E1A731",
                textDecoration: "underline",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                sessionStorage.clear();
                window.location.href = RedirectUri.login;
              }}
            >
              Back to login
            </span>
          </div>
          {invalidError ? (
            <div className={classes.invalidError}>{customError}</div>
          ) : null}{" "}
        </form>
      </div>
    </ThemeProvider>
  );
};

export default NewPassword;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import {
  ChamberMemberReturn,
  GetAllChamberMembersQuery,
  ShopLocalCampaignParticipatingMembersInput,
} from "../../../graphql/types";
import { TextField, makeStyles, FormHelperText } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../../themes";
import Loader from "../../common/Loader/Loader";
import { FormikErrors, FormikTouched } from "formik";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import enumStrings from "../../enumStrings";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "450px",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0px",
    marginTop: "10px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  descriptionPreviewText: {
    [theme.breakpoints.down("sm")]: {
      width: "255px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "420px",
    },
  },
}));

export default function ChooseParticipatingMembers({
  selectedChamberMembers,
  handleChamberMembersChange,
  allChamberMemberData,
  formikTouchedAvailableToChamberIds,
  formikErrorsAvailableToChamberIds,
}: {
  selectedChamberMembers: ShopLocalCampaignParticipatingMembersInput[];
  handleChamberMembersChange: (
    e: ShopLocalCampaignParticipatingMembersInput[]
  ) => void;
  allChamberMemberData: GetAllChamberMembersQuery | undefined;
  formikTouchedAvailableToChamberIds:
    | FormikTouched<ShopLocalCampaignParticipatingMembersInput>[]
    | undefined;
  formikErrorsAvailableToChamberIds:
    | string
    | string[]
    | FormikErrors<ShopLocalCampaignParticipatingMembersInput>[]
    | undefined;
}) {
  const classes = useStyles();
  const [checked, setChecked] = useState<
    ShopLocalCampaignParticipatingMembersInput[]
  >([]);
  const [fullList, setFullList] = useState<
    Pick<ChamberMemberReturn, "id" | "name">[]
  >([]);

  const handleToggle = useCallback(
    (
        newCheckState: boolean,
        value: ShopLocalCampaignParticipatingMembersInput
      ) =>
      () => {
        const newChecked = [...checked];

        if (newCheckState) {
          newChecked.push(value);
        } else {
          const currentIndex = checked.findIndex((obj) => obj.id === value.id);
          newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        handleChamberMembersChange(newChecked);
      },
    [checked, handleChamberMembersChange]
  );

  const handleToggleAll = useCallback(
    (items: ShopLocalCampaignParticipatingMembersInput[]) => () => {
      if (checked.length === items.length) {
        setChecked([]);
        handleChamberMembersChange([]);
      } else {
        setChecked(fullList);
        handleChamberMembersChange(fullList);
      }
    },
    [checked.length, fullList, handleChamberMembersChange]
  );

  const [isLoading, setIsLoading] = useState(true);

  const [searchInput, setSearchInput] = useState("");
  const handleSearchChange = useCallback((e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  }, []);

  const selectedChamberMemberIds = useMemo(() => {
    return selectedChamberMembers.map((chamberMember) => chamberMember.id);
  }, [selectedChamberMembers]);

  useEffect(() => {
    if (isLoading) {
      const allChamberMemberSelectableArray = (
        allChamberMemberData?.getAllChamberMembers || []
      )
        .map(({ id, name }) => ({
          id,
          name,
        }))
        .sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

      if (selectedChamberMembers && selectedChamberMembers.length > 0) {
        setChecked(
          (allChamberMemberSelectableArray || []).filter((chamberMember) =>
            selectedChamberMemberIds.includes(chamberMember.id)
          ) || []
        );
      } else {
        setChecked([]);
      }

      setFullList(allChamberMemberSelectableArray || []);
      setIsLoading(false);
    }
  }, [
    allChamberMemberData?.getAllChamberMembers,
    selectedChamberMembers,
    isLoading,
    selectedChamberMemberIds,
  ]);

  const searchedItems = useMemo(() => {
    return fullList.filter((chamberMember) => {
      return chamberMember.name.toLowerCase().match(searchInput.toLowerCase());
    });
  }, [fullList, searchInput]);

  const customList = useMemo(() => {
    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          title={
            <div className={classes.searchContainer}>
              <TextField
                style={{
                  borderBottom: "none",
                  border: "1px solid #9E9E9E",
                }}
                type="text"
                placeholder="Search by Member Company Name"
                onChange={handleSearchChange}
                value={searchInput}
                className={classes.searchBar}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
                }}
              />
            </div>
          }
        />
        <h3
          style={{
            marginBottom: "0px",
            paddingLeft: "20px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {checked.length}/{fullList.length} member companies selected
        </h3>
        <ListItem
          key={"select-all-list-item"}
          role="listitem"
          button
          onClick={handleToggleAll(searchedItems)}
        >
          <ListItemIcon>
            <Checkbox
              style={{
                color: "#E1A731",
              }}
              checked={
                checked.length === searchedItems.length &&
                searchedItems.length !== 0
              }
              disabled={searchedItems.length === 0}
              inputProps={{
                "aria-label": "all items selected",
              }}
            />
          </ListItemIcon>
          <ListItemText id={"select-all"} primary={"Select All"} />
        </ListItem>
        <Divider />
        <List
          sx={{
            height: 350,
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          dense
          component="div"
          role="list"
        >
          {searchedItems.map((value) => {
            const labelId = `transfer-list-all-item-${value.id}-label`;
            const isChecked = selectedChamberMemberIds.includes(value.id);

            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(!isChecked, value)}
              >
                <ListItemIcon>
                  <Checkbox
                    style={{
                      color: "#E1A731",
                    }}
                    checked={isChecked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItem>
            );
          })}
        </List>
      </Card>
    );
  }, [
    checked.length,
    classes.searchBar,
    classes.searchContainer,
    fullList.length,
    handleSearchChange,
    handleToggle,
    handleToggleAll,
    searchInput,
    searchedItems,
    selectedChamberMemberIds,
  ]);

  if (!allChamberMemberData?.getAllChamberMembers || isLoading) {
    return (
      <div style={{ marginTop: "46px" }}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={classes.companyInputWithToolTipContainer}>
        <Grid
          container
          style={{
            marginTop: "65px",
          }}
          spacing={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: "30px",
            }}
          >
            <h1
              style={{
                marginBottom: "0px",
                textAlign: "center",
                whiteSpace: "pre-wrap",
              }}
            >
              Select Member Companies{"\n"}Participating in Campaign
            </h1>
            <div
              style={{
                marginTop: "0px",
              }}
            >
              <HoverToolTip
                description={
                  enumStrings.chamberShopLocal.tooltip.participatingMembers
                }
                showIt
              />
            </div>
          </div>
          <Grid
            container
            style={{
              marginTop: "5px",
            }}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              style={{
                margin: 0,
              }}
            >
              {customList}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "10px",
          textAlign: "center",
        }}
      >
        {formikTouchedAvailableToChamberIds &&
          formikErrorsAvailableToChamberIds}
      </FormHelperText>
      <div className={classes.descriptionPreviewText}>
        <ParticipationCodeExplanationText />
      </div>
    </>
  );
}

export const ParticipationCodeExplanationText = () => {
  return (
    <h4>
      Each member company selected to participate in this contest will be
      assigned a unique participation code.
      <br />
      <br />
      Chamber Perks App™ users will need to use the participation code when
      redeeming a perk from a participating member company to enter into the
      draw for the contest prize.
      <br />
      <br />
      You will be able to see each member's participation code by opening the
      list of participating members on the Active Contest page in your Chamber
      Dashboard.
      <br />
      <br />
      Members will be able to see their unique participation code on their
      Member Dashboard on the Shop Local Contest page.
      <br />
      <br />
      You should make any participating member company aware that Chamber Perks
      App™ users may ask for their participation code, and that they can provide
      it by checking for it in their Member Dashboard on the Shop Local page.
    </h4>
  );
};

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { DiscountReturn, UserType } from "../../graphql/types";
import { RedirectUri } from "../enumStrings";
import { useHistory } from "react-router-dom";
import { getOfferedToText } from "../pages/ChamberAdmin/RootChamberAdmin";
import { TextField, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../themes";
import LogoImage from "./utilities/LogoImage";
import useGetChamberFromUserIdInAuth from "./Hooks/useGetChamberFromUserIdInAuth";

type OmittedRedeemSettingsDiscount = Omit<
  DiscountReturn,
  "redeemSettings" | "numberOfFavorites"
>;
type AllDiscountDataType = OmittedRedeemSettingsDiscount[];
type TableDiscountDataType = OmittedRedeemSettingsDiscount & {
  chamberMemberName: string;
  offeredToText: string;
  localCreationDate: number;
};
type AllTableDiscountDataType = TableDiscountDataType[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableDiscountDataType,
  comparator: (a: any, b: any) => number
): AllTableDiscountDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
}

interface FullPageDiscountTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageDiscountTableHead(props: FullPageDiscountTableProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((themes: any) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
}));

const headCells: HeadCell[] = [
  {
    id: "memberLogo",
    label: "",
  },
  {
    id: "chamberMemberName",
    label: "Member Name",
  },
  {
    id: "title",
    label: "Perk Title",
  },

  {
    id: "offeredToText",
    label: "Redeemable By",
  },
  {
    id: "views",
    label: "Views",
    alignCenter: true,
  },
  // {
  //   id: "localCreationDate",
  //   label: "Creation Date",
  // },
];

export default function FullPageDiscountTable({
  userType,
  allDiscountData,
}: {
  userType: UserType;
  allDiscountData: AllDiscountDataType;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");
  const history = useHistory();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = React.useCallback(
    (discountInfo: TableDiscountDataType) => {
      if (userType === UserType.ChamberAdmin) {
        history.push(RedirectUri.editDiscountPageChamberAdmin, discountInfo);
      }
    },
    [history, userType]
  );

  const [searchInput, setSearchInput] = React.useState("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const allTableDiscountData: AllTableDiscountDataType = React.useMemo(() => {
    if (!allDiscountData) {
      return [];
    }

    const allDiscountsArr = allDiscountData.map((discountData) => ({
      ...discountData,
      chamberMemberName: discountData.chamberMemberInfo.name,
      offeredToText: getOfferedToText(discountData as DiscountReturn),
      localCreationDate: discountData.createdAtUtcMilli || 0,
    }));

    if (searchInput.length > 0) {
      return allDiscountsArr.filter((i) => {
        return (
          i.chamberMemberInfo.name
            .toLowerCase()
            .match(searchInput.toLowerCase()) ||
          i.title.toLowerCase().match(searchInput.toLowerCase())
        );
      });
    }

    return allDiscountsArr;
  }, [allDiscountData, searchInput]);

  const visibleRows = React.useMemo(
    () => stableSort(allTableDiscountData, getComparator(order, orderBy)),
    [allTableDiscountData, order, orderBy]
  );

  const userIdInAuthChamberInfo = useGetChamberFromUserIdInAuth();

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder="Search by Perk or Member Company Name"
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageDiscountTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((discountInfo) => {
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(discountInfo)}
                    role="checkbox"
                    tabIndex={-1}
                    key={discountInfo.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      <LogoImage
                        logoSource={discountInfo?.chamberMemberInfo?.logo || ""}
                        size="large"
                        squared
                        key={discountInfo.id}
                        chamberName={discountInfo.chamberMemberName}
                      />
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {discountInfo.chamberMemberName}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {discountInfo.title}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {discountInfo.offeredToText}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "16px", paddingRight: "40px" }}
                    >
                      {/* show random number between 50 and 200 for demonstration purposes for sandbay chamber */}
                      {userIdInAuthChamberInfo?.isSandbox
                        ? Math.floor(Math.random() * 150) + 50
                        : discountInfo.views}
                    </TableCell>
                    {/* <TableCell style={{ fontSize: "16px" }}>
                      {getLocalTimestamp(
                        discountInfo.localCreationDate || 0,
                        true
                      )}
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

import React, { ReactElement, useMemo } from "react";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PasswordRequirementToolTip from "./PasswordRequirementTooltip";
import EyeOnIcon from "@material-ui/icons/VisibilityOutlined";
import EyeOffIcon from "@material-ui/icons/VisibilityOffOutlined";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "white",
  },
  textFieldLabel: {
    fontSize: "18px",
  },
}));
const PasswordField = ({
  id,
  fieldName,
  label,
  value,
  onChange,
  touched,
  error,
  disablePopup = false,
  hideText = false,
  showVisibilityEyeball = false,
}: {
  id: string;
  fieldName: string;
  label: string;
  value: string;
  onChange: (e: any) => void;
  touched: boolean | undefined;
  error: string | undefined;
  disablePopup?: boolean;
  hideText?: boolean;
  showVisibilityEyeball?: boolean;
}): ReactElement => {
  const classes = useStyles();
  const [showPasswordTooltip, setShowPasswordTooltip] = React.useState(false);

  const [hideTextBingo, setHideTextBingo] = React.useState(hideText);

  const visible = useMemo(() => {
    if (hideTextBingo) {
      return false;
    }
    return true;
  }, [hideTextBingo]);

  return (
    <div style={{ position: "relative" }}>
      {showPasswordTooltip ? (
        <PasswordRequirementToolTip passwordText={value} />
      ) : null}

      <TextField
        onFocus={() => {
          if (!disablePopup) {
            setShowPasswordTooltip(true);
          }
        }}
        onBlur={() => {
          if (!disablePopup) {
            setShowPasswordTooltip(false);
          }
        }}
        variant="outlined"
        InputProps={{
          className: classes.textField,
        }}
        InputLabelProps={{
          className: classes.textFieldLabel,
        }}
        fullWidth
        style={{
          marginTop: "30px",
        }}
        id={id}
        name={fieldName}
        label={label}
        value={value}
        onChange={onChange}
        error={touched && Boolean(error)}
        helperText={touched && error}
        type={visible ? "text" : "password"}
      />
      {showVisibilityEyeball ? (
        <div
          style={{
            position: "absolute",
            top: 62,
            right: 10,
          }}
        >
          {visible ? (
            <EyeOffIcon
              onClick={() => {
                setHideTextBingo(!hideTextBingo);
              }}
            />
          ) : (
            <EyeOnIcon
              onClick={() => {
                setHideTextBingo(!hideTextBingo);
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default PasswordField;

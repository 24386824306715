import React from "react";
import { UserType } from "../../graphql/types";
import { marginTopPx } from "./BannerPicker";

const BannerPickerAgreementOverlay = ({
  userType,
  paddingLeftPx,
  chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted,
  openChamberMemberMemberProfileAndPerksBannersAgreementModal,
}: {
  userType: UserType | "";
  paddingLeftPx: number;
  chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted: boolean;
  openChamberMemberMemberProfileAndPerksBannersAgreementModal: () => void;
}) => {
  return (
    <>
      {userType === UserType.ChamberMemberAdmin && (
        <div
          style={{
            zIndex: 1,
            position: "absolute",
            backgroundColor: "transparent",
            opacity: 0.5,
            height: `calc(100% - ${marginTopPx}px)`,
            width: `calc(100% - ${paddingLeftPx * 2}px)`,
            marginTop: `${marginTopPx}px`,
            marginRight: `${paddingLeftPx}px`,
            ...(!chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted
              ? {
                  cursor: "pointer",
                }
              : { display: "none" }),
          }}
          onClick={() => {
            if (!chamberMemberMemberProfileAndPerksBannersAgreementIsAccepted) {
              openChamberMemberMemberProfileAndPerksBannersAgreementModal();
            }
          }}
        />
      )}
    </>
  );
};

export default BannerPickerAgreementOverlay;

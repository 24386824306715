import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
  UserType,
  GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery,
  useGetPerkPartnerDiscountStatsForRootAdminQuery,
  useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery,
} from "../../../graphql/types";
import Loader from "../Loader/Loader";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { getLocalTimestamp } from "../../pages/Root/RootAdmin";
import { UserTypeContext } from "../../../UserTypeContext";
import PerkPartnerStatsLineChart from "./PerkPartnerStatsLineChart";
import PerkPartnerTimeseriesTable from "./PerkPartnerTimeseriesTable";

interface HeadCell {
  id: string;
  label: string;
  convertUtcToTimestamp?: boolean;
  defaultSort?: boolean;
  sortOrder?: Order;
  splitOn?: string;
  isPercent?: boolean;
  disableRightBorder?: boolean;
  utcMilliTimestampObjKey?: string;
  deltaType?: string;
  sticky?: boolean;
  isBool?: boolean;
}

export default function PerkPartnerStatsTable({
  perkPartnerDiscountId,
}: {
  perkPartnerDiscountId: string;
}) {
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const { data: dataPerkPartnerDiscountStatsForPerkPartnerAdminUser } =
    useGetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery({
      variables: {
        id: perkPartnerDiscountId,
      },
      skip: userType === UserType.RootAdmin,
    });

  const { data: dataPerkPartnerDiscountStatsForRootAdmin } =
    useGetPerkPartnerDiscountStatsForRootAdminQuery({
      variables: {
        id: perkPartnerDiscountId,
      },
      skip: userType === UserType.PerkPartnerDiscountAdmin,
    });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      dataPerkPartnerDiscountStatsForPerkPartnerAdminUser ||
      dataPerkPartnerDiscountStatsForRootAdmin
    ) {
      setIsLoading(false);
    }
  }, [
    dataPerkPartnerDiscountStatsForPerkPartnerAdminUser,
    dataPerkPartnerDiscountStatsForRootAdmin,
  ]);

  if (isLoading) {
    return (
      <div
        style={{
          marginTop: "46px",
          marginBottom: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
          style={{
            marginBottom: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Perk Stats
        </h1>
        <Loader />
      </div>
    );
  }

  return (
    <Grid
      container
      style={{
        marginTop: "56px",
      }}
      spacing={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <h1
        style={{ marginBottom: "0px", textAlign: "center", fontWeight: "bold" }}
      >
        Perk Stats
      </h1>
      <div
        style={{
          marginTop: "10px",
          marginBottom: "5px",
          textAlign: "center",
          fontSize: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {userType === UserType.PerkPartnerDiscountAdmin && (
          <>
            <span style={{ color: "black" }}>
              Total Views:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
                  ?.getPerkPartnerDiscountStats?.totalViewCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Opens:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
                  ?.getPerkPartnerDiscountStats?.totalOpenCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Opens From Views Conversion Rate:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {
                  dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
                    ?.getPerkPartnerDiscountStats
                    ?.totalOpensFromViewsConversionRatePercentage
                }
                %
              </span>
            </span>
            <div style={{ height: "15px" }} />
          </>
        )}
        {userType === UserType.RootAdmin && (
          <>
            <span style={{ color: "black" }}>
              Total Views:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalViewCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Unverified Views:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalUnverifiedViewCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Verified Views:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalVerifiedViewCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Ineligible Views:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalIneligibleViewCount || 0}
              </span>
            </span>
            <div style={{ height: "20px" }} />
            <span style={{ color: "black" }}>
              Total Opens:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalOpenCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Unverified Opens:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalUnverifiedOpenCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Verified Opens:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalVerifiedOpenCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Ineligible Opens:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalIneligibleOpenCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Opens From Views Conversion Rate:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {
                  dataPerkPartnerDiscountStatsForRootAdmin
                    ?.getPerkPartnerDiscountStats
                    ?.totalOpensFromViewsConversionRatePercentage
                }
                %
              </span>
            </span>
            <div style={{ height: "20px" }} />
            <span style={{ color: "black" }}>
              Total Redirects:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalRedeemCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total No Employer Selected Redrects:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalUnverifiedRedeemCount ||
                  0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Employer Selected Redirects:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats?.totalVerifiedRedeemCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Total Employer Selected Without Access to Email Redirects:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {dataPerkPartnerDiscountStatsForRootAdmin
                  ?.getPerkPartnerDiscountStats
                  ?.totalVerifiedNoAccessToEmailRedeemCount || 0}
              </span>
            </span>
            <span style={{ color: "black" }}>
              Redirects From Opens Conversion Rate:{" "}
              <span
                style={{
                  color: "#E1A731",
                }}
              >
                {
                  dataPerkPartnerDiscountStatsForRootAdmin
                    ?.getPerkPartnerDiscountStats
                    ?.totalRedeemCountFromOpensConversionRatePercentage
                }
                %
              </span>
            </span>
            <div style={{ height: "20px" }} />
          </>
        )}
      </div>

      {userType === UserType.RootAdmin &&
      dataPerkPartnerDiscountStatsForRootAdmin?.getPerkPartnerDiscountStats
        ?.splitByChamber &&
      dataPerkPartnerDiscountStatsForRootAdmin.getPerkPartnerDiscountStats
        ?.splitByChamber.length > 0 ? (
        <PerkPartnerDiscountAdminUsersTable
          allChartData={
            dataPerkPartnerDiscountStatsForRootAdmin.getPerkPartnerDiscountStats
              .splitByChamber
          }
          headCells={[
            {
              id: "chamberName",
              label: "Chamber",
              defaultSort: true,
              sortOrder: "desc",
              sticky: true,
            },
            {
              id: "chamberIsEnabledForPerkPartnerDiscount",
              label: "Perk Enabled",
              isBool: true,
            },
            {
              id: "totalViewCount",
              label: "Total Views",

              disableRightBorder: true,
            },
            {
              id: "unverifiedViewCount",
              label: "Unverified Views",
              disableRightBorder: true,
            },
            {
              id: "verifiedViewCount",
              label: "Verified Views",
              disableRightBorder: true,
            },
            {
              id: "ineligibleViewCount",
              label: "Ineligible Views",
            },
            {
              id: "totalOpenCount",
              label: "Total Opens",

              disableRightBorder: true,
            },
            {
              id: "unverifiedOpenCount",
              label: "Unverified Opens",
              disableRightBorder: true,
            },
            {
              id: "verifiedOpenCount",
              label: "Verified Opens",
              disableRightBorder: true,
            },
            {
              id: "ineligibleOpenCount",
              label: "Ineligible Opens",
            },
            {
              id: "totalRedeemCount",
              label: "Total Redirects",
              disableRightBorder: true,
            },
            {
              id: "unverifiedRedeemCount",
              label: "No Employer Selected Redrects",
              disableRightBorder: true,
            },
            {
              id: "verifiedRedeemCount",
              label: "Employer Selected Redirects",
              disableRightBorder: true,
            },
            {
              id: "verifiedNoAccessToEmailRedeemCount",
              label: "Employer Selected Without Access to Email Redrects",
              disableRightBorder: true,
            },
          ]}
        />
      ) : null}

      {userType === UserType.PerkPartnerDiscountAdmin &&
      dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
        ?.getPerkPartnerDiscountStats?.splitByChamber &&
      dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
        .getPerkPartnerDiscountStats?.splitByChamber.length > 0 ? (
        <PerkPartnerDiscountAdminUsersTable
          allChartData={
            dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
              .getPerkPartnerDiscountStats.splitByChamber
          }
          headCells={[
            {
              id: "chamberName",
              label: "Chamber",
            },
            {
              id: "totalViewCount",
              label: "Views",
              defaultSort: true,
              sortOrder: "desc",
            },
            {
              id: "totalOpenCount",
              label: "Opens",
              defaultSort: true,
              sortOrder: "desc",
            },
          ]}
        />
      ) : null}

      <Box sx={{ width: "100%", p: 5, pt: 4, pb: 2 }}>
        <Paper
          elevation={0}
          sx={{ p: 3, borderRadius: 4, border: "1px solid #e0e0e0" }}
        >
          <PerkPartnerStatsLineChart
            timeseriesData={
              dataPerkPartnerDiscountStatsForRootAdmin
                ?.getPerkPartnerDiscountStats?.timeseriesData ||
              dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
                ?.getPerkPartnerDiscountStats?.timeseriesData
            }
            userType={userType}
          />
        </Paper>
      </Box>

      <Box sx={{ width: "100%", p: 5, pt: 0, pb: 4 }}>
        <Paper elevation={0} sx={{ p: 3, borderRadius: 4 }}>
          <PerkPartnerTimeseriesTable
            timeseriesData={
              dataPerkPartnerDiscountStatsForRootAdmin
                ?.getPerkPartnerDiscountStats?.timeseriesData ||
              dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
                ?.getPerkPartnerDiscountStats?.timeseriesData
            }
            userType={userType}
          />
        </Paper>
      </Box>

      {dataPerkPartnerDiscountStatsForPerkPartnerAdminUser
        ?.getPerkPartnerDiscountStats?.splitByChamber?.length === 0 ||
      dataPerkPartnerDiscountStatsForRootAdmin?.getPerkPartnerDiscountStats
        ?.splitByChamber?.length === 0 ? (
        <h3 style={{ marginBottom: "39px" }}>No Stats Yet</h3>
      ) : null}
    </Grid>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: any[],
  comparator: (a: any, b: any) => number
): any[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export enum DeltaType {
  INCREASE = "increase",
  DECREASE = "decrease",
}

interface FullPageChamberMemberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function PerkPartnerDiscountAdminUsersTableHeader(
  props: FullPageChamberMemberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              borderRight: headCell.disableRightBorder
                ? undefined
                : index + 1 < headCells.length
                ? "1px solid rgba(224, 224, 224, 1)"
                : undefined,
              padding: "8px",
              position: headCell.sticky ? "sticky" : undefined,
              left: headCell.sticky ? 0 : undefined,
              zIndex: headCell.sticky ? 5 : undefined,
            }}
            key={headCell.id}
            align={"center"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function PerkPartnerDiscountAdminUsersTable({
  allChartData,
  headCells,
}: {
  allChartData:
    | GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery["getPerkPartnerDiscountStats"]["splitByChamber"];
  headCells: HeadCell[];
}) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>(headCells[0].id);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // Find first numerical column or use default sort if specified
    const firstNumericalColumn = headCells.find(
      (cell) =>
        !cell.isBool &&
        cell.id !== "chamberName" &&
        typeof (allChartData?.[0] as any)?.[cell.id] === "number"
    );

    if (firstNumericalColumn) {
      setOrderBy(firstNumericalColumn.id);
      setOrder("desc");
    } else {
      const defaultSortCell = headCells.find((cell) => cell.defaultSort);
      if (defaultSortCell) {
        setOrderBy(defaultSortCell.id);
        setOrder(defaultSortCell.sortOrder || "asc");
      }
    }
  }, [allChartData, headCells]); // Empty dependency array to ensure it only runs once on mount

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isCurrentColumn = orderBy === property;
    const isNumericalColumn =
      !headCells.find((cell) => cell.id === property)?.isBool &&
      property !== "chamberName" &&
      typeof (allChartData?.[0] as any)?.[property] === "number";

    let newOrder: Order;
    if (isCurrentColumn) {
      newOrder = order === "asc" ? "desc" : "asc";
    } else {
      newOrder = isNumericalColumn ? "desc" : "asc";
    }

    setOrder(newOrder);
    setOrderBy(property);

    // Smooth scroll to top
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort((allChartData || []) as any, getComparator(order, orderBy)),
    [allChartData, order, orderBy]
  );

  return (
    <Box
      sx={{
        maxWidth: "95%",
        margin: "auto",
      }}
    >
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "unset",
        }}
      >
        <TableContainer
          ref={tableContainerRef}
          sx={{
            maxHeight: "400px",
            minHeight: "100px",
            scrollBehavior: "smooth",
          }}
        >
          <Table
            sx={{
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
            style={{
              tableLayout: undefined,
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <PerkPartnerDiscountAdminUsersTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody
              style={{
                position: "relative",
              }}
            >
              {visibleRows.map((rowData, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {headCells.map(
                      (
                        {
                          id,
                          convertUtcToTimestamp,
                          splitOn,
                          isPercent,
                          disableRightBorder,
                          utcMilliTimestampObjKey,
                          deltaType,
                          sticky,
                          isBool,
                        },
                        index
                      ) => {
                        let columnData = rowData[id];

                        if (splitOn) {
                          columnData = columnData
                            .split(splitOn)
                            .join(`${splitOn} `);
                        }

                        return (
                          <TableCell
                            size="medium"
                            style={{
                              fontSize: "15px",
                              borderRight: disableRightBorder
                                ? undefined
                                : index + 1 < headCells.length
                                ? "1px solid rgba(224, 224, 224, 1)"
                                : undefined,
                              padding: "8px",
                              color:
                                deltaType && columnData !== 0
                                  ? rowData[deltaType] === DeltaType.INCREASE
                                    ? "green"
                                    : rowData[deltaType] === DeltaType.DECREASE
                                    ? "red"
                                    : undefined
                                  : undefined,
                              position: sticky ? "sticky" : undefined,
                              left: sticky ? 0 : undefined,
                              backgroundColor: sticky ? "white" : undefined,
                            }}
                            align={"center"}
                          >
                            {deltaType && columnData !== 0
                              ? rowData[deltaType] === DeltaType.INCREASE
                                ? "+"
                                : rowData[deltaType] === DeltaType.DECREASE
                                ? ""
                                : ""
                              : ""}
                            {isBool ? (columnData ? "Yes" : "No") : ""}
                            {utcMilliTimestampObjKey
                              ? rowData[utcMilliTimestampObjKey]
                              : convertUtcToTimestamp
                              ? getLocalTimestamp(columnData)
                              : columnData}
                            {isPercent ? "%" : ""}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

import React, { ReactElement } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@material-ui/core";
import enumStrings from "../../enumStrings";

export enum ButtonConfigType {
  YesNoConfig = "yesNoConfig",
  OkConfig = "okConfig",
}

export interface YesNoButtonConfig {
  buttonType: ButtonConfigType.YesNoConfig;
  yesButtonAction: () => void;
  noButtonAction: () => void;
}

export interface OkButtonConfig {
  buttonType: ButtonConfigType.OkConfig;
  okButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "5px",
      textAlign: "center",
      minHeight: "275px",
      width: "500px",
      maxWidth: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(2, 4, 3),
    },
    yesButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    noButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#ff0000",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    okButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
  })
);

const ButtonForConfig = ({
  buttonConfig,
}: {
  buttonConfig: YesNoButtonConfig | OkButtonConfig;
}): ReactElement => {
  const classes = useStyles();

  if (buttonConfig.buttonType === ButtonConfigType.YesNoConfig) {
    return (
      <div>
        <Button
          onClick={buttonConfig.yesButtonAction}
          className={classes.yesButton}
          variant="contained"
        >
          {enumStrings.chamberModal.yesText}
        </Button>
        <Button
          onClick={buttonConfig.noButtonAction}
          className={classes.noButton}
          variant="contained"
        >
          {enumStrings.chamberModal.noText}
        </Button>
      </div>
    );
  }

  return (
    <Button
      onClick={buttonConfig.okButtonAction}
      className={classes.okButton}
      variant="contained"
    >
      {enumStrings.chamberModal.okText}
    </Button>
  );
};

const ModalContent = ({
  message,
  buttonConfig,
  loading,
}: {
  message: string;
  buttonConfig: YesNoButtonConfig | OkButtonConfig;
  loading: boolean;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      {loading ? (
        <CircularProgress style={{ color: "#E1A731", margin: "auto" }} />
      ) : (
        <div>
          <h2
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            {message}
          </h2>
          <ButtonForConfig buttonConfig={buttonConfig} />
        </div>
      )}
    </div>
  );
};

const ConfirmationModal = ({
  open,
  loading,
  handleClose,
  message,
  buttonConfig,
}: {
  open: boolean;
  loading: boolean;
  handleClose: () => void;
  message: string;
  buttonConfig: YesNoButtonConfig | OkButtonConfig;
}): ReactElement => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <ModalContent
        loading={loading}
        message={message}
        buttonConfig={buttonConfig}
      />
    </Modal>
  );
};

export default ConfirmationModal;

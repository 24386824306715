import { ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn } from "../../../graphql/types";
import useGetChamberFromUserIdInAuth from "./useGetChamberFromUserIdInAuth";

type StartDateActivationInfo = Pick<
  ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
  "startDateUtcMilli"
>;

const useIsMemberProfileAndPerksBannersActiveForChamberMember = ({
  activeMemberProfileAndPerksBannersSettings,
}: {
  activeMemberProfileAndPerksBannersSettings:
    | { activationInfo: StartDateActivationInfo }
    | undefined
    | null;
}) => {
  const chamberInfo = useGetChamberFromUserIdInAuth();

  // member profiel & perks banners are active for specified member IF chamber engage is enabled for chamber, and a member profile & perks banner is active for the member
  if (
    chamberInfo?.chamberEngageSettings?.isActive &&
    activeMemberProfileAndPerksBannersSettings?.activationInfo &&
    Date.now() >=
      activeMemberProfileAndPerksBannersSettings?.activationInfo
        ?.startDateUtcMilli
  ) {
    return true;
  }

  return false;
};

export default useIsMemberProfileAndPerksBannersActiveForChamberMember;

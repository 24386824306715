import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Chip,
  CircularProgress,
  makeStyles,
  Modal,
} from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  UserType,
  useActivateMemberProfileAndPerksBannersForChamberMemberMutation,
  GetAllChamberMembers_RootAndChamberAdminOnlyDocument,
  useGetAllChamberMembers_RootAndChamberAdminOnlyQuery,
  ChamberMemberReturn,
  useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation,
  useReactivateMemberProfileAndPerksBannersForChamberMemberMutation,
  ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
  GetUser_RootAdminChamberAdminQuery,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../common/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import { useHistory, useLocation } from "react-router-dom";
import { UserTypeContext } from "../../../UserTypeContext";
import { getLocalTimestamp } from "../Root/RootAdmin";
import LogoImage from "../../common/utilities/LogoImage";
import {
  getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli,
  useGetGraphqlChamberIdVariableObjForRootAdmin,
} from "./ManageChamberEngageCategoryAdvertisingBanners";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ExportButton from "../../common/ExportButton";
import VideoTutorialButton from "../../common/VideoTutorialButton";

const numberOfMonthsTillExpiry = 12;

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    backgroundColor: "#E1A731",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    marginTop: "33px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: "33px",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: "800px",
    width: "740px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(6),
    position: "relative",
  },
  yesButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "white",
    fontWeight: "bold",
    height: "40px",
  },
  okButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#37474f",
    color: "white",
    fontWeight: "bold",
    height: "40px",
  },
  filledDatePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    height: "55px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white !important",
      borderRadius: "15px !important",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "#37474f",
    },
    "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
      color: "black",
      opacity: 1,
      "-webkit-text-fill-color": "black",
    },
  },
}));

type CustomActiveMemberProfileAndPerksBannersSettingsReturnForTable = {
  activationInfo: Omit<
    ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
    | "sentEmailNotificationOfUpcomingExpiry"
    | "chamberMemberHasAcceptedAgreement"
  >;
  reactivationInfo?:
    | Omit<
        ActiveMemberProfileAndPerksBannersSettingsActivationInfoReturn,
        | "sentEmailNotificationOfUpcomingExpiry"
        | "chamberMemberHasAcceptedAgreement"
      >
    | undefined
    | null;
};

type Goner = {
  activeMemberProfileAndPerksBannersSettings?:
    | CustomActiveMemberProfileAndPerksBannersSettingsReturnForTable
    | undefined
    | null;
};

type Bongus = Omit<
  ChamberMemberReturn,
  "activeMemberProfileAndPerksBannersSettings"
> &
  Goner & {
    statusForTable: string;
    annualFeeForTable: number | undefined | null;
    startDateForTable: number | undefined | null;
    expiryDateForTable: number | undefined | null;
  };

export type AllTableAllChamberMembersForChamberDataType = Bongus[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableAllChamberMembersForChamberDataType,
  comparator: (a: any, b: any) => number
): AllTableAllChamberMembersForChamberDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
}

interface FullPageAllChamberMembersForChamberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageAllChamberMembersForChamberTableHead(
  props: FullPageAllChamberMembersForChamberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells: HeadCell[] = [
  {
    id: "memberLogo",
    label: "",
  },
  {
    id: "name",
    label: "Member Name",
  },
  {
    id: "configButtons",
    label: "",
  },
  {
    id: "statusForTable",
    label: "Status",
    alignCenter: true,
  },
  {
    id: "annualFeeForTable",
    label: "Annual Fee",
    alignCenter: true,
  },
  {
    id: "startDateForTable",
    label: "Start Date",
    alignCenter: true,
  },
  {
    id: "expiryDateForTable",
    label: "Expiration Date",
    alignCenter: true,
  },
];

const useGetChamberIdToUseToPullAllChamberMembersForRootOrChamberAdmin = () => {
  const {
    state: { userType },
  } = useContext(UserTypeContext);
  const chamberInfo = useGetChamberFromUserIdInAuth();
  const location = useLocation();
  const chamberIdToUse = useMemo(() => {
    if (userType === UserType.RootAdmin) {
      return (
        (location.state || { chamberId: undefined }) as {
          chamberId: string | undefined;
        }
      ).chamberId;
    }
    return chamberInfo?.id;
  }, [chamberInfo?.id, location.state, userType]);

  return chamberIdToUse;
};

function FullPageManageAllChamberMembersForChamberTable({
  allChamberMembersForChamberData,
}: {
  allChamberMembersForChamberData: AllTableAllChamberMembersForChamberDataType;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [searchInput, setSearchInput] = React.useState("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const allTableAllChamberMembersForChamberData: AllTableAllChamberMembersForChamberDataType =
    React.useMemo(() => {
      if (!allChamberMembersForChamberData) {
        return [];
      }

      const allChamberMembersForChamberArr =
        allChamberMembersForChamberData.slice();

      if (searchInput.length > 0) {
        return allChamberMembersForChamberData.filter((i) => {
          return i.name.toLowerCase().match(searchInput.toLowerCase());
        });
      }

      return allChamberMembersForChamberArr;
    }, [allChamberMembersForChamberData, searchInput]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        allTableAllChamberMembersForChamberData,
        getComparator(order, orderBy)
      ),
    [allTableAllChamberMembersForChamberData, order, orderBy]
  );

  const [dbProcessInProgress, setDbProcessInProgress] = useState(false);
  const [
    chamberMemberToShowCaseInAnyOpenMmodal,
    setChamberMemberToShowCaseInAnyOpenMmodal,
  ] = useState<Bongus | null>(null);

  const [openActivateForMemberModal, setOpenActivateForMemberModal] =
    useState(false);
  const handleOpenActivateForMemberModal = useCallback(
    ({ chamberMember }: { chamberMember: Bongus }) => {
      setChamberMemberToShowCaseInAnyOpenMmodal(chamberMember);
      setOpenActivateForMemberModal(true);
    },
    []
  );

  const [openDeactivateForMemberModal, setOpenDeactivateForMemberModal] =
    useState(false);
  const handleOpenDeactivateForMemberModal = useCallback(
    ({ chamberMember }: { chamberMember: Bongus }) => {
      setChamberMemberToShowCaseInAnyOpenMmodal(chamberMember);
      setOpenDeactivateForMemberModal(true);
    },
    []
  );

  const [openReactivateForMemberModal, setOpenReactivateForMemberModal] =
    useState<"newRe" | "viewRe" | false>(false);
  const handleOpenReactivateForMemberModal = useCallback(
    ({
      chamberMember,
      modalType,
    }: {
      chamberMember: Bongus;
      modalType: "newRe" | "viewRe";
    }) => {
      setChamberMemberToShowCaseInAnyOpenMmodal(chamberMember);
      setAdvertisingStartDateChange(
        chamberMember.activeMemberProfileAndPerksBannersSettings?.activationInfo
          ?.startDateUtcMilli || 0
      );
      setOpenReactivateForMemberModal(modalType);
    },
    []
  );

  const [showAdvertisingStartDateError, setShowAdvertisingStartDateError] =
    useState(false);
  const [advertisingStartDateChange, setAdvertisingStartDateChange] =
    useState(0);
  const handleAdvdertisingStartDateChange = useCallback((val) => {
    setShowAdvertisingStartDateError(false);
    setAdvertisingStartDateChange(dayjs(val).startOf("day").valueOf());
  }, []);

  const handleCloseActivateForMemberModal = useCallback(() => {
    setAdvertisingStartDateChange(0);
    setShowAdvertisingStartDateError(false);
    setOpenActivateForMemberModal(false);
  }, []);

  const handleCloseReactivateForMemberModal = useCallback(() => {
    setAdvertisingStartDateChange(0);
    setShowAdvertisingStartDateError(false);
    setOpenReactivateForMemberModal(false);
  }, []);

  const handleCloseDeactivateForMemberModal = useCallback(() => {
    setOpenDeactivateForMemberModal(false);
  }, []);

  const graphqlChamberIdVariableObjForRootAdmin =
    useGetGraphqlChamberIdVariableObjForRootAdmin();
  const chamberIdToUse =
    useGetChamberIdToUseToPullAllChamberMembersForRootOrChamberAdmin();

  const [activateMemberProfileAndPerksBannersForChamberMember] =
    useActivateMemberProfileAndPerksBannersForChamberMemberMutation();
  const handleActivateForMember = useCallback(async () => {
    setDbProcessInProgress(true);

    //validate data
    if (!(advertisingStartDateChange > 0)) {
      if (advertisingStartDateChange <= 0) {
        setShowAdvertisingStartDateError(true);
      }

      setDbProcessInProgress(false);
      return;
    }

    await activateMemberProfileAndPerksBannersForChamberMember({
      variables: {
        ...graphqlChamberIdVariableObjForRootAdmin,
        chamberMemberId: chamberMemberToShowCaseInAnyOpenMmodal?.id || "",
        startDateUtcMilli: advertisingStartDateChange,
      },
      refetchQueries: [
        {
          query: GetAllChamberMembers_RootAndChamberAdminOnlyDocument,
          variables: {
            chamberId: chamberIdToUse,
          },
        },
      ],
    });

    toast.success("Activated Successfully");
    handleCloseActivateForMemberModal();
    setDbProcessInProgress(false);
  }, [
    activateMemberProfileAndPerksBannersForChamberMember,
    advertisingStartDateChange,
    chamberIdToUse,
    chamberMemberToShowCaseInAnyOpenMmodal?.id,
    graphqlChamberIdVariableObjForRootAdmin,
    handleCloseActivateForMemberModal,
  ]);

  const [reactivateMemberProfileAndPerksBannersForChamberMember] =
    useReactivateMemberProfileAndPerksBannersForChamberMemberMutation();
  const handleReactivateForMember = useCallback(async () => {
    setDbProcessInProgress(true);

    await reactivateMemberProfileAndPerksBannersForChamberMember({
      variables: {
        ...graphqlChamberIdVariableObjForRootAdmin,
        chamberMemberId: chamberMemberToShowCaseInAnyOpenMmodal?.id || "",
      },
      refetchQueries: [
        {
          query: GetAllChamberMembers_RootAndChamberAdminOnlyDocument,
          variables: {
            chamberId: chamberIdToUse,
          },
        },
      ],
    });

    toast.success("Re-activated Successfully");
    handleCloseReactivateForMemberModal();
    setDbProcessInProgress(false);
  }, [
    chamberIdToUse,
    chamberMemberToShowCaseInAnyOpenMmodal?.id,
    graphqlChamberIdVariableObjForRootAdmin,
    handleCloseReactivateForMemberModal,
    reactivateMemberProfileAndPerksBannersForChamberMember,
  ]);

  const [deactivateMemberProfileAndPerksBannersForChamberMember] =
    useDeactivateMemberProfileAndPerksBannersForChamberMemberMutation();
  const handleDeactivateForMember = useCallback(async () => {
    setDbProcessInProgress(true);

    await deactivateMemberProfileAndPerksBannersForChamberMember({
      variables: {
        ...graphqlChamberIdVariableObjForRootAdmin,
        chamberMemberId: chamberMemberToShowCaseInAnyOpenMmodal?.id || "",
      },
      refetchQueries: [
        {
          query: GetAllChamberMembers_RootAndChamberAdminOnlyDocument,
          variables: {
            chamberId: chamberIdToUse,
          },
        },
      ],
    });

    toast.success("Deactivated Successfully");
    handleCloseDeactivateForMemberModal();
    setDbProcessInProgress(false);
  }, [
    chamberIdToUse,
    chamberMemberToShowCaseInAnyOpenMmodal?.id,
    deactivateMemberProfileAndPerksBannersForChamberMember,
    graphqlChamberIdVariableObjForRootAdmin,
    handleCloseDeactivateForMemberModal,
  ]);

  const chamberInfo = useGetChamberFromUserIdInAuth() as
    | GetUser_RootAdminChamberAdminQuery["getUser"]["chamberInfo"]
    | undefined;

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {openDeactivateForMemberModal && (
        <Modal className={classes.modal} open={openDeactivateForMemberModal}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "450px" }}
          >
            <h1 style={{ fontWeight: "bold", margin: 0 }}>
              Deactivate {chamberMemberToShowCaseInAnyOpenMmodal?.name}’s
              Featured Member Profile & Perks Banners
            </h1>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "scroll",
                alignItems: "center",
                paddingRight: "25px",
                paddingLeft: "25px",
                paddingTop: "30px",
              }}
            >
              <h3
                style={{ textAlign: "left", margin: 0, whiteSpace: "pre-wrap" }}
              >
                Are you sure you want to deactivate{" "}
                {chamberMemberToShowCaseInAnyOpenMmodal?.name}'s member profile
                & perk banners? Deactivation is usually done when innapropriate
                or outdated content is being displayed by a member.
                <div style={{ height: 10 }} />
                By deactivating, all display and video banners will be
                immediately removed from{" "}
                {chamberMemberToShowCaseInAnyOpenMmodal?.name}'s member profile
                and perk pages in Chamber Perks App™.
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.yesButton}
                onClick={handleDeactivateForMember}
                variant="contained"
                disabled={dbProcessInProgress}
              >
                {dbProcessInProgress ? (
                  <CircularProgress
                    size={30}
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  "Deactivate"
                )}
              </Button>
              <Button
                className={classes.okButton}
                onClick={handleCloseDeactivateForMemberModal}
                variant="contained"
                disabled={dbProcessInProgress}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {openReactivateForMemberModal && (
        <Modal
          className={classes.modal}
          open={openReactivateForMemberModal ? true : false}
        >
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "600px" }}
          >
            <h1 style={{ fontWeight: "bold", margin: 0 }}>
              {openReactivateForMemberModal === "newRe"
                ? "Re-activate"
                : "View re-activation of"}{" "}
              {chamberMemberToShowCaseInAnyOpenMmodal?.name}'s Member Profile &
              Perks Banners
            </h1>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "scroll",
                alignItems: "center",
                paddingRight: "25px",
                paddingLeft: "25px",
                paddingTop: "30px",
              }}
            >
              <h3
                style={{
                  textAlign: "left",
                  margin: 0,
                  whiteSpace: "pre-wrap",
                }}
              >
                {chamberMemberToShowCaseInAnyOpenMmodal?.name}’s featured member
                profile & perks banners will re-activate as of their current
                expiration date
                {advertisingStartDateChange > 0 ? (
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    (
                    {getLocalTimestamp(
                      getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                        {
                          advertisingStartDateUtcMilli:
                            advertisingStartDateChange,
                          months: numberOfMonthsTillExpiry,
                        }
                      ),
                      true
                    )}
                    )
                  </span>
                ) : (
                  ""
                )}
                .
                <div style={{ height: 10 }} />
              </h3>
              <h3
                style={{ textAlign: "left", margin: 0, whiteSpace: "pre-wrap" }}
              >
                You will automatically receive an email notification 60 days
                before the expiration date.
                <div style={{ height: 10 }} />
                These banners will automatically deactivate 365 days after the
                current expiration date
                {advertisingStartDateChange > 0 ? (
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    (
                    {getLocalTimestamp(
                      getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                        {
                          advertisingStartDateUtcMilli:
                            advertisingStartDateChange,
                          months: numberOfMonthsTillExpiry,
                        }
                      ),
                      true
                    )}
                    )
                  </span>
                ) : (
                  ""
                )}
                .
              </h3>
              <div style={{ height: 20 }} />
              <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>
                Annual Fee
              </h1>
              <div style={{ height: 10 }} />
              <div>
                <Chip
                  label={
                    <h3
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      $
                      {
                        chamberInfo?.chamberEngageSettings
                          ?.memberProfileAndPerkBannerAnnualBannerFeeInDollars
                      }
                    </h3>
                  }
                  style={{
                    backgroundColor: "#E1A731",
                    borderColor: "#E1A731",
                    display: "flex",
                    paddingLeft: 5,
                    paddingRight: 5,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {openReactivateForMemberModal === "newRe" && (
                <Button
                  className={classes.yesButton}
                  onClick={() => {
                    handleReactivateForMember();
                  }}
                  variant="contained"
                  disabled={dbProcessInProgress}
                >
                  {dbProcessInProgress ? (
                    <CircularProgress
                      size={30}
                      style={{
                        color: "white",
                      }}
                    />
                  ) : (
                    "Re-activate"
                  )}
                </Button>
              )}
              <Button
                className={classes.okButton}
                onClick={handleCloseReactivateForMemberModal}
                variant="contained"
                disabled={dbProcessInProgress}
              >
                {openReactivateForMemberModal === "newRe" ? "Cancel" : "Close"}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {openActivateForMemberModal && (
        <Modal className={classes.modal} open={openActivateForMemberModal}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "700px" }}
          >
            <h1 style={{ fontWeight: "bold", margin: 0 }}>
              Activate {chamberMemberToShowCaseInAnyOpenMmodal?.name}'s Enhanced
              Member Profile & Perks Banners
            </h1>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "scroll",
                alignItems: "center",
                paddingRight: "25px",
                paddingLeft: "25px",
                paddingTop: "30px",
              }}
            >
              <h3
                style={{ textAlign: "left", margin: 0, whiteSpace: "pre-wrap" }}
              >
                Enter a start date for this member's enhanced profile & perk
                banners activation.
                <div style={{ height: 10 }} />
                You will automatically receive an email notification 60 days
                before the expiration date.
                <div style={{ height: 10 }} />
                These banners will automatically deactivate 365 days after the
                selected start date
                {advertisingStartDateChange > 0 ? (
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    (
                    {getLocalTimestamp(
                      getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                        {
                          advertisingStartDateUtcMilli:
                            advertisingStartDateChange,
                          months: numberOfMonthsTillExpiry,
                        }
                      ),
                      true
                    )}
                    )
                  </span>
                ) : (
                  ""
                )}
                .
                <div style={{ height: 10 }} />
                You can re-activate these banners at any time for this member by
                clicking re-activate and following the onscreen instructions.
              </h3>

              <div style={{ height: 40 }} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider
                  key={`advertisingStartDate_${chamberMemberToShowCaseInAnyOpenMmodal?.id}`}
                  dateAdapter={AdapterDayjs}
                >
                  <MobileDatePicker
                    disabled={dbProcessInProgress}
                    slotProps={{
                      textField: {
                        InputProps: {
                          disableUnderline: true,
                        },
                        className: classes.filledDatePicker,
                        variant: "filled",
                        sx: {
                          "& .MuiFilledInput-root:before": {
                            borderBottom: "none",
                          },
                          "& .MuiFilledInput-root:after": {
                            borderBottom: "none",
                          },
                          "& .MuiFilledInput-root:hover:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-input.MuiFilledInput-input.Mui-readOnly.MuiInputBase-readOnly":
                            {
                              cursor: "pointer",
                            },
                        },
                      },
                    }}
                    minDate={dayjs(Date.now())}
                    label={"Start Date"}
                    format="ll"
                    onChange={handleAdvdertisingStartDateChange}
                    value={
                      advertisingStartDateChange > 0
                        ? dayjs(advertisingStartDateChange)
                        : undefined
                    }
                  />
                </LocalizationProvider>
                {showAdvertisingStartDateError && (
                  <h3
                    style={{
                      margin: 0,
                      marginTop: theme.spacing(1),
                      color: "red",
                    }}
                  >
                    Select a start date to activate
                  </h3>
                )}
                <div style={{ height: 20 }} />
                <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>
                  Annual Fee
                </h1>
                <div style={{ height: 10 }} />
                <div>
                  <Chip
                    label={
                      <h3
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          margin: 0,
                          textAlign: "center",
                        }}
                      >
                        $
                        {
                          chamberInfo?.chamberEngageSettings
                            ?.memberProfileAndPerkBannerAnnualBannerFeeInDollars
                        }
                      </h3>
                    }
                    style={{
                      backgroundColor: "#E1A731",
                      borderColor: "#E1A731",
                      display: "flex",
                      paddingLeft: 5,
                      paddingRight: 5,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.yesButton}
                onClick={handleActivateForMember}
                variant="contained"
                disabled={dbProcessInProgress}
              >
                {dbProcessInProgress ? (
                  <CircularProgress
                    size={30}
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  "Activate"
                )}
              </Button>
              <Button
                className={classes.okButton}
                onClick={handleCloseActivateForMemberModal}
                variant="contained"
                disabled={dbProcessInProgress}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder="Search by Member Company Name"
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1450,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageAllChamberMembersForChamberTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((chamberMember) => (
                <TableRow key={chamberMember.id}>
                  <TableCell
                    style={{
                      fontSize: "16px",
                    }}
                    component="th"
                    scope="row"
                  >
                    <LogoImage
                      logoSource={chamberMember.logo}
                      size="normal"
                      squared
                      key={chamberMember.name}
                      chamberName={chamberMember.name}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                    }}
                    component="th"
                    scope="row"
                  >
                    {chamberMember.name}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      paddingRight: 30,
                    }}
                    component="th"
                    scope="row"
                  >
                    {chamberMember.activeMemberProfileAndPerksBannersSettings
                      ?.activationInfo ? (
                      <div>
                        {" "}
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#37474f",
                            color: "white",
                            fontSize: "13.5px",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            width: 155,
                            height: 30,
                          }}
                          onClick={() => {
                            handleOpenDeactivateForMemberModal({
                              chamberMember,
                            });
                          }}
                        >
                          Deactivate
                        </Button>
                        <div style={{ height: 10 }} />
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#e1a731",
                            color: "white",
                            fontSize: "13.5px",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            whiteSpace: "pre-wrap",
                            width: 155,
                            ...(!chamberMember
                              .activeMemberProfileAndPerksBannersSettings
                              ?.reactivationInfo
                              ? {
                                  height: 30,
                                }
                              : {}),
                          }}
                          onClick={() => {
                            handleOpenReactivateForMemberModal({
                              chamberMember,
                              modalType: chamberMember
                                .activeMemberProfileAndPerksBannersSettings
                                ?.reactivationInfo
                                ? "viewRe"
                                : "newRe",
                            });
                          }}
                        >
                          {chamberMember
                            .activeMemberProfileAndPerksBannersSettings
                            ?.reactivationInfo
                            ? "View\nRe-activation"
                            : "Re-activate"}
                        </Button>
                        {chamberMember
                          ?.activeMemberProfileAndPerksBannersSettings
                          ?.reactivationInfo && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: 5,
                              fontSize: 14,
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                width: "105%",
                              }}
                            >
                              Re-activation start date:
                            </span>
                            {"\n"}
                            <span>
                              {getLocalTimestamp(
                                chamberMember
                                  ?.activeMemberProfileAndPerksBannersSettings
                                  ?.reactivationInfo?.startDateUtcMilli || 0,
                                true
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#e1a731",
                          color: "white",
                          fontSize: "13.5px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: 155,
                          height: 30,
                        }}
                        onClick={() => {
                          handleOpenActivateForMemberModal({
                            chamberMember,
                          });
                        }}
                      >
                        Activate
                      </Button>
                    )}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      paddingRight: 30,
                    }}
                    component="th"
                    scope="row"
                  >
                    <Chip
                      label={
                        chamberMember.activeMemberProfileAndPerksBannersSettings
                          ?.activationInfo
                          ? "Active"
                          : "Inactive"
                      }
                      variant="outlined"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 16,
                        backgroundColor: chamberMember
                          .activeMemberProfileAndPerksBannersSettings
                          ?.activationInfo
                          ? "#E2ECDE"
                          : "#E3E4E6",
                        borderColor: chamberMember
                          .activeMemberProfileAndPerksBannersSettings
                          ?.activationInfo
                          ? "#4F6C5A"
                          : "#727272",
                        color: chamberMember
                          .activeMemberProfileAndPerksBannersSettings
                          ?.activationInfo
                          ? "#4F6C5A"
                          : "#727272",
                        textAlign: "center",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      paddingRight: 30,
                      width: 230,
                    }}
                    component="th"
                    scope="row"
                  >
                    {chamberMember.activeMemberProfileAndPerksBannersSettings
                      ?.activationInfo
                      ? `$${chamberMember.activeMemberProfileAndPerksBannersSettings?.activationInfo.memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation}`
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      paddingRight: 30,
                    }}
                    component="th"
                    scope="row"
                  >
                    {chamberMember.activeMemberProfileAndPerksBannersSettings
                      ?.activationInfo
                      ? getLocalTimestamp(
                          chamberMember
                            .activeMemberProfileAndPerksBannersSettings
                            .activationInfo.startDateUtcMilli,
                          true
                        )
                      : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      paddingRight: 30,
                    }}
                    component="th"
                    scope="row"
                  >
                    {chamberMember.activeMemberProfileAndPerksBannersSettings
                      ?.activationInfo
                      ? getLocalTimestamp(
                          getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                            {
                              advertisingStartDateUtcMilli:
                                chamberMember
                                  .activeMemberProfileAndPerksBannersSettings
                                  .activationInfo.startDateUtcMilli,
                              months: numberOfMonthsTillExpiry,
                            }
                          ),
                          true
                        )
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

const ManageMemberProfileAndPerksBanners = (): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const [allChamberMembersForChamberData, setAllChamberMembersForChamberData] =
    React.useState<AllTableAllChamberMembersForChamberDataType>([]);

  const [isLoading, setIsLoading] = useState(true);
  const chamberIdToUse =
    useGetChamberIdToUseToPullAllChamberMembersForRootOrChamberAdmin();

  const { data: allChamberMembersForChamber, loading } =
    useGetAllChamberMembers_RootAndChamberAdminOnlyQuery({
      variables: { chamberId: chamberIdToUse || "" },
      skip: !chamberIdToUse,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    const getAllChamberMembersForChamberstats = async () => {
      if (!(allChamberMembersForChamber?.getAllChamberMembers && !loading)) {
        setIsLoading(true);
        setAllChamberMembersForChamberData([]);
        return;
      }

      const allChamberMembersForChamberArr: AllTableAllChamberMembersForChamberDataType =
        [];

      for (const chamberMember of allChamberMembersForChamber?.getAllChamberMembers ||
        []) {
        allChamberMembersForChamberArr.push({
          ...chamberMember,
          statusForTable: chamberMember
            .activeMemberProfileAndPerksBannersSettings?.activationInfo
            ? "Active"
            : "Inactive",
          annualFeeForTable:
            chamberMember.activeMemberProfileAndPerksBannersSettings
              ?.activationInfo
              ?.memberProfileAndPerkBannerAnnualBannerFeeInDollarsAtTimeOfActivation,
          startDateForTable:
            chamberMember.activeMemberProfileAndPerksBannersSettings
              ?.activationInfo?.startDateUtcMilli,
          expiryDateForTable: chamberMember
            .activeMemberProfileAndPerksBannersSettings?.activationInfo
            ?.startDateUtcMilli
            ? getExpiryUtcMilliTimestampThatIsSpecifiedMonthsAfterSpecifiedAdvertisingStartDateUtcMilli(
                {
                  advertisingStartDateUtcMilli:
                    chamberMember.activeMemberProfileAndPerksBannersSettings
                      ?.activationInfo?.startDateUtcMilli || 0,
                  months: numberOfMonthsTillExpiry,
                }
              )
            : undefined,
        });
      }

      setAllChamberMembersForChamberData(
        allChamberMembersForChamberArr
          .slice()
          // sort alphabetically by name
          .sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          // then sort by active status
          .sort((a, b) => {
            if (
              a.activeMemberProfileAndPerksBannersSettings?.activationInfo &&
              b.activeMemberProfileAndPerksBannersSettings?.activationInfo
            ) {
              return 0;
            }
            if (
              a.activeMemberProfileAndPerksBannersSettings?.activationInfo &&
              !b.activeMemberProfileAndPerksBannersSettings?.activationInfo
            ) {
              return -1;
            }
            if (
              !a.activeMemberProfileAndPerksBannersSettings?.activationInfo &&
              b.activeMemberProfileAndPerksBannersSettings?.activationInfo
            ) {
              return 1;
            }
            return 0;
          })
      );

      // set allChamberMembersIdToName
      const allChamberMembersIdToNameObj = {} as {
        [key: string]: Bongus;
      };
      for (const chamberMemberObj of allChamberMembersForChamber.getAllChamberMembers) {
        allChamberMembersIdToNameObj[chamberMemberObj.id] =
          chamberMemberObj as Bongus;
      }

      setIsLoading(false);
    };

    getAllChamberMembersForChamberstats();
  }, [
    allChamberMembersForChamber,
    allChamberMembersForChamber?.getAllChamberMembers,
    loading,
  ]);

  const rootAdminObj = // @ts-ignore
    enumStrings?.groups[userType][
      RedirectUri.chamberEngageManageMemberProfileAndPerkBanners
    ];

  const history = useHistory();

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>
            {rootAdminObj.title}
            {(location?.state as any)?.chamberName &&
              ` for ${(location?.state as any)?.chamberName}`}
          </span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <div style={{ height: 10 }} />
          <div
            style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}
          >
            {userType !== UserType.RootAdmin && (
              <div style={{ paddingRight: "10px", paddingBottom: "10px" }}>
                <ExportButton
                  customOnClick={() => {
                    history.push(
                      `${RedirectUri.chamberAdmin}#scrollToChamberEngage`
                    );
                  }}
                  disablePadding
                  label="View Stats"
                />
              </div>
            )}
            <div style={{ flex: 1 }} />
            <div style={{ paddingBottom: "10px" }}>
              <VideoTutorialButton
                label="How to Activate a Member"
                tutorialUrl="https://share.synthesia.io/embeds/videos/28a96f32-ce4d-48b8-bf2b-6cf6a2e75326"
              />
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {allChamberMembersForChamberData &&
            allChamberMembersForChamberData.length > 0 ? (
              <FullPageManageAllChamberMembersForChamberTable
                allChamberMembersForChamberData={
                  allChamberMembersForChamberData
                }
              />
            ) : (
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                Loading Chamber Members
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ManageMemberProfileAndPerksBanners;

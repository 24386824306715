import { Auth } from "aws-amplify";
import { useCallback, useContext } from "react";
import { UserTypeContext } from "../../../UserTypeContext";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { freshworksLogoutUrl } from "../utilities/getFreshworksInfo";

export const useHandleLogout = () => {
  const {
    setters: { setUserType },
  } = useContext(UserTypeContext);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log(error);
    }

    sessionStorage.clear();

    const userHasAccessedExternalKnowledgeBase = localStorage.getItem(
      enumStrings.localStorageKey.userHasAccessedExternalKnowledgeBase
    );

    if (userHasAccessedExternalKnowledgeBase === "true") {
      localStorage.removeItem(
        enumStrings.localStorageKey.userHasAccessedExternalKnowledgeBase
      );
      window.location.href = freshworksLogoutUrl;

      return;
    }

    window.location.href = RedirectUri.login;
    setUserType("");
  }, [setUserType]);

  return handleLogout;
};

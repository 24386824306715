import { Grid, Tooltip } from "@material-ui/core";
import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Info";
import { Typography } from "antd";
import { useState } from "react";

const PositionedTooltips = ({
  title = "",
  description = "",
  showIt = false,
  disablePadding,
  overrideWeirdStyling,
}: {
  title?: string;
  description?: string | React.ReactElement;
  showIt?: boolean;
  disablePadding?: boolean;
  overrideWeirdStyling?: boolean;
}): React.ReactElement => {
  let [tooltipOpen, setTooltipOpen] = useState(false);

  if (showIt) {
    return (
      <Grid
        item
        container
        xs={6}
        direction="column"
        style={{
          margin: "0px",
          flexBasis: overrideWeirdStyling ? "0%" : "0%",
          flexGrow: 0,
          maxWidth: overrideWeirdStyling ? "50%" : "9%",
        }}
      >
        <Grid item>
          <IconButton
            style={{
              paddingRight: 0,
              ...(disablePadding ? { padding: "0px" } : {}),
            }}
            onClick={() => setTooltipOpen(!tooltipOpen)}
            onBlur={() => setTooltipOpen(false)}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
          >
            <Tooltip
              open={tooltipOpen}
              arrow
              title={
                <div
                  style={{
                    backgroundColor: "#707070",
                  }}
                >
                  {title ? (
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                        paddingBottom: "5px",
                      }}
                    >
                      {title}
                    </Typography>
                  ) : null}
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "12px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {description}
                  </Typography>
                </div>
              }
              placement="right"
            >
              <HelpIcon />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  return <div></div>;
};
export default PositionedTooltips;

import React, { ReactElement, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Loader from "../Loader/Loader";
import { TextField } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useChamberMemberChangeAdminConsoleLoginEmailMutation } from "../../../graphql/types";
import SendLoginInformationModal from "../SendLoginInformationModal";

export enum ButtonConfigType {
  YesNoConfig = "yesNoConfig",
  OkConfig = "okConfig",
}

export interface YesNoButtonConfig {
  buttonType: ButtonConfigType.YesNoConfig;
  yesButtonAction: () => {};
}

export interface OkButtonConfig {
  buttonType: ButtonConfigType.OkConfig;
  okButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invalidError: {
      textAlign: "center",
      color: "red",
    },
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "5px",
      textAlign: "center",
      width: "500px",
      minHeight: "300px",
      maxWidth: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(2, 4, 3),
    },
    yesButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    customButton: {
      margin: "10px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    noButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#ff0000",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    okButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    companyInput: {
      backgroundColor: "#F2F2F6",
      paddingBottom: "10px",
      borderRadius: "15px",
      marginTop: "50px",
      width: "431px",
      height: "55px",
      maxWidth: "85%",
      "& .MuiOutlinedInput-root": {
        borderRadius: "15px",
        "& fieldset": {
          borderColor: "black",
          borderRadius: "15px",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#E1A731",
        },
      },
      "& label.Mui-focused": {
        color: "#E1A731",
      },
      "& label.Mui": {
        color: "black",
      },
    },
  })
);

const validationSchema = yup.object({
  adminConsoleLoginEmail: yup
    .string()
    .email("Not a valid email")
    .required("New dashboard email username is required"),
});

const initialValues = {
  adminConsoleLoginEmail: "",
};

const ChangeEmailUsernameModal = ({
  chamberMemberId,
  currentAdminConsoleLoginEmail,
  open,
  handleClose,
  chamberMemberName,
  isSuspended,
}: {
  chamberMemberId: string | undefined;
  currentAdminConsoleLoginEmail: string | undefined;
  open: boolean;
  handleClose: () => void;
  chamberMemberName: string;
  isSuspended: boolean;
}): ReactElement => {
  const classes = useStyles();
  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [
    plusAddressedEmailSuggestionModalIsOpen,
    setPlusAddressedEmailSuggestionModalIsOpen,
  ] = useState(false);

  const [suggestedPlusAddressedEmail, setSuggestedPlusAddressedEmail] =
    useState("");

  const [
    loadingChangingAdminConsoleLoginEmailModal,
    setLoadingChangingAdminConsoleLoginEmailModal,
  ] = useState(false);
  const [
    newPasswordFromAdminConsoleLoginEmailChange,
    setNewPasswordFromAdminConsoleLoginEmailChange,
  ] = useState("");
  const [
    successfullyChangedAdminConsoleLoginEmail,
    setSuccessfullyChangedAdminConsoleLoginEmail,
  ] = useState(false);

  const [chamberMemberChangeAdminConsoleLoginEmail] =
    useChamberMemberChangeAdminConsoleLoginEmailMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input) => {
      if (!chamberMemberId || !currentAdminConsoleLoginEmail) {
        return;
      }

      setLoadingChangingAdminConsoleLoginEmailModal(true);
      // api call here
      const { data } = await chamberMemberChangeAdminConsoleLoginEmail({
        variables: {
          chamberMemberId: chamberMemberId,
          currentAdminConsoleLoginEmail: currentAdminConsoleLoginEmail,
          newAdminConsoleLoginEmail: input.adminConsoleLoginEmail,
        },
        refetchQueries: ["getAdminConsoleLoginEmailForChamberMember"],
        awaitRefetchQueries: true,
      });

      if (
        data?.chamberMemberChangeAdminConsoleLoginEmail
          .adminConsoleLoginEmailChangedSuccessfully === false
      ) {
        setInvalidError(true);
        setCustomError(
          `Unable to change dashboard email username. ${
            (
              data?.chamberMemberChangeAdminConsoleLoginEmail.failureReason ||
              ""
            ).includes("UsernameExistsException")
              ? "An account with the given email already exists."
              : data?.chamberMemberChangeAdminConsoleLoginEmail.failureReason ||
                ""
          }`
        );

        if (
          (
            data?.chamberMemberChangeAdminConsoleLoginEmail.failureReason || ""
          ).includes("UsernameExistsException")
        ) {
          setSuggestedPlusAddressedEmail(
            (
              data?.chamberMemberChangeAdminConsoleLoginEmail.failureReason ||
              ""
            )
              .trim()
              .split(" ")[1]
          );
          setPlusAddressedEmailSuggestionModalIsOpen(true);
        }

        setLoadingChangingAdminConsoleLoginEmailModal(false);
        return;
      }

      setNewPasswordFromAdminConsoleLoginEmailChange(
        data?.chamberMemberChangeAdminConsoleLoginEmail.password || ""
      );
      setSuccessfullyChangedAdminConsoleLoginEmail(
        data?.chamberMemberChangeAdminConsoleLoginEmail
          .adminConsoleLoginEmailChangedSuccessfully || false
      );
      setLoadingChangingAdminConsoleLoginEmailModal(false);
    },
  });

  return (
    <>
      <Modal
        className={classes.modal}
        open={plusAddressedEmailSuggestionModalIsOpen}
        onClose={() => setPlusAddressedEmailSuggestionModalIsOpen(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h3
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            A dashboard account with the email username{" "}
            <span style={{ fontWeight: "bold" }}>
              {formik.values.adminConsoleLoginEmail}
            </span>{" "}
            already exists.
            {"\n\n"}An alternative email username that can be used to create an
            account for the dashboard is{" "}
            <span style={{ fontWeight: "bold" }}>
              {suggestedPlusAddressedEmail}
            </span>
            {"\n"}This alternative email username uses plus addressing; all
            emails sent to this alternative email address will still go to the
            original email's inbox.
            {"\n\n"}Click{" "}
            <span style={{ fontWeight: "bold" }}>Use Alternative</span> to use
            the plus-addressed email username. Otherwise, click{" "}
            <span style={{ fontWeight: "bold" }}>Back</span> to try another
            email username.
          </h3>
          <br />
          <Button
            onClick={() => {
              setInvalidError(false);
              formik.resetForm();
              formik.setFieldValue(
                "adminConsoleLoginEmail",
                suggestedPlusAddressedEmail.trim().toLowerCase()
              );
              setPlusAddressedEmailSuggestionModalIsOpen(false);
            }}
            className={classes.customButton}
            variant="contained"
          >
            Use Alternative
          </Button>
          <Button
            onClick={() => {
              setPlusAddressedEmailSuggestionModalIsOpen(false);
            }}
            className={classes.okButton}
            variant="contained"
          >
            Back
          </Button>
        </div>
      </Modal>
      <Modal
        className={classes.modal}
        open={open}
        onClose={
          loadingChangingAdminConsoleLoginEmailModal ? () => null : handleClose
        }
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          {loadingChangingAdminConsoleLoginEmailModal ? (
            <Loader />
          ) : newPasswordFromAdminConsoleLoginEmailChange.length > 0 &&
            successfullyChangedAdminConsoleLoginEmail ? (
            <>
              <SendLoginInformationModal
                isSuspended={isSuspended}
                handleClose={handleClose}
                open={
                  newPasswordFromAdminConsoleLoginEmailChange.length > 0 &&
                  successfullyChangedAdminConsoleLoginEmail
                }
                memberAdminPortalEmail={formik.values.adminConsoleLoginEmail.trim()}
                memberCompanyName={chamberMemberName}
                chamberMemberId={chamberMemberId}
                type="changeLoginEmailUsername"
              />
            </>
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <h3 style={{ marginTop: "25px", marginBottom: "-15px" }}>
                Type the new dashboard email username below and click update to
                confirm the change.
              </h3>
              <TextField
                type="text"
                label="New Dashboard Email Username *"
                className={classes.companyInput}
                variant="outlined"
                id="adminConsoleLoginEmail"
                name="adminConsoleLoginEmail"
                value={formik.values.adminConsoleLoginEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.adminConsoleLoginEmail &&
                  Boolean(formik.errors.adminConsoleLoginEmail)
                }
                helperText={
                  formik.touched.adminConsoleLoginEmail &&
                  formik.errors.adminConsoleLoginEmail
                }
              />
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
              <div style={{ paddingTop: "20px" }}>
                <Button
                  type="submit"
                  className={classes.yesButton}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  onClick={handleClose}
                  className={classes.noButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ChangeEmailUsernameModal;

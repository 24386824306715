import React, { ReactElement, useState } from "react";
import { ThemeProvider, Button, TextField } from "@material-ui/core";
import theme from "../../../themes";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { signInHandler } from "./SignInHandler";
import Logo from "../../../images/Gold_Logo.png";
import { Link } from "react-router-dom";
import enumStrings, { RedirectUri } from "../../enumStrings";
import PasswordField from "../../common/PasswordField";

interface LoginInput {
  email: string;
  password: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  loginForm: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "250px",
    display: "block",
    margin: "auto",
    paddingTop: "9.2px",
  },
  heading: {},
  formFields: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    height: "600px",
    background: "white",
    padding: "29px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    margin: "auto",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "40px",
    paddingBottom: "9px",
  },
  textField: {
    backgroundColor: "white",
  },
  textFieldLabel: {
    fontSize: "18px",
  },
  formBody: {
    width: "90%",
    maxWidth: "585px",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  icon: {
    margin: "auto",
    marginTop: "30px",
  },
}));

const validationSchema = yup.object({
  email: yup.string().required("Email Username is required"),
  password: yup.string().required("Password is required"),
});
const Login = (): ReactElement => {
  const classes = useStyles();
  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const initialValues: LoginInput = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: LoginInput) => {
      setLoader(true);
      try {
        await signInHandler(input.email.trim().toLowerCase(), input.password);
        setButtonDisabled(true);
      } catch (error) {
        setInvalidError(true);
        if (error?.code === "NetworkError") {
          setCustomError("Please connect to the internet");
        } else if (error?.code === "NotAuthorizedException") {
          setCustomError("Invalid username or password");
        } else if (error?.code === "UserNotFoundException") {
          setCustomError("Invalid username or password");
        } else if (error?.code === "CapUserTryingToSignIn") {
          setCustomError("You are not permitted to access the dashboard");
        } else if (error?.code === "UserDisabled") {
          setCustomError("Invalid username or password");
        }
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <form className={classes.formFields} onSubmit={formik.handleSubmit}>
        <img
          src={Logo}
          width="180px"
          height="180px"
          className={classes.icon}
          alt="capIcon"
        />
        <h1 style={{ fontWeight: "bold", color: "#E1A731" }}>Dashboard</h1>
        <div>
          {invalidError ? (
            <div className={classes.invalidError}>{customError}</div>
          ) : null}
        </div>{" "}
        <TextField
          variant="outlined"
          InputProps={{
            className: classes.textField,
          }}
          InputLabelProps={{
            className: classes.textFieldLabel,
          }}
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <PasswordField
          id="password"
          fieldName="password"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
          touched={formik.touched.password}
          disablePopup
          hideText
          showVisibilityEyeball
        />
        <div className={classes.buttonContainer}>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            style={{ height: "70px" }}
            disabled={buttonDisabled || loader}
          >
            {loader ? <CircularProgress /> : "Log in"}
          </Button>
        </div>
        <Link
          style={{
            textAlign: "center",
            color: "#E1A731",
            textDecoration: "underline",
            fontWeight: "bold",
          }}
          to={RedirectUri.forgotPassword}
        >
          {enumStrings.loginText.forgotPasswordText}
        </Link>
      </form>
    </ThemeProvider>
  );
};

export default Login;

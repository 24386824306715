import React, { ReactElement, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  useGetAllCategoriesQuery,
  useGetAllChamberMembersQuery,
  useGetAllDiscountsFromChamberIdQuery,
  UserType,
} from "../../../graphql/types";
import Loader from "../../common/Loader/Loader";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import FullPageChamberMemberTable from "../../common/FullPageChamberMemberTable";
import ExportButton from "../../common/ExportButton";
import DownloadAllMemberLoginCredentialsModal from "./DownloadAllMemberLoginCredentialsModal";

const useStyles = makeStyles(() => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    backgroundColor: "#E1A731",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    marginTop: "33px",
    flexDirection: "column",
  },
}));

const ChamberAdminListAllChamberMembers = (): ReactElement => {
  const classes = useStyles();
  const chamberInfo = useGetChamberFromUserIdInAuth();

  const { data: chamberMemberData, loading: chamberMemberDataLoading } =
    useGetAllChamberMembersQuery({
      variables: {
        chamberId: chamberInfo?.id || "",
        getHasSignedUp: true,
        getNumberOfUsers: true,
      },
      skip: !chamberInfo?.id,
      fetchPolicy: "cache-and-network",
    });
  const { data: categoriesData } = useGetAllCategoriesQuery();

  let { data: discountData } = useGetAllDiscountsFromChamberIdQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id,
    fetchPolicy: "cache-and-network",
  });
  const [numOfMembersNotLoggedInYet, setNumOfMembersNotLoggedInYet] =
    useState(0);

  const [
    atLeastOneChamberMemberNotSignedUp,
    setAtLeastOneChamberMemberNotSignedUp,
  ] = useState(false);

  const listChamberMemberData = useMemo(() => {
    const memberIdToNumberOfPerksOffered: any = {};

    for (const discountForChamber of discountData?.getAllDiscountsFromChamberId ||
      []) {
      if (
        !(discountForChamber?.chamberMemberId in memberIdToNumberOfPerksOffered)
      ) {
        memberIdToNumberOfPerksOffered[discountForChamber?.chamberMemberId] = 0;
      }

      memberIdToNumberOfPerksOffered[discountForChamber?.chamberMemberId] += 1;
    }

    let chamberData = [];
    let totalCountNotLoggedInYet = 0;
    for (const chamberMember of chamberMemberData?.getAllChamberMembers || []) {
      if (!atLeastOneChamberMemberNotSignedUp && !chamberMember.hasSignedUp) {
        setAtLeastOneChamberMemberNotSignedUp(true);
      }

      if (!chamberMember.hasSignedUp) {
        totalCountNotLoggedInYet += 1;
      }

      const resultObj = {
        ...chamberMember,
        name: chamberMember.name.trim(),
        numberOfPerksOffered:
          memberIdToNumberOfPerksOffered[chamberMember.id] || 0,
      };

      chamberData.push(resultObj);
    }
    setNumOfMembersNotLoggedInYet(totalCountNotLoggedInYet);
    return chamberData;
  }, [
    atLeastOneChamberMemberNotSignedUp,
    chamberMemberData?.getAllChamberMembers,
    discountData?.getAllDiscountsFromChamberId,
  ]);

  const rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.ChamberAdmin][
      RedirectUri.manageAMemberChamberAdmin
    ];

  let csvDataLabel = [
    { label: "Member Name", key: "memberName" },
    {
      label: "Member Dashboard Contact Name (Not shown in app)",
      key: "memberDashboardContactName",
    },
    {
      label: "Member Dashboard Email Username (Not shown in app)",
      key: "memberDashboardContactEmail",
    },
    {
      label: "Company Contact Name (Shown in app)",
      key: "companyContactName",
    },
    {
      label: "Company Contact Email (Shown in app)",
      key: "companyContactEmail",
    },
    {
      label: "Membership Status",
      key: "membershipStatus",
    },
    { label: "Category 1", key: "category1" },
    { label: "Category 2", key: "category2" },
    { label: "Number of Perks Offered", key: "numberOfPerksOffered" },
    { label: "Has Logged In To Dashboard", key: "hasSignedUp" },
    { label: "Has Logo", key: "hasLogo" },
    {
      label: "Chamber Perks App Users Signed Up Under Member",
      key: "numberOfUsers",
    },
    { label: "Creation Time (GMT)", key: "creationTimeUtc" },
  ];

  const csvDataData = useMemo(() => {
    const memberIdToNumberOfPerksOffered: any = {};

    for (const discountForChamber of discountData?.getAllDiscountsFromChamberId ||
      []) {
      if (
        !(discountForChamber?.chamberMemberId in memberIdToNumberOfPerksOffered)
      ) {
        memberIdToNumberOfPerksOffered[discountForChamber?.chamberMemberId] = 0;
      }

      memberIdToNumberOfPerksOffered[discountForChamber?.chamberMemberId] += 1;
    }

    return chamberMemberData?.getAllChamberMembers
      ?.map((item) => ({
        memberName: item.name,
        memberDashboardContactEmail: item.adminConsoleLoginEmail || "",
        memberDashboardContactName: item.adminConsoleContactName || "",
        companyContactName: item.contactName || "",
        companyContactEmail: item.email || "",
        numberOfPerksOffered: memberIdToNumberOfPerksOffered[item.id] || 0,
        hasSignedUp: item.hasSignedUp ? "Yes" : "No",
        adminConsoleLoginEmail: item.adminConsoleLoginEmail,
        hasLogo: (item?.logo || "").length > 0 ? "Yes" : "No",
        membershipStatus: item?.isSuspended ? "Suspended" : "Active",
        numberOfUsers: item.numberOfUsers,
        creationTimeUtc: item.createdAtUtcMilli
          ? new Date(item.createdAtUtcMilli).toUTCString()
          : "",
        category1:
          categoriesData?.getAllCategories?.find(
            ({ id }) => id === item.categoryId[0]
          )?.name || "",
        category2: item.categoryId[1]
          ? categoriesData?.getAllCategories?.find(
              ({ id }) => id === item.categoryId[1]
            )?.name || ""
          : "",
      }))
      .sort((a, b) => {
        if (a.memberName < b.memberName) {
          return -1;
        } else if (a.memberName > b.memberName) {
          return 1;
        } else {
          return 0;
        }
      });
  }, [
    categoriesData?.getAllCategories,
    discountData?.getAllDiscountsFromChamberId,
    chamberMemberData?.getAllChamberMembers,
  ]);

  const [
    downloadAllMemberLoginCredentialsModalOpen,
    setDownloadAllMemberLoginCredentialsModalOpen,
  ] = useState(false);

  return (
    <div>
      {downloadAllMemberLoginCredentialsModalOpen ? (
        <DownloadAllMemberLoginCredentialsModal
          handleClose={() => {
            setDownloadAllMemberLoginCredentialsModalOpen(false);
          }}
          open={downloadAllMemberLoginCredentialsModalOpen}
          numOfMembersNotLoggedInYet={numOfMembersNotLoggedInYet}
        />
      ) : null}
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
          <div style={{ paddingTop: "12px" }} />
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div style={{ paddingRight: "10px", paddingBottom: "10px" }}>
                <ExportButton
                  disablePadding
                  loading={chamberMemberDataLoading}
                  csvData={csvDataData}
                  csvHeaders={csvDataLabel}
                  label="Export Members"
                />
              </div>
              {atLeastOneChamberMemberNotSignedUp ? (
                <div style={{ paddingBottom: "10px" }}>
                  <ExportButton
                    disablePadding
                    loading={chamberMemberDataLoading}
                    csvData={[]}
                    csvHeaders={[]}
                    customOnClick={() => {
                      setDownloadAllMemberLoginCredentialsModalOpen(true);
                    }}
                    label={
                      enumStrings.chamberAdminListAllChambersText
                        .downloadAllMemberCredentials.buttonLabel
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        {!chamberMemberData || chamberMemberDataLoading ? (
          <Loader />
        ) : (
          <>
            {listChamberMemberData && listChamberMemberData.length > 0 ? (
              <FullPageChamberMemberTable
                allChamberMemberData={listChamberMemberData}
                userType={UserType.ChamberAdmin}
              />
            ) : (
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                {enumStrings.chamberAdminListAllChambersText.noChambersText}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChamberAdminListAllChamberMembers;

import { useMemo } from "react";
import useGetChamberFromUserIdInAuth from "./useGetChamberFromUserIdInAuth";

const useIsChamber = () => {
  const chamberInfo = useGetChamberFromUserIdInAuth();

  const isChamber = useMemo(() => {
    // get isChamber from localstorage
    let isChamber = localStorage.getItem("isChamber");

    if (chamberInfo && chamberInfo.name) {
      if (chamberInfo.name.toLowerCase().includes("chamber")) {
        localStorage.setItem("isChamber", "true");
        isChamber = "true";
      } else {
        localStorage.setItem("isChamber", "false");
        isChamber = "false";
      }
    }

    // return isChamber from localstorage
    return isChamber === "true";
  }, [chamberInfo]);

  return isChamber;
};

export default useIsChamber;

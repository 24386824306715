import React, { useCallback, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import {
  PerksLinkRequestStatusEnum,
  useGetAllPerksLinksForChamberQuery,
  useRequestPerksLinkMutation,
  PerksLinkReturn,
  useDeletePerksLinkRequestMutation,
  PerksLinkDeletionReasonEnum,
  useAcceptPerksLinkRequestMutation,
  GetAllPerksLinksForChamberDocument,
} from "../../../graphql/types";
import {
  TextField,
  makeStyles,
  Button,
  Modal,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../../themes";
import Loader from "../../common/Loader/Loader";
import { toast, ToastContainer } from "react-toastify";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import { getLocalTimestamp } from "../Root/RootAdmin";

const useStyles = makeStyles(() => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "550px",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    marginTop: "10px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "290px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "450px",
      height: "41px",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "700px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
  notificationRadioGroup: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginBottom: "10px",
    alignItems: "center",
    paddingLeft: "100px",
  },
  radio: {
    color: "#E1A731!important",
  },
  chamberList: {
    height: "515px",
    "@media (max-height:1030px)": {
      height: "400px",
    },
    "@media (max-height:900px)": {
      height: "300px",
    },
    "@media (max-height:770px)": {
      height: "200px",
    },
    "@media (max-height:650px)": {
      height: "100px",
    },
  },
}));

export default function PerksLinkChamberList() {
  const classes = useStyles();
  const [fullList, setFullList] = useState<
    Pick<
      PerksLinkReturn & {
        linked: boolean;
        linkPendingAsRequestor: boolean;
        linkPendingAsRequestee: boolean;
        perksLinkRequestId: string;
        requestedAtUtcMilli: number;
        linkedAtTimeUtcMilli: number;
      },
      | "chamberId"
      | "chamberName"
      | "chamberContactEmail"
      | "linked"
      | "linkPendingAsRequestor"
      | "linkPendingAsRequestee"
      | "perksLinkRequestId"
      | "requestedAtUtcMilli"
      | "chamberContactNumber"
      | "linkedAtTimeUtcMilli"
    >[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);

  const [searchInput, setSearchInput] = useState("");
  const handleSearchChange = useCallback((e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  }, []);

  const userChamberInfo = useGetChamberFromUserIdInAuth();

  const { data: allPerksLinkChamberData } = useGetAllPerksLinksForChamberQuery({
    fetchPolicy: "network-only",
  });

  const updateListOfChambers = useCallback(() => {
    let baseChamberArr = [];
    for (const chamber of allPerksLinkChamberData?.getAllPerksLinksForChamber ||
      []) {
      baseChamberArr.push({
        chamberId: chamber.chamberId,
        chamberName: chamber.chamberName,
        linked:
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.status ===
          PerksLinkRequestStatusEnum.Accepted,
        chamberContactEmail: chamber.chamberContactEmail,
        linkPendingAsRequestor:
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.status ===
            PerksLinkRequestStatusEnum.Pending &&
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.requestorChamberId ===
            userChamberInfo?.id,
        linkPendingAsRequestee:
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.status ===
            PerksLinkRequestStatusEnum.Pending &&
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.requesteeChamberId ===
            userChamberInfo?.id,
        perksLinkRequestId:
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.id || "",
        requestedAtUtcMilli:
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.createdAtUtcMilli || 0,
        chamberContactNumber: chamber.chamberContactNumber,
        linkedAtTimeUtcMilli:
          chamber.pendingOrAcceptedPerksLinkRequestInfo?.linkedAtTimeUtcMilli ||
          0,
      });
    }

    const linkedChambers = baseChamberArr
      .filter((chamber) => chamber.linked)
      .sort((a, b) => a.chamberName.localeCompare(b.chamberName));

    const linkPendingAsRequestorChambers = baseChamberArr
      .filter((chamber) => chamber.linkPendingAsRequestor)
      .sort((a, b) => a.chamberName.localeCompare(b.chamberName));

    const linkPendingAsRequesteeChambers = baseChamberArr
      .filter((chamber) => chamber.linkPendingAsRequestee)
      .sort((a, b) => a.chamberName.localeCompare(b.chamberName));

    const nonLinkedChambers = baseChamberArr
      .filter(
        (chamber) =>
          !chamber.linked &&
          !chamber.linkPendingAsRequestor &&
          !chamber.linkPendingAsRequestee
      )
      .sort((a, b) => a.chamberName.localeCompare(b.chamberName));

    const allChamberSelectableArray = [
      ...linkedChambers,
      ...linkPendingAsRequestorChambers,
      ...linkPendingAsRequesteeChambers,
      ...nonLinkedChambers,
    ];

    setFullList(allChamberSelectableArray || []);
  }, [
    allPerksLinkChamberData?.getAllPerksLinksForChamber,
    userChamberInfo?.id,
  ]);

  useEffect(() => {
    if (
      isLoading &&
      allPerksLinkChamberData?.getAllPerksLinksForChamber &&
      userChamberInfo
    ) {
      updateListOfChambers();
      setIsLoading(false);
    }
  }, [
    isLoading,
    allPerksLinkChamberData?.getAllPerksLinksForChamber,
    userChamberInfo,
    updateListOfChambers,
  ]);

  useEffect(() => {
    updateListOfChambers();
  }, [updateListOfChambers]);

  const searchedItems = useMemo(() => {
    return fullList.filter((chamber) => {
      return chamber.chamberName.toLowerCase().match(searchInput.toLowerCase());
    });
  }, [fullList, searchInput]);

  const [linkConfirmModalOpen, setLinkConfirmModalOpen] = useState(false);
  const [requestedLinkChamberName, setRequestedLinkChamberName] = useState("");
  const [requestedLinkChamberEmail] = useState("");
  const [requestedLinkChamberId, setRequestedLinkChamberId] = useState("");

  const [requestLinkMutation] = useRequestPerksLinkMutation();

  const [sendLoading, setSendLoading] = useState(false);

  const requestLink = useCallback(
    async ({
      requestedLinkChamberIdManual,
    }: {
      requestedLinkChamberIdManual: string;
    }) => {
      setSendLoading(true);

      await requestLinkMutation({
        variables: {
          requestingLinkToChamberId: requestedLinkChamberIdManual,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GetAllPerksLinksForChamberDocument },
          "getAllPerksLinksForChamber",
        ],
      });

      toast.success("Request sent successfully to support team.");
      setSendLoading(false);
      setLinkConfirmModalOpen(false);
    },
    [requestLinkMutation]
  );

  const [cancelLinkRequestModalOpen, setCancelLinkRequestModalOpen] =
    useState(false);
  const [denyLinkRequestModalOpen, setDenyLinkRequestModalOpen] =
    useState(false);
  const [unlinkRequestModalOpen, setUnlinkRequestModalOpen] = useState(false);

  const [deleteRequestLoading, setDeleteRequestLoading] = useState(false);
  const [perksLinkRequestId, setPerksLinkRequestId] = useState("");

  const [deletePerksLinkRequestMutation] = useDeletePerksLinkRequestMutation();

  const cancelRequest = useCallback(
    async ({
      deletionReason,
    }: {
      deletionReason: PerksLinkDeletionReasonEnum;
    }) => {
      setDeleteRequestLoading(true);

      await deletePerksLinkRequestMutation({
        variables: {
          requestId: perksLinkRequestId,
          deletionReason,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: GetAllPerksLinksForChamberDocument },
          "getAllPerksLinksForChamber",
        ],
      });

      toast.success(
        `${
          deletionReason === PerksLinkDeletionReasonEnum.Cancelled
            ? "Request cancelled"
            : deletionReason === PerksLinkDeletionReasonEnum.Denied
            ? "Request denied"
            : "Unlinked"
        } successfully.`
      );
      setDeleteRequestLoading(false);
      setCancelLinkRequestModalOpen(false);
      setDenyLinkRequestModalOpen(false);
      setUnlinkRequestModalOpen(false);
    },
    [deletePerksLinkRequestMutation, perksLinkRequestId]
  );

  const [acceptRequestModalOpen, setAcceptRequestModalOpen] = useState(false);
  const [acceptRequestLoading, setAcceptRequestLoading] = useState(false);
  const [acceptPerksLinkRequestMutation] = useAcceptPerksLinkRequestMutation();

  const acceptRequest = useCallback(async () => {
    setAcceptRequestLoading(true);

    await acceptPerksLinkRequestMutation({
      variables: {
        requestId: perksLinkRequestId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: GetAllPerksLinksForChamberDocument },
        "getAllPerksLinksForChamber",
      ],
    });

    toast.success(`Request accepted successfully.`);
    setAcceptRequestLoading(false);
    setAcceptRequestModalOpen(false);
  }, [acceptPerksLinkRequestMutation, perksLinkRequestId]);

  const customList = useMemo(() => {
    return (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          title={
            <div className={classes.searchContainer}>
              <TextField
                style={{
                  borderBottom: "none",
                  border: "1px solid #9E9E9E",
                }}
                type="text"
                placeholder="Search by Chamber or Board Name"
                onChange={handleSearchChange}
                value={searchInput}
                className={classes.searchBar}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
                }}
              />
            </div>
          }
        />
        <Divider />
        <List
          sx={{
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          className={classes.chamberList}
          dense
          component="div"
          role="list"
        >
          {searchedItems.length === 0 && (
            <ListItem
              role="listitem"
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                cursor: "unset",
              }}
            >
              <ListItemText primary="No Chambers or Boards found" />
            </ListItem>
          )}
          {searchedItems.map((value) => {
            const labelId = `transfer-list-all-item-${value.chamberId}-label`;

            return (
              <ListItem
                key={value.chamberId}
                role="listitem"
                divider
                button
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  cursor: "unset",
                }}
              >
                <ListItemText id={labelId} primary={value.chamberName} />
                <ListItemIcon>
                  {value.linked ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "160px",
                        }}
                      >
                        <Chip
                          label="Linked"
                          variant="outlined"
                          size="medium"
                          style={{
                            color: "#34694F",
                            backgroundColor: "#DCEEDC",
                            borderColor: "#34694F",
                            marginRight: "5px",
                          }}
                        />
                        <HoverToolTip
                          disablePadding
                          showIt
                          description={`${userChamberInfo?.name} and ${
                            value.chamberName
                          } linked perks on ${getLocalTimestamp(
                            value.linkedAtTimeUtcMilli,
                            true
                          )}.`}
                        />
                      </div>
                      <div style={{ height: "10px" }} />
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#37474f",
                          color: "#e9e9e9",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "160px",
                          height: "30px",
                        }}
                        disabled={sendLoading}
                        onClick={() => {
                          setUnlinkRequestModalOpen(() => {
                            setRequestedLinkChamberName(value.chamberName);
                            setPerksLinkRequestId(value.perksLinkRequestId);
                            return true;
                          });
                        }}
                      >
                        Unlink
                      </Button>
                    </div>
                  ) : value.linkPendingAsRequestor ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "160px",
                        }}
                      >
                        <Chip
                          label="Link Request Sent"
                          variant="outlined"
                          size="medium"
                          style={{
                            color: "#727272",
                            backgroundColor: "#E3E4E6",
                            borderColor: "#727272",
                            marginRight: "5px",
                          }}
                        />
                        <HoverToolTip
                          disablePadding
                          showIt
                          description={`Your PerksLink™ request was sent to ${
                            value.chamberName
                          } at ${
                            value.chamberContactEmail
                          } on ${getLocalTimestamp(
                            value.requestedAtUtcMilli,
                            true
                          )}.\n\nIn case your request has not been accepted after some time, it is recommended that you contact ${
                            value.chamberName
                          } at ${value.chamberContactEmail} or ${
                            value.chamberContactNumber
                          } and inquire about this request.`}
                        />
                      </div>
                      <div style={{ height: "10px" }} />
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#37474f",
                          color: "#e9e9e9",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "160px",
                          height: "30px",
                        }}
                        onClick={() => {
                          setCancelLinkRequestModalOpen(() => {
                            setRequestedLinkChamberName(value.chamberName);
                            setPerksLinkRequestId(value.perksLinkRequestId);
                            return true;
                          });
                        }}
                      >
                        Cancel Request
                      </Button>
                    </div>
                  ) : value.linkPendingAsRequestee ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "160px",
                        }}
                      >
                        <Chip
                          label="Link Requested"
                          variant="outlined"
                          size="medium"
                          style={{
                            color: "#727272",
                            backgroundColor: "#E3E4E6",
                            borderColor: "#727272",
                            marginRight: "5px",
                          }}
                        />
                        <HoverToolTip
                          disablePadding
                          showIt
                          description={`${
                            value.chamberName
                          } requested to enable PerksLink™ with ${
                            userChamberInfo?.name
                          } on ${getLocalTimestamp(
                            value.requestedAtUtcMilli,
                            true
                          )}.\n\nBy accepting this request, any Chamber Perks App™ users viewing perks under ${
                            value.chamberName
                          } will also see perks offered under ${
                            userChamberInfo?.name
                          } and vice versa.`}
                        />
                      </div>
                      <div style={{ height: "10px" }} />
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#E1A731",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "160px",
                          height: "30px",
                        }}
                        onClick={() => {
                          setAcceptRequestModalOpen(() => {
                            setRequestedLinkChamberId(value.chamberId);
                            setRequestedLinkChamberName(value.chamberName);
                            setPerksLinkRequestId(value.perksLinkRequestId);
                            return true;
                          });
                        }}
                      >
                        Accept Request
                      </Button>
                      <div style={{ height: "5px" }} />
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#37474f",
                          color: "#e9e9e9",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          width: "160px",
                          height: "30px",
                        }}
                        onClick={() => {
                          setDenyLinkRequestModalOpen(() => {
                            setRequestedLinkChamberName(value.chamberName);
                            setPerksLinkRequestId(value.perksLinkRequestId);
                            return true;
                          });
                        }}
                      >
                        Deny Request
                      </Button>
                    </div>
                  ) : (
                    <Button
                      type="submit"
                      size="large"
                      color="primary"
                      variant="contained"
                      style={{
                        backgroundColor: "#E1A731",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "10px",
                        width: "160px",
                        height: "30px",
                      }}
                      disabled={sendLoading}
                      onClick={() => {
                        setRequestedLinkChamberId(value.chamberId);
                        requestLink({
                          requestedLinkChamberIdManual: value.chamberId,
                        });

                        // setLinkConfirmModalOpen(() => {
                        // setRequestedLinkChamberId(value.chamberId);
                        //   setRequestedLinkChamberName(value.chamberName);
                        //   setRequestedLinkChamberEmail(
                        //     value.chamberContactEmail
                        //   );
                        //   return true;
                        // });
                      }}
                    >
                      {requestedLinkChamberId === value.chamberId &&
                      sendLoading ? (
                        <CircularProgress
                          style={{ color: "white" }}
                          size={28}
                        />
                      ) : (
                        "Request Link"
                      )}
                    </Button>
                  )}
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </Card>
    );
  }, [
    classes.chamberList,
    classes.searchBar,
    classes.searchContainer,
    handleSearchChange,
    requestLink,
    requestedLinkChamberId,
    searchInput,
    searchedItems,
    sendLoading,
    userChamberInfo?.name,
  ]);

  if (!allPerksLinkChamberData?.getAllPerksLinksForChamber || isLoading) {
    return (
      <div style={{ marginTop: "46px" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      {linkConfirmModalOpen && (
        <Modal className={classes.modal} open={linkConfirmModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "610px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>PerksLink™ Request</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              You are requesting to link perks with{" "}
              <span style={{ fontWeight: "bold" }}>
                {requestedLinkChamberName}
              </span>
              .<br />
              <br />
              By linking perks, Chamber Perks App™ users will be able to see
              perks from your chamber and {requestedLinkChamberName} when they
              look for perks under your chamber or under{" "}
              {requestedLinkChamberName}.
              <br />
              <br />
              If you are sure you want to link perks, Click Send Request to send
              your request to {requestedLinkChamberName} at{" "}
              {requestedLinkChamberEmail} including instructions for how to
              accept your request. If you are not sure, click Close to cancel
              the request.
              {userChamberInfo?.isSandbox && (
                <>
                  <br />
                  <br />
                  <span
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>Note:</span> Since this
                    is a sandbox chamber, a request will not be emailed to{" "}
                    {requestedLinkChamberName} and instead a sample of the
                    request email will be sent to CPA team to review, and the
                    link will automatically be enabled.
                  </span>
                </>
              )}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                // onClick={requestLink}
                variant="contained"
                disabled={sendLoading}
              >
                {sendLoading ? (
                  <CircularProgress style={{ color: "white" }} size={28} />
                ) : (
                  "Send Request"
                )}
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setLinkConfirmModalOpen(false)}
                variant="contained"
                disabled={sendLoading}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {cancelLinkRequestModalOpen && (
        <Modal className={classes.modal} open={cancelLinkRequestModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "430px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Cancel PerksLink™ Request</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              If you are sure you do not want to enable PerksLink™ with{" "}
              {requestedLinkChamberName}, Click Cancel Request. If you are not
              sure, click Close.
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  cancelRequest({
                    deletionReason: PerksLinkDeletionReasonEnum.Cancelled,
                  });
                }}
                variant="contained"
                disabled={deleteRequestLoading}
              >
                {deleteRequestLoading ? (
                  <CircularProgress style={{ color: "white" }} size={28} />
                ) : (
                  "Cancel Request"
                )}
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setCancelLinkRequestModalOpen(false)}
                variant="contained"
                disabled={deleteRequestLoading}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {denyLinkRequestModalOpen && (
        <Modal className={classes.modal} open={denyLinkRequestModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "430px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Deny PerksLink™ Request</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              If you are sure you do not want to enable PerksLink™ with{" "}
              {requestedLinkChamberName}, Click Deny Request. If you are not
              sure, click Close.
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  cancelRequest({
                    deletionReason: PerksLinkDeletionReasonEnum.Denied,
                  });
                }}
                variant="contained"
                disabled={deleteRequestLoading}
              >
                {deleteRequestLoading ? (
                  <CircularProgress style={{ color: "white" }} size={28} />
                ) : (
                  "Deny Request"
                )}
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setDenyLinkRequestModalOpen(false)}
                variant="contained"
                disabled={deleteRequestLoading}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {unlinkRequestModalOpen && (
        <Modal className={classes.modal} open={unlinkRequestModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "430px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Disable PerksLink™</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              Unlinking your perks from {requestedLinkChamberName} will prevent
              any perks under your chamber from being shown under{" "}
              {requestedLinkChamberName} and vice versa on the Chamber Perks
              App™.
              <br />
              <br />
              If you are sure you want to unlink your perks from{" "}
              {requestedLinkChamberName}, Click Unlink. If you are not sure,
              click Close.
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={() => {
                  cancelRequest({
                    deletionReason: PerksLinkDeletionReasonEnum.Unlinked,
                  });
                }}
                variant="contained"
                disabled={deleteRequestLoading}
              >
                {deleteRequestLoading ? (
                  <CircularProgress style={{ color: "white" }} size={28} />
                ) : (
                  "Unlink"
                )}
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setUnlinkRequestModalOpen(false)}
                variant="contained"
                disabled={deleteRequestLoading}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {acceptRequestModalOpen && (
        <Modal className={classes.modal} open={acceptRequestModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between", height: "430px" }}
          >
            <h1 style={{ fontWeight: "bold" }}>Accept PerksLink™ Request</h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "17px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              By linking perks, Chamber Perks App™ users will be able to see
              perks from your chamber and {requestedLinkChamberName} when they
              look for perks under your chamber or under{" "}
              {requestedLinkChamberName}.
              <br />
              <br />
              If you are sure you want to enable PerksLink™ with{" "}
              {requestedLinkChamberName}, Click Accept Request. If you are not
              sure, click Close.
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                onClick={acceptRequest}
                variant="contained"
                disabled={acceptRequestLoading}
              >
                {acceptRequestLoading ? (
                  <CircularProgress style={{ color: "white" }} size={28} />
                ) : (
                  "Accept Request"
                )}
              </Button>
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setAcceptRequestModalOpen(false)}
                variant="contained"
                disabled={acceptRequestLoading}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <div style={{ marginLeft: 30, marginRight: 30, maxWidth: 850 }}>
        <h3>
          How to enable PerksLink™ between {userChamberInfo?.name} and another
          Chamber or Board participating in the Chamber Perks App™:
          <div style={{ height: 5 }} />
          <ol>
            <li>
              Contact the chamber or board you would like to link your perks
              with. Once they agree, each chamber or board will need to search
              for the chamber or board below and click Request Link.
            </li>
            <li>
              The PerksLink™ will be enabled once the support team receives a
              link request from both Chambers or Boards.
            </li>
            <li>
              All perks will then be accessible by Chamber Perks App™ users on
              the linked Chambers or Boards.
            </li>
          </ol>
        </h3>
      </div>

      <div
        className={classes.companyInputWithToolTipContainer}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid
            container
            style={{
              marginTop: "5px",
            }}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              style={{
                margin: 0,
              }}
            >
              {customList}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

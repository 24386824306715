import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
}

const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onClose,
}: ConfirmationDialogProps): React.ReactElement => {
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = async () => {
    setIsConfirming(true);
    await onConfirm();
    setIsConfirming(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={isConfirming ? undefined : onClose}
      PaperProps={{
        style: {
          backgroundColor: "#37474f",
          color: "#ffffff",
          borderRadius: "8px",
          padding: "8px",
        },
      }}
    >
      <DialogTitle style={{ color: "#ffffff" }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: "#e0e0e0" }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          style={{ color: "#e0e0e0" }}
          disabled={isConfirming}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          style={{
            backgroundColor: "#e1a731",
            color: "#ffffff",
            minWidth: "100px",
            position: "relative",
            minHeight: "37px",
          }}
          disabled={isConfirming}
          autoFocus
        >
          {isConfirming ? (
            <CircularProgress
              size={24}
              style={{
                color: "#ffffff",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          ) : (
            "Confirm"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

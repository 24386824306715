import React from "react";
import { Switch, styled } from "@material-ui/core";
import HoverToolTip from "./HoverToolTip";
import ConfirmationDialog from "../ConfirmationDialog";

const LABEL_TEXT = "Auto Approve Banner Content Updates";
const TOOLTIP_TEXT =
  "When this is enabled, any updates made to banner content by this member will be auto approved and go live on the Chamber Perks App™ immediately. If you want to review the updates before they go live, keep this disabled.";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));

interface AutoApproveBannerContentToggleProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  showConfirmationDialog?: boolean;
}

const AutoApproveBannerContentToggle = ({
  checked,
  onChange,
  disabled = false,
  showConfirmationDialog = false,
}: AutoApproveBannerContentToggleProps) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [pendingCheckedState, setPendingCheckedState] = React.useState<
    boolean | null
  >(null);

  const handleSwitchChange = (newChecked: boolean) => {
    if (showConfirmationDialog) {
      setIsDialogOpen(true);
      setPendingCheckedState(newChecked);
    } else {
      onChange(newChecked);
    }
  };

  const handleConfirm = async () => {
    if (pendingCheckedState !== null) {
      await onChange(pendingCheckedState);
    }

    setIsDialogOpen(false);
    setPendingCheckedState(null);
  };

  const handleCancel = () => {
    setIsDialogOpen(false);
    setPendingCheckedState(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2
            style={{ marginBottom: "0px", marginTop: "0px", fontSize: "24px" }}
          >
            {LABEL_TEXT}
          </h2>
          <CustomSwitch
            checked={checked}
            onChange={(event, checked) => handleSwitchChange(checked)}
            disabled={disabled}
          />
          <div style={{ marginTop: "0px" }}>
            <HoverToolTip description={TOOLTIP_TEXT} showIt disablePadding />
          </div>
        </div>
      </div>

      <ConfirmationDialog
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        title={`${!checked ? "Enable" : "Disable"} Auto Approve`}
        message={`Are you sure you want to ${
          !checked ? "enable" : "disable"
        } auto approval for banner content updates?`}
      />
    </>
  );
};

export default AutoApproveBannerContentToggle;

import { FormControl, makeStyles } from "@material-ui/core";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useCallback } from "react";
import dayjs from "dayjs";

const useStyles = makeStyles((theme: any) => ({
  chamberInput: {
    marginTop: "50px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    borderRadius: "15px",
  },
  selectedValue: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
}));

const ChamberSubscriptionDatePicker = ({
  formikSetFieldValue,
}: {
  formikSetFieldValue: (a: string, b: string) => void;
}) => {
  const classes = useStyles();

  const handleDateChange = useCallback(
    (val) => {
      formikSetFieldValue("subscriptionDate", dayjs(val).format("ll"));
    },
    [formikSetFieldValue]
  );

  return (
    <FormControl className={classes.chamberInput}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          label="Subscription Start Date"
          className={classes.selectedValue}
          format="ll"
          onChange={handleDateChange}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default ChamberSubscriptionDatePicker;

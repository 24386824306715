import React, { ReactElement, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings from "../../enumStrings";
import {
  useGetAllChamberMembersQuery,
  useGetAllRewardCampaignsForChamberQuery,
} from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import { Typography } from "antd";
import FullPageRewardCampaignsTable from "./FullPageRewardCampaignsTable";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";

export type RewardPageType = "createNew" | "seePrevious";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    marginTop: "33px",
    flexDirection: "column",
  },
  buttonGroupRoot: {
    paddingBottom: "0px",
    "& .MuiToggleButton-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "white",
      color: "#737373",
      fontWeight: "bold",
      fontSize: "17px",
      "&:hover": {
        boxShadow: "3px 2px 5px 0px rgba(0,0,0,0.1)",
        backgroundColor: "white",
      },
      "&.Mui-selected": {
        backgroundColor: "#E0A731",
        color: "white",
        "&:hover": {
          boxShadow: "0px 2px 5px 3px rgba(0,0,0,0.1)",
          backgroundColor: "#E0A731",
        },
      },
    },
  },
}));

const ListAllChamberRewardCampaigns = ({
  mode,
}: {
  mode: "active" | "inactive";
}): ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const bingo = useMemo(() => {
    return (location.state || { showToast: undefined }) as {
      showToast: boolean | undefined;
      toastText?: string;
    };
  }, [location.state]);
  const history = useHistory();

  useEffect(() => {
    if (bingo.showToast) {
      toast.success(
        bingo.toastText ? bingo.toastText : enumStrings.rewardCampaign.toastText
      );
      history.replace({ ...history.location, state: undefined });
    }
  }, [bingo.showToast, bingo.toastText, history]);

  const { data: allRewardCampaignsForChamber } =
    useGetAllRewardCampaignsForChamberQuery({
      fetchPolicy: "network-only",
    });

  const chamberInfo = useGetChamberFromUserIdInAuth();
  const { data: allChamberMemberData } = useGetAllChamberMembersQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip: !chamberInfo?.id,
    fetchPolicy: "cache-and-network",
  });

  const headerInfo = useMemo(() => {
    if (mode === "active") {
      return {
        title: enumStrings.rewardCampaign.activeTitle,
        description: enumStrings.rewardCampaign.activeDescription,
      };
    }

    return {
      title: enumStrings.rewardCampaign.inactiveTitle,
      description: enumStrings.rewardCampaign.inactiveDescription,
    };
  }, [mode]);

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{headerInfo.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {headerInfo.description}
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {!allRewardCampaignsForChamber ||
        !allRewardCampaignsForChamber ||
        !allChamberMemberData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <br />
            <br />

            {(
              allRewardCampaignsForChamber?.getAllRewardCampaignsForChamber ||
              []
            ).length > 0 ? (
              (allChamberMemberData?.getAllChamberMembers || []).length > 0 && (
                <FullPageRewardCampaignsTable
                  allChamberMemberData={allChamberMemberData}
                  allRewardCampaignData={
                    allRewardCampaignsForChamber?.getAllRewardCampaignsForChamber
                  }
                  mode={mode}
                />
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  {mode === "active"
                    ? "No active reward campaign found"
                    : "No previous reward campaigns found"}
                </Typography>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ListAllChamberRewardCampaigns;

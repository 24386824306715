import React, { ReactElement, useCallback, useState } from "react";
import {
  Button,
  makeStyles,
  TextField,
  FormHelperText,
  InputLabel,
} from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  ChamberMemberInput,
  useCreateChamberMembersMutation,
  UserType,
} from "../../../graphql/types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import useRedirect from "../../common/Hooks/useRedirect";
import CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal from "../../common/ConfirmationModal/CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal";
import Categories from "../../common/utilities/Categories";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import { CSVLink } from "react-csv";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import _without from "lodash/without";
import PhoneNumberFormat from "../../common/utilities/PhoneNumberFormat";
import LogoPicker from "../../common/utilities/LogoPicker";
import ProvincePicker from "../../common/utilities/ProvincePicker";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import { FormControl } from "@material-ui/core";
import MultiContactForm, {
  getInitialStateOfCustomContacts,
} from "../../common/utilities/MultiContactForm";
import SendLoginInformationModal from "../../common/SendLoginInformationModal";
import ChamberMemberIsSuspendedRadioGroup from "../../common/ChamberMemberIsSuspendedRadioGroup";

const CHARACTER_LIMIT_DESCRIPTION = 2000;
const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  companyContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "75px",
      height: "75px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100px",
      height: "100px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "65px",
      height: "65px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90px",
      height: "90px",
    },
  },
  companyTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "36px",
  },
  companyInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },

  content: {
    background: "#FFFFFF",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    marginTop: "33px",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  companyInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  switch: {
    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: "#e1a731",
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#e1a731",
    },
  },
  selectedValue: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "14px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "15px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  switchContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "30px",
  },
  switchText: {
    margin: "10px",
  },
  companyDescription: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    border: "1px solid black",
    marginTop: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "105px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "105px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0000008a",
      },
    },
    "& label.Mui-focused": {
      color: "#0000008a",
    },
    "& select": {},
    "& label.Mui": {
      color: "black",
      paddingLeft: "10px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
  },
  companyDescriptionInput: {
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
    "& .MuiSelect-icon": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 7px 7px 0px",
      width: "55px",
    },
    "& .MuiSelect-iconOpen": {
      height: "53px",
      backgroundColor: "#E1A731",
      top: "-16px",
      borderRadius: "0px 0px 0px 0px",
      width: "55px",
    },
    "& .MuiPaper-root": {
      minWidth: "430px!important",
      top: "269px!important",
      left: "256px!important",
    },
    "& .MuiInputBase-inputMultiline": {
      overflow: "scroll !important",
      height: "79px!important",
    },
  },
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "100%",
    borderRadius: "5px",
    textAlign: "center",
    maxHeight: "800px",
    width: "740px",
    maxWidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(6),
    position: "relative",
  },
  yesButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "white",
    fontWeight: "bold",
    height: "40px",
  },
}));
// export const ShowInAppSwitch = styled((props: SwitchProps) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   margin: "10px",
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     padding: 0,
//     margin: 2,

//     transitionDuration: "300ms",
//     "&.Mui-checked": {
//       transform: "translateX(16px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         border: 0,
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         opacity: 0.5,
//       },
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#33cf4d",
//       border: "6px solid #fff",
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: 22,
//     height: 22,
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 26 / 2,
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//   },
//   "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
//     backgroundColor: "#e1a731",
//     border: "1px solid black",
//   },
//   "& .MuiSwitch-root": {
//     margin: "10px",
//   },
// }));

const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const validationSchema = yup.object({
  name: yup.string().required("Company Name is required"),
  phoneNumber: yup.string(),
  email: yup.string().email("Not a valid email"),
  adminConsoleLoginEmail: yup
    .string()
    .email("Not a valid email")
    .required("Member Dashboard email username is required"),
  adminConsoleContactName: yup
    .string()
    .required("Member Dashboard contact name is required"),
  address: yup.object({
    province: yup.string(),
    city: yup.string(),
    postalCode: yup.string(),
    streetAddress: yup.string(),
  }),
  categoryId: yup.array().min(1, "Category is required"),
});

const CreateChamberMember = (): ReactElement => {
  const classes = useStyles();

  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [invalidError, setInvalidError] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [name, setname] = useState("");
  const [createButtonText, setCreateButtonText] = useState("Create");
  const [open, setOpen] = useState(false);
  const [createChamberMember] = useCreateChamberMembersMutation();
  const chamberInfo = useGetChamberFromUserIdInAuth();
  const [categoryDisable, setCategoryDisable] = useState(false);

  const initialValues: ChamberMemberInput = {
    id: "",
    chamberId: "",
    categoryId: [],
    name: "",
    description: "",
    website: "",
    facebook: "",
    instagram: "",
    threads: "",
    linkedin: "",
    twitter: "",
    tiktok: "",
    youtube: "",
    address: {
      city: "",
      postalCode: "",
      province: "",
      streetAddress: "",
    },
    phoneNumber: "",
    contactName: "",
    email: "",
    logo: "",
    showInApp: true,
    adminConsoleLoginEmail: "",
    adminConsoleContactName: "",
    customContacts: getInitialStateOfCustomContacts(null),
    isSuspended: false,
  };

  const redirectToManageChamber = useRedirect(
    RedirectUri.manageAMemberChamberAdmin
  );

  const handleClose = useCallback(async (): Promise<void> => {
    setOpen(false);
    redirectToManageChamber();
  }, [redirectToManageChamber]);

  const [
    suggestedAlternativeEmailUsernameModalOpen,
    setSuggestedAlternativeEmailUsernameModalOpen,
  ] = useState(false);

  const closeSuggestedAlternativeEmailUsernameModal = useCallback(() => {
    setSuggestedAlternativeEmailUsernameModalOpen(false);
  }, []);

  const openSuggestedAlternativeEmailUsernameModal = useCallback(() => {
    setSuggestedAlternativeEmailUsernameModalOpen(true);
  }, []);

  const [suggestedPlusAddressedEmail, setSuggestedPlusAddressedEmail] =
    useState("");

  const [newChamberMemberId, setNewChamberMemberId] = useState("");

  const id = uuidv4();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: ChamberMemberInput) => {
      setInvalidError(false);
      setCustomError(``);

      if (!chamberInfo || !chamberInfo.id) {
        setInvalidError(true);
        setCustomError(
          `Unable to create member. Please try again. If this continues, please refresh.`
        );
        return;
      }

      setLoader(true);
      try {
        setButtonDisabled(true);
        const chamberMemberUser = await createChamberMember({
          variables: {
            input: {
              data: [
                {
                  address: {
                    city: input.address.city || "",
                    postalCode: input.address.postalCode || "",
                    province: input.address.province || "",
                    streetAddress: input.address.streetAddress || "",
                  },
                  categoryId: input.categoryId,
                  chamberId: chamberInfo.id,
                  description: (input.description || "").trim(),
                  contactName: (input.contactName || "").trim(),
                  email: (input.email || "").trim().toLowerCase(),
                  phoneNumber: input.phoneNumber,
                  showInApp: input.showInApp,
                  name: input.name.trim(),
                  website: input.website,
                  facebook: input.facebook,
                  instagram: input.instagram,
                  threads: input.threads,
                  linkedin: input.linkedin,
                  twitter: input.twitter,
                  tiktok: input.tiktok,
                  youtube: input.youtube,
                  logo: input.logo,
                  id: id,
                  customContacts: input.customContacts,
                  // adding ignore here cuz adminConsoleLoginEmail will ALWAYS be given on creation
                  // @ts-ignore
                  adminConsoleLoginEmail: input.adminConsoleLoginEmail
                    .trim()
                    .toLowerCase(),
                  adminConsoleContactName: (
                    input.adminConsoleContactName || ""
                  ).trim(),
                  isSuspended: input.isSuspended,
                },
              ],
            },
          },
        });
        const creationResult =
          chamberMemberUser.data?.createChamberMembers.results[0];
        setNewChamberMemberId(id);
        if (creationResult?.chamberMemberAddedSuccessfully === false) {
          setInvalidError(true);
          setCustomError(
            `Unable to create member. ${
              (creationResult.failureReason || "").includes(
                "UsernameExistsException"
              )
                ? "An account with the given email already exists."
                : creationResult.failureReason || ""
            }`
          );
          setCreateButtonText("Create");
          setLoader(false);
          setOpen(false);
          setButtonDisabled(false);

          if (
            creationResult.failureReason?.includes("UsernameExistsException")
          ) {
            setSuggestedPlusAddressedEmail(
              (creationResult.failureReason || "").trim().split(" ")[1]
            );
            openSuggestedAlternativeEmailUsernameModal();
          }

          return;
        }
        const passwordVal = creationResult?.password as string;
        const usernameVal = creationResult?.email as string;
        const nameVal = input.name;

        setPassword(passwordVal);
        setUsername(usernameVal);
        setname(nameVal);

        setOpen(true);
        setLoader(false);
        setCreateButtonText("Created");

        console.log(formik.errors);
      } catch (error) {
        console.log(formik.errors);
        setOpen(false);
        setInvalidError(true);
        setLoader(false);
        setButtonDisabled(false);
        setCreateButtonText("Error");
      }
    },
  });

  const handleUseSuggestedPlusAddressedAlternative = useCallback(() => {
    formik.setFieldValue(
      "adminConsoleLoginEmail",
      suggestedPlusAddressedEmail.trim().toLowerCase()
    );
    formik.submitForm();
    closeSuggestedAlternativeEmailUsernameModal();
  }, [
    closeSuggestedAlternativeEmailUsernameModal,
    formik,
    suggestedPlusAddressedEmail,
  ]);

  const handleCategoriesChange = (event: { target: { value: any } }) => {
    const value = event.target.value;
    formik.setFieldValue("categoryId", value);
    if (value.length >= 2) {
      setCategoryDisable(true);
    } else {
      setCategoryDisable(false);
    }
  };

  const handleDelete = (value: string) => {
    const newArray = _without(formik.values.categoryId, value);
    formik.setFieldValue("categoryId", newArray);
    if (newArray.length < 2) {
      setCategoryDisable(false);
    }
  };

  // const handleToggleChange = useCallback(
  //   (e) => {
  //     console.log(e.target.checked);
  //     formik.setFieldValue("showInApp", e.target.checked);
  //   },
  //   [formik]
  // );

  const handleCreateBase64 = useCallback(
    async (e) => {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);
      formik.setFieldValue("logo", base64);
    },
    [formik]
  );
  const deleteLogo = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    formik.setFieldValue("logo", undefined);
  };
  const csvData = [
    ["Member Dashboard Email Username", "Member Dashboard Temporary Password"],
    [username, password],
  ];

  // @ts-ignore
  const rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.ChamberAdmin][
      RedirectUri.createAMemberChamberAdmin
    ];
  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </div>
      </div>
      <div className={classes.content}>
        <div>
          <form
            className={classes.formContainer}
            onSubmit={formik.handleSubmit}
          >
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Name *"
                className={classes.companyInput}
                variant="outlined"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={enumStrings.createChamberMemberText.tooltip.name}
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Member Dashboard Contact Name *"
                className={classes.companyInput}
                variant="outlined"
                id="adminConsoleContactName"
                name="adminConsoleContactName"
                value={formik.values.adminConsoleContactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.adminConsoleContactName &&
                  Boolean(formik.errors.adminConsoleContactName)
                }
                helperText={
                  formik.touched.adminConsoleContactName &&
                  formik.errors.adminConsoleContactName
                }
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip
                      .adminConsoleContactName
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Member Dashboard Email Username *"
                className={classes.companyInput}
                variant="outlined"
                id="adminConsoleLoginEmail"
                name="adminConsoleLoginEmail"
                value={formik.values.adminConsoleLoginEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.adminConsoleLoginEmail &&
                  Boolean(formik.errors.adminConsoleLoginEmail)
                }
                helperText={
                  formik.touched.adminConsoleLoginEmail &&
                  formik.errors.adminConsoleLoginEmail
                }
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip
                      .adminConsoleLoginEmail
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Contact Name"
                className={classes.companyInput}
                variant="outlined"
                id="contactName"
                name="contactName"
                value={formik.values.contactName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactName &&
                  Boolean(formik.errors.contactName)
                }
                helperText={
                  formik.touched.contactName && formik.errors.contactName
                }
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.contactName
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Contact Email"
                className={classes.companyInput}
                variant="outlined"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.contactEmail
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <PhoneNumberFormat
                formikErrors={formik.errors.phoneNumber}
                formikVal={formik.values.phoneNumber}
                handleChange={formik.handleChange}
                label="Company Contact Phone Number"
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.phoneNumber
                  }
                  showIt
                />
              </div>
            </div>
            <MultiContactForm
              formikValuesCustomContacts={formik.values.customContacts}
              formikSetFieldValue={formik.setFieldValue}
            />
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Street Address"
                className={classes.companyInput}
                variant="outlined"
                id="address.streetAddress"
                name="address.streetAddress"
                value={formik.values.address.streetAddress}
                onChange={formik.handleChange}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.streetAddress
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <ProvincePicker
                formikVal={formik.values.address?.province}
                formikErrors={
                  formik.touched.address?.province &&
                  Boolean(formik.errors.address?.province)
                }
                formikStringError={formik.errors.address?.province}
                formikSetFieldValue={formik.setFieldValue}
                isOptional
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.province
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="City"
                className={classes.companyInput}
                variant="outlined"
                id="address.city"
                name="address.city"
                value={formik.values.address.city}
                onChange={formik.handleChange}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={enumStrings.createChamberMemberText.tooltip.city}
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Postal/Zip Code"
                className={classes.companyInput}
                variant="outlined"
                id="address.postalCode"
                name="address.postalCode"
                value={formik.values.address.postalCode}
                onChange={formik.handleChange}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.postalCode
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <FormControl className={classes.companyDescription}>
                <TextField
                  type="text"
                  label="Company Description"
                  className={classes.companyDescriptionInput}
                  maxRows={6}
                  multiline
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      maxLength: CHARACTER_LIMIT_DESCRIPTION,
                    },
                  }}
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </FormControl>
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.description
                  }
                  showIt
                />
              </div>
            </div>
            <InputLabel style={{ margin: "auto" }}>
              {`${formik.values?.description?.length}/${CHARACTER_LIMIT_DESCRIPTION} characters remaining`}
              <FormHelperText
                component="legend"
                style={{
                  color: "#f44336",
                  paddingLeft: "10px",
                  textAlign: "center",
                  margin: 0,
                }}
              >
                {formik.errors.description}
              </FormHelperText>{" "}
            </InputLabel>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Website"
                className={classes.companyInput}
                variant="outlined"
                id="website"
                name="website"
                value={formik.values.website}
                onChange={formik.handleChange}
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.website
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Facebook Business Page URL"
                className={classes.companyInput}
                variant="outlined"
                id="facebook"
                name="facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
                error={
                  formik.touched.facebook && Boolean(formik.errors.facebook)
                }
                helperText={formik.touched.facebook && formik.errors.facebook}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.facebook
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Instagram Account URL"
                className={classes.companyInput}
                variant="outlined"
                id="instagram"
                name="instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
                error={
                  formik.touched.instagram && Boolean(formik.errors.instagram)
                }
                helperText={formik.touched.instagram && formik.errors.instagram}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.instagram
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Threads Account URL"
                className={classes.companyInput}
                variant="outlined"
                id="threads"
                name="threads"
                value={formik.values.threads}
                onChange={formik.handleChange}
                error={formik.touched.threads && Boolean(formik.errors.threads)}
                helperText={formik.touched.threads && formik.errors.threads}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.threads
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company LinkedIn Page URL"
                className={classes.companyInput}
                variant="outlined"
                id="linkedin"
                name="linkedin"
                value={formik.values.linkedin}
                onChange={formik.handleChange}
                error={
                  formik.touched.linkedin && Boolean(formik.errors.linkedin)
                }
                helperText={formik.touched.linkedin && formik.errors.linkedin}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.linkedin
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Twitter Account URL"
                className={classes.companyInput}
                variant="outlined"
                id="twitter"
                name="twitter"
                value={formik.values.twitter}
                onChange={formik.handleChange}
                error={formik.touched.twitter && Boolean(formik.errors.twitter)}
                helperText={formik.touched.twitter && formik.errors.twitter}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.twitter
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company TikTok Account URL"
                className={classes.companyInput}
                variant="outlined"
                id="tiktok"
                name="tiktok"
                value={formik.values.tiktok}
                onChange={formik.handleChange}
                error={formik.touched.tiktok && Boolean(formik.errors.tiktok)}
                helperText={formik.touched.tiktok && formik.errors.tiktok}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.tiktok
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <TextField
                type="text"
                label="Company Youtube Account URL"
                className={classes.companyInput}
                variant="outlined"
                id="youtube"
                name="youtube"
                value={formik.values.youtube}
                onChange={formik.handleChange}
                error={formik.touched.youtube && Boolean(formik.errors.youtube)}
                helperText={formik.touched.youtube && formik.errors.youtube}
              />
              <div
                style={{
                  marginTop: "50px",
                }}
              >
                <HoverToolTip
                  description={
                    enumStrings.createChamberMemberText.tooltip.youtube
                  }
                  showIt
                />
              </div>
            </div>
            <div className={classes.companyInputWithToolTipContainer}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Categories
                    selectedCategories={formik.values.categoryId}
                    handleCategoriesChange={handleCategoriesChange}
                    disableCategories={categoryDisable}
                    handleDelete={handleDelete}
                  />
                  <div
                    style={{
                      marginTop: "55px",
                    }}
                  >
                    <HoverToolTip
                      description={
                        enumStrings.createChamberMemberText.tooltip.categories
                      }
                      showIt
                    />
                  </div>
                </div>
                <FormHelperText
                  component="legend"
                  style={{
                    color: "#f44336",
                    paddingLeft: "15px",
                    paddingTop: "5px",
                    textAlign: "left",
                  }}
                >
                  {formik.errors.categoryId}
                </FormHelperText>
              </div>
            </div>

            <div className={classes.companyInputWithToolTipContainer}>
              <LogoPicker
                logo={formik.values.logo}
                deleteLogo={deleteLogo}
                base64={(e: any) => handleCreateBase64(e)}
              />
              <div
                style={{
                  marginTop: "40px",
                }}
              >
                <HoverToolTip
                  description={enumStrings.createChamberMemberText.tooltip.logo}
                  showIt
                />
              </div>
            </div>
            {/* <FormControl className={classes.switchContainer}>
              <Typography className={classes.switchText}>
                {enumStrings.showInAppText}
              </Typography>
              <ShowInAppSwitch
                checked={formik.values.showInApp}
                onChange={handleToggleChange}
                id="showInApp"
                name="showInApp"
                value={formik.values.showInApp}
                defaultChecked
              />
            </FormControl> */}
            <ChamberMemberIsSuspendedRadioGroup
              formikSetFieldValue={formik.setFieldValue}
              formikErrors={formik.errors}
              formikValues={formik.values}
            />
            <CSVLink
              data={csvData}
              filename={`${name} Member Dashboard Credentials.csv`}
              id="csv-download-button"
              style={{ display: "none" }}
            />
            <div
              style={{
                marginTop: "35px",
              }}
            >
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
            </div>
            {Object.keys(formik?.errors || {}).length > 0 ? (
              <div>
                <div className={classes.invalidError}>
                  Please address any issues in red to create
                </div>
                <br />
              </div>
            ) : null}
            <Button
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              style={{
                backgroundColor: "#E1A731",
                fontSize: "25px",
                fontWeight: "bold",
                borderRadius: "10px",
                width: "200px",
                margin: "39px",
                marginTop: "10px",
              }}
              disabled={loader || buttonDisabled}
            >
              {loader ? (
                <CircularProgress
                  style={{
                    color: "white",
                  }}
                />
              ) : (
                createButtonText
              )}
            </Button>
          </form>
        </div>
      </div>
      {suggestedAlternativeEmailUsernameModalOpen && (
        <CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal
          open={suggestedAlternativeEmailUsernameModalOpen}
          handleClose={closeSuggestedAlternativeEmailUsernameModal}
          handleUseSuggestedPlusAddressedAlternative={
            handleUseSuggestedPlusAddressedAlternative
          }
          currentAdminConsoleLoginEmail={
            formik.values.adminConsoleLoginEmail || ""
          }
          suggestedPlusAddressedEmail={suggestedPlusAddressedEmail}
        />
      )}
      {open && (
        <>
          <SendLoginInformationModal
            isSuspended={formik.values.isSuspended ? true : false}
            handleClose={handleClose}
            open={open}
            memberAdminPortalEmail={username}
            memberCompanyName={name}
            chamberMemberId={newChamberMemberId}
            type="createChamberMember"
          />
        </>
      )}
    </span>
  );
};

export default CreateChamberMember;

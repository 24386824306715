import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import {
  GetUserQuery,
  UserType,
  useGetUserQuery,
} from "../../../graphql/types";
import { UserTypeContext } from "../../../UserTypeContext";

const useGetChamberMemberFromUserIdInAuth = ():
  | GetUserQuery["getUser"]["chamberMemberInfo"]
  | undefined => {
  const [cognitoSub, setCognitoSub] = useState(undefined);
  useEffect(() => {
    const main = async (): Promise<void> => {
      const user = await Auth.currentAuthenticatedUser();
      setCognitoSub(user.attributes.sub);
    };
    main();
  }, []);

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  const { data } = useGetUserQuery({
    variables: { userId: cognitoSub || "" },
    skip:
      !cognitoSub ||
      userType === "" || // need to check for empty string here because its set to empty string at logout... and this query shouldn't run while its empty string
      userType === UserType.PerkPartnerDiscountAdmin,
  });

  // forcing return undefined here because perk partner discount admin users do not have a chamber member
  if (userType === UserType.PerkPartnerDiscountAdmin) {
    return undefined;
  }

  return data?.getUser?.chamberMemberInfo;
};

export default useGetChamberMemberFromUserIdInAuth;

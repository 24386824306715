import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { PerkPartnerDiscountReturn } from "../../graphql/types";
import { RedirectUri } from "../enumStrings";
import { useHistory } from "react-router-dom";
import { TextField, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../themes";
import LogoImage from "./utilities/LogoImage";
import { getLocalTimestamp } from "../pages/Root/RootAdmin";
import HoverToolTip from "./utilities/HoverToolTip";

export type AllTablePerkPartnerDiscountDataType = (PerkPartnerDiscountReturn & {
  numberOfChambersAvailableTo: number;
  views: number;
  opens: number;
  redeems: number;
})[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTablePerkPartnerDiscountDataType,
  comparator: (a: any, b: any) => number
): AllTablePerkPartnerDiscountDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
}

interface FullPagePerkPartnerDiscountTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPagePerkPartnerDiscountTableHead(
  props: FullPagePerkPartnerDiscountTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((themes: any) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
}));

const headCells: HeadCell[] = [
  {
    id: "perkLogo",
    label: "",
  },
  {
    id: "companyName",
    label: "Company Name",
  },
  {
    id: "title",
    label: "Perk Title",
  },
  {
    id: "numberOfChambersAvailableTo",
    label: "# of Chambers Available To",
  },
  {
    id: "views",
    label: "Views",
  },
  {
    id: "opens",
    label: "Opens",
  },
  {
    id: "redeems",
    label: "Redirects",
  },
  {
    id: "createdAtUtcMilli",
    label: "Creation Date",
  },
  {
    id: "updatedAtUtcMilli",
    label: "Update Date",
  },
];

export default function FullPagePerkPartnerDiscountTable({
  allPerkPartnerDiscountData,
}: {
  allPerkPartnerDiscountData: AllTablePerkPartnerDiscountDataType;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");
  const history = useHistory();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = React.useCallback(
    (discountInfo: PerkPartnerDiscountReturn) => {
      history.push(
        RedirectUri.editperkPartnerDiscountPageChamberAdmin,
        discountInfo
      );
    },
    [history]
  );

  const [searchInput, setSearchInput] = React.useState("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const allTablePerkPartnerDiscountData: AllTablePerkPartnerDiscountDataType =
    React.useMemo(() => {
      if (!allPerkPartnerDiscountData) {
        return [];
      }

      const allDiscountsArr = allPerkPartnerDiscountData.slice();

      if (searchInput.length > 0) {
        return allPerkPartnerDiscountData.filter((i) => {
          return i.title.toLowerCase().match(searchInput.toLowerCase());
        });
      }

      return allDiscountsArr;
    }, [allPerkPartnerDiscountData, searchInput]);

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        allTablePerkPartnerDiscountData,
        getComparator(order, orderBy)
      ),
    [allTablePerkPartnerDiscountData, order, orderBy]
  );

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder="Search by Perk or Member Company Name"
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPagePerkPartnerDiscountTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((discountInfo) => {
                return (
                  <TableRow
                    hover
                    onClick={() => handleClick(discountInfo)}
                    role="checkbox"
                    tabIndex={-1}
                    key={discountInfo.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      <LogoImage
                        logoSource={discountInfo.logo}
                        size="large"
                        squared
                        key={discountInfo.id}
                        chamberName={discountInfo.title}
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {discountInfo.companyName}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {discountInfo.title}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                      }}
                      component="th"
                      scope="row"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {discountInfo.numberOfChambersAvailableTo}
                        <HoverToolTip
                          description={
                            <>
                              {(discountInfo.availableToChambers || []).map(
                                ({ name }, index) => (
                                  <>
                                    {index > 0 ? "\n" : ""}• {name}
                                  </>
                                )
                              )}
                            </>
                          }
                          showIt
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {discountInfo.views}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {discountInfo.opens}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {discountInfo.redeems}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {getLocalTimestamp(
                        discountInfo.createdAtUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {getLocalTimestamp(
                        discountInfo.updatedAtUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

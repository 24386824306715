import React, { ReactElement } from "react";
import { Button, makeStyles } from "@material-ui/core";
import theme from "../../themes";
import enumStrings, { RedirectUri } from "../enumStrings";
import useRedirect from "../common/Hooks/useRedirect";

const useStyles = makeStyles((themes: any) => ({
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "16px",
  },
  settingsButtonContainer: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  errrorTextContainer: {
    maxWidth: "600px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const DeleteCPAData = (): ReactElement => {
  const classes = useStyles();

  // @ts-ignore
  const redirectToHomePage = useRedirect(RedirectUri.home);

  return (
    <div>
      <div className={classes.content}>
        <div className={classes.errrorTextContainer}>
          <h1>Chamber Perks App Account Deletion Guide</h1>
          <p
            style={{
              textAlign: "left",
              maxWidth: "500px",
            }}
          >
            <p>To delete your Chamber Perks App account, you can either:</p>

            <ol>
              <li>
                Delete through the Chamber Perks App
                <ol>
                  <li>Open the Chamber Perks App</li>
                  <li>Open settings</li>
                  <li>Tap on “My Account”</li>
                  <li>Tap “Delete Account”</li>
                  <li>
                    Follow the on-screen instructions to complete account
                    deletion
                  </li>
                </ol>
              </li>

              <li>
                Request account deletion via email
                <ol>
                  <li>
                    You can email{" "}
                    <a
                      href={`mailto:${enumStrings.supportContactEmail}?subject=Chamber Perks App Account Deletion Reques&body=Please%20state%20your%20Chamber%20Perks%20App%20account%20email%20here.`}
                    >
                      {enumStrings.supportContactEmail}
                    </a>{" "}
                    and request that your Chamber Perks App account be deleted.
                    Please include your Chamber Perks App account’s email
                    address in your email body. We will then get your account
                    deleted within 60 days of the request.
                  </li>
                </ol>
              </li>
            </ol>
            <br />
            <p>
              Except for the following data, all data will be deleted after user
              account deletion completes
            </p>

            <ul>
              <li>
                Perk redemption data (we will not be able to link this data to a
                deleted user account, so it will be considered anonymized after
                a user account is deleted)
              </li>
            </ul>
          </p>
        </div>
        <div className={classes.settingsButtonContainer}>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#E1A731",
              fontSize: "23px",
              fontWeight: "bold",
              borderRadius: "10px",
              width: "307px",
              margin: "39px",
              height: "116px",
            }}
            onClick={redirectToHomePage}
          >
            {enumStrings.errorPageText.homeButtonText}
          </Button>{" "}
        </div>
      </div>
    </div>
  );
};

export default DeleteCPAData;

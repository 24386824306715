//////////////////////
// Ensure this file stays the same as 'removePlusAddress' in backend
/////////////////////

export const getChamberNameAcronymized = (chamberName: string) => {
  let chamberNameAcronymized = "";
  chamberName
    .trim()
    .toLowerCase()
    .split(" ")
    // eslint-disable-next-line array-callback-return
    .map((word) => {
      if (word !== "of" && word !== "and" && word !== "the") {
        chamberNameAcronymized += word[0];
      }
    });

  return chamberNameAcronymized.replace(/[^A-Za-z]/g, "");
};

export const removePlusAddress = ({
  adminConsoleLoginEmail,
  chamberName,
}: {
  adminConsoleLoginEmail: string;
  chamberName: string;
}) => {
  const acronymizedChamberName = getChamberNameAcronymized(chamberName);

  const emailSectionToAnalyze = adminConsoleLoginEmail.trim().split("@")[0];
  const postAtEmailSection = adminConsoleLoginEmail.trim().split("@")[1];

  const plusAddressAcronym = `+${acronymizedChamberName}`;
  if (emailSectionToAnalyze.includes(plusAddressAcronym)) {
    const emailWithoutPlusAddress =
      emailSectionToAnalyze.split(plusAddressAcronym)[0]; // doing it this way incase the plus address piece has a number after it
    return `${emailWithoutPlusAddress}@${postAtEmailSection}`; // return email without plus address
  }

  return adminConsoleLoginEmail; // if no plus address, continue as normal
};

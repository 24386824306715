import { Auth } from "aws-amplify";
import { UserType } from "../../../graphql/types";
import enumStrings, { RedirectUri } from "../../enumStrings";

export const signInHandler = async (
  email: string,
  password: string
): Promise<void> => {
  try {
    const user = await Auth.signIn(email, password);

    if (
      user.signInUserSession &&
      user.signInUserSession.idToken &&
      user.signInUserSession.idToken.payload &&
      user.signInUserSession.idToken.payload["custom:isDisabled"] === "true"
    ) {
      await Auth.signOut();

      // eslint-disable-next-line
      throw { code: "UserDisabled" };
    }

    if (
      user.signInUserSession &&
      user.signInUserSession.accessToken &&
      user.signInUserSession.accessToken.payload &&
      user.signInUserSession.accessToken.payload["cognito:groups"][0] &&
      user.signInUserSession.accessToken.payload["cognito:groups"][0] ===
        UserType.CapUser
    ) {
      // eslint-disable-next-line
      throw { code: "CapUserTryingToSignIn" };
    }

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      sessionStorage.setItem(
        enumStrings.sessionStorageKey.loggedInWithTemporaryPassword,
        JSON.stringify({
          email: email.trim().toLowerCase(),
          tempPassword: password,
        })
      );
    }

    window.location.href = RedirectUri.home;
  } catch (error) {
    throw error;
  }
};

import React, { ReactElement } from "react";
import {
  makeStyles,
  Switch,
  FormGroup,
  SwitchProps,
  styled,
} from "@material-ui/core";

import { DayEnum, MonthEnum } from "../../graphql/types";
import enumStrings from "../enumStrings";
import PositionedTooltips from "./utilities/HoverToolTip";

const useStyles = makeStyles(() => ({
  toggleSwitchWithTitle: {
    display: "flex",
    margin: "auto",
    alignItems: "baseline",
    marginTop: "35px",
  },
}));

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));

export const checkIfPerkNotificationIsEnabledForSelectedAvailability = ({
  daysAvailable,
  monthsAvailable,
  startTimeUtcMilli,
  expiryTimeUtcMilli,
}: {
  daysAvailable: DayEnum[] | undefined | null;
  monthsAvailable: MonthEnum[] | undefined | null;
  startTimeUtcMilli: number | undefined | null;
  expiryTimeUtcMilli: number | undefined | null;
}) => {
  const today = new Date();
  const currentMonth = today.toLocaleString("default", {
    month: "long",
  });
  const dayOfWeek = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
  }).format(today);

  if (
    monthsAvailable &&
    monthsAvailable.length > 0 &&
    !monthsAvailable.includes(currentMonth.toUpperCase() as MonthEnum)
  ) {
    return false;
  }
  if (
    daysAvailable &&
    daysAvailable.length > 0 &&
    !daysAvailable.includes(dayOfWeek.toUpperCase() as DayEnum)
  ) {
    return false;
  }

  // if the current utc milliseconds is less than the start time, return false
  if (startTimeUtcMilli && expiryTimeUtcMilli) {
    if (today.getTime() < startTimeUtcMilli) {
      return false;
    }

    // if the current utc milliseconds is greater than the expiry time, return false
    if (today.getTime() > expiryTimeUtcMilli) {
      return false;
    }
  }

  return true;
};

const PerkNotificationSwitch = ({
  isCreate = false,
  chamberName,
  daysAvailable,
  monthsAvailable,
  startTimeUtcMilli,
  expiryTimeUtcMilli,
  formikNotify,
  formikSetFieldValue,
}: {
  isCreate?: boolean;
  chamberName: string;
  daysAvailable: DayEnum[] | undefined | null;
  monthsAvailable: MonthEnum[] | undefined | null;
  startTimeUtcMilli: number | undefined | null;
  expiryTimeUtcMilli: number | undefined | null;
  formikNotify: boolean | undefined | null;
  formikSetFieldValue: (a: string, b: boolean | any[]) => void;
}): ReactElement => {
  const classes = useStyles();

  const isPerkNotificationEnabledForSelectedAvailability =
    checkIfPerkNotificationIsEnabledForSelectedAvailability({
      daysAvailable,
      monthsAvailable,
      startTimeUtcMilli,
      expiryTimeUtcMilli,
    });

  return (
    <FormGroup>
      <div
        style={{
          marginTop: "45px",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: "#E1A731",
            fontWeight: "bold",
            fontSize: "20px",
            maxWidth: "750px",
            whiteSpace: "pre-wrap",
          }}
        >
          {!isPerkNotificationEnabledForSelectedAvailability
            ? enumStrings.disocuntText.perkDisabledFromBadAvailability
            : ""}
        </span>
        <div
          className={classes.toggleSwitchWithTitle}
          style={{ alignItems: "center", marginTop: "0px" }}
        >
          <h1 style={{ marginTop: "10px" }}>
            {enumStrings.disocuntText.notifyText}
          </h1>
          <ShowInAppSwitch
            disabled={!isPerkNotificationEnabledForSelectedAvailability}
            checked={
              !isPerkNotificationEnabledForSelectedAvailability
                ? false
                : formikNotify || false
            }
            onChange={(e, checked) => {
              formikSetFieldValue("notify", checked);
            }}
            id="notify"
            name="notifyToggle"
          />
          <PositionedTooltips
            showIt
            description={enumStrings.disocuntText.tooltip.notify(
              chamberName,
              isCreate
            )}
          />
        </div>
      </div>
    </FormGroup>
  );
};

export default PerkNotificationSwitch;

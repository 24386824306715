import { useMemo } from "react";
import {
  ChamberMemberCategory,
  Query,
  useGetAllCategoriesQuery,
} from "../../../graphql/types";

export const useGetCategoryIdToHumanReadableName = (): {
  categoryIdToHumanReadableName:
    | { [key in ChamberMemberCategory]: string }
    | null;
  allCategories: Query["getAllCategories"];
} => {
  const { data } = useGetAllCategoriesQuery();

  const categoryIdToHumanReadableName = useMemo(() => {
    const categoryObj = {} as { [key in ChamberMemberCategory]: string };
    if (!data?.getAllCategories) {
      return null;
    }
    // eslint-disable-next-line array-callback-return
    data.getAllCategories.map(({ id, name }) => {
      categoryObj[id] = name;
    });
    return categoryObj;
  }, [data?.getAllCategories]);

  return {
    categoryIdToHumanReadableName,
    allCategories: data?.getAllCategories,
  };
};

import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useLocation } from "react-router-dom";
import awsconfig from "./aws-amplify-config";
import Amplify from "aws-amplify";
import { UserTypeProvider } from "./UserTypeContext";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import * as Sentry from "@sentry/react";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://363a7c1d2f8e61ba3c01273e1060dec4@o4507950792704000.ingest.us.sentry.io/4508172468092928",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Amplify.configure(awsconfig);

// scroll to top when going to a new page
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  <React.StrictMode>
    <UserTypeProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </UserTypeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { ReactElement, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { UserType } from "../../../graphql/types";

import useRedirect from "../../common/Hooks/useRedirect";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";
const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  content: {
    background: "#FFFFFF",
    height: "1029px",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "20px",
    flex: "1",
    marginTop: "33px",
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  avatarImages: {
    borderRadius: "200px",
    margin: "auto",
    fontSize: "30px",
    backgroundColor: "#67b917",

    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  importContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  templateSubtitle: {
    textAlign: "center",
    fontSize: "2.5vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.5vw",
    },
  },
  companyInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    marginTop: "50px",
    width: "238px",
    height: "45px",
    "& input[type=file]": {
      display: "none",
    },
    "& .csv-label": {
      width: "100%",
      padding: "10px",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      textOverflow: "ellipsis",
    },
  },
}));
const ChamberAdminImportAdmin = (): ReactElement => {
  const classes = useStyles();
  useRedirect(RedirectUri.manageAMemberChamberAdmin);
  const [logo, setLogo] = useState("Select Member CSV File");
  const [dataObj, setDataObj] = useState([]);

  const csvData = [
    [
      "Company Name",
      "Comapany Website",
      "Company Address",
      "Phone Number",
      "Email",
      "Company Logo URL",
      "Show in App? (True of False)",
    ],
    [
      "Tims Toy Shop",
      "www.timstoys.com",
      "123 Street Avenue",
      "123-445-5433",
      "tim@toys.com",
      "www.timstoys.com/png",
      "TRUE",
    ],
    [
      "Nancy's Diner",
      "NancysDiner.com",
      "123 Southwest Cambridge",
      "433-235-6443",
      "nancy@diner.com",
      "www.nancydiner.com/logo_png",
      "www.nancydiner.com/logo_png",
    ],
  ];
  const handleForce = (data: any, fileInfo: any) => {
    setLogo(fileInfo.name);
    setDataObj(data);
  };

  const submitUserImport = () => {
    dataObj.forEach((item) => {
      console.log(item["comapany_website"]);
      console.log(item["company_address"]);
      console.log(item["company_logo_url"]);
      console.log(item["email"]);
      console.log(item["company_name"]);
      console.log(item["phone_number"]);
      console.log(item["show_in_app___true_of_false_"]);
    });
    setLogo("Select Member CSV File");
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: string) =>
      header.toLowerCase().replace(/\W/g, "_"),
  };
  const rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.ChamberAdmin][
      RedirectUri.importAMemberChamberAdmin
    ];
  return (
    <span>
      <div className={classes.titleContent}>
        {/* @ts-ignore */}
        <span className={classes.title}>{rootAdminObj.title}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.chamberContainer}>
          <div className={classes.imageContainer}></div>
        </div>{" "}
        <div className={classes.importContainer}>
          <span className={classes.templateSubtitle}>
            {enumStrings.chamberAdminImportMembersText.importCSVText}
            <span> </span>
            <CSVLink
              data={csvData}
              filename={`Import members tempalate.csv`}
              style={{
                color: "#E1A731",
              }}
            >
              {enumStrings.chamberAdminImportMembersText.hereText}
            </CSVLink>{" "}
            {enumStrings.chamberAdminImportMembersText.toDownloadText}
          </span>
          <CSVReader
            cssClass={classes.companyInputLogo}
            label={logo}
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          ></CSVReader>
          <Button
            type="submit"
            size="large"
            color="primary"
            variant="contained"
            style={{
              backgroundColor: "#E1A731",
              fontSize: "18px",
              fontWeight: "bold",
              borderRadius: "10px",
              width: "200px",
              margin: "39px",
            }}
            onClick={submitUserImport}
          >
            {enumStrings.chamberAdminImportMembersText.importMembersButtonText}
          </Button>{" "}
        </div>
      </div>
    </span>
  );
};

export default ChamberAdminImportAdmin;

import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useRedirect = (
  redirectUrl: string,
  redirectState?: { [key: string]: any }
): (() => void) => {
  const history = useHistory();
  return useCallback(() => {
    history.push(redirectUrl, redirectState);
  }, [history, redirectUrl, redirectState]);
};

export default useRedirect;

import * as React from "react";
import { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

const PasswordRequirementToolTip = ({
  passwordText,
}: {
  passwordText: string;
}): React.ReactElement => {
  const [eightCarsValid, setEightCarsValid] = useState(false);
  const [lowerCaseValid, setLowerCaseValid] = useState(false);
  const [upperCaseValid, setUpperCaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);

  useEffect(() => {
    // "8chars"
    if (passwordText.length >= 8) {
      setEightCarsValid(true);
    } else {
      setEightCarsValid(false);
    }

    // lowerCaseLetter
    if (/[a-z]/.test(passwordText)) {
      setLowerCaseValid(true);
    } else {
      setLowerCaseValid(false);
    }

    // upperCaseLetter
    if (/[A-Z]/.test(passwordText)) {
      setUpperCaseValid(true);
    } else {
      setUpperCaseValid(false);
    }

    // number
    if (/[0-9]/.test(passwordText)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }

    // special
    if (
      passwordText.includes("^") ||
      passwordText.includes("$") ||
      passwordText.includes("*") ||
      passwordText.includes(".") ||
      passwordText.includes("[") ||
      passwordText.includes("]") ||
      passwordText.includes("{") ||
      passwordText.includes("}") ||
      passwordText.includes("(") ||
      passwordText.includes(")") ||
      passwordText.includes("?") ||
      passwordText.includes('"') ||
      passwordText.includes("!") ||
      passwordText.includes("@") ||
      passwordText.includes("#") ||
      passwordText.includes("%") ||
      passwordText.includes("&") ||
      passwordText.includes("/") ||
      passwordText.includes("\\") ||
      passwordText.includes(",") ||
      passwordText.includes(">") ||
      passwordText.includes("<") ||
      passwordText.includes("'") ||
      passwordText.includes(":") ||
      passwordText.includes(";") ||
      passwordText.includes("|") ||
      passwordText.includes("_") ||
      passwordText.includes("~") ||
      passwordText.includes("`") ||
      passwordText.includes("-")
    ) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  }, [passwordText]);

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 10000,
        backgroundColor: "white",
        display: "flex",
        top: "-115px",
        borderWidth: "1px",
        borderColor: "black",
        borderRadius: "10px",
        padding: 10,
        borderStyle: "solid",
      }}
    >
      <div>
        <Requirement valid={eightCarsValid} text="8 Characters Minimum" />
        <Requirement valid={lowerCaseValid} text="One Lowercase Letter" />
        <Requirement valid={upperCaseValid} text="One Uppercase Letter" />
        <Requirement valid={numberValid} text="One Number" />
        <Requirement
          valid={specialCharValid}
          text="One Special character (@#!)"
        />
      </div>
    </div>
  );
};

const Requirement = ({
  valid,
  text,
}: {
  valid: boolean;
  text: string;
}): React.ReactElement => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: 2,
      marginBottom: 2,
    }}
  >
    {valid ? (
      <CheckIcon style={{ color: "green" }} fontSize={"medium"} />
    ) : (
      <CloseIcon color="error" fontSize={"medium"} />
    )}
    <div style={{ width: 10 }} />
    <span style={{ color: "#242629" }}>{text}</span>
  </div>
);

export default PasswordRequirementToolTip;

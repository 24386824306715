import React, { ReactElement, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { UserType } from "../../../graphql/types";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import useRedirect from "../../common/Hooks/useRedirect";
import "react-toastify/dist/ReactToastify.css";
import { UserTypeContext } from "../../../UserTypeContext";

const useStyles = makeStyles((themes: any) => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginRight: "24px",
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    flexGrow: 1,
    height: "85vh",
    borderRadius: "16px",
    margin: "20px",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    marginTop: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  avatarImages: {
    borderRadius: "200px",
    margin: "auto",
    fontSize: "30px",
    backgroundColor: "#67b917",

    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  settingsButtonContainer: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

const ChamberMemberAdminSettings = (): ReactElement => {
  const classes = useStyles();
  useRedirect(RedirectUri.manageAMemberChamberAdmin);
  const chamberInfo = useGetChamberFromUserIdInAuth();
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  let rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.ChamberMemberAdmin][
      RedirectUri.settingsChamberMemberAdmin
    ];

  if (userType === "CHAMBER_ADMIN") {
    rootAdminObj =
      // @ts-ignore
      enumStrings?.groups[UserType.ChamberAdmin][
        RedirectUri.settingsOfChamberChamberAdmin
      ];
  }

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
        </div>
      </div>
      {/* 1. change the "support page" for chamber members: switch the positions of the two emails... update the description of the "chamber support email" to also show "to change you email username" */}
      <div className={classes.content}>
        {userType === UserType.ChamberAdmin ? (
          <>
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
                paddingTop: "20px",
                paddingBottom: "0px",
                maxWidth: "850px",
              }}
            >
              To get general guidance with your Chamber Dashboard, and any other
              general support issues, contact {/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  (window.location.href = `mailto:${enumStrings.subscriptionsContactEmail}?subject=Chamber Perks Dashboard Question ${chamberInfo?.name} &body=Please%20type%20your%20question%20here.`)
                }
              >
                {enumStrings.subscriptionsContactEmail}
              </a>
            </span>
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
                paddingTop: "20px",
                paddingBottom: "10px",
                maxWidth: "850px",
              }}
            >
              To change your Chamber Dashboard login email username, and for
              technical support issues and feedback on the dashboard or Chamber
              Perks App, contact {/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  (window.location.href = `mailto:${enumStrings.supportContactEmail}?subject=Chamber Dashboard Technical Issue ${chamberInfo?.name} &body=Please%20type%20your%20feedback%20here.`)
                }
              >
                {enumStrings.supportContactEmail}
              </a>
            </span>
          </>
        ) : (
          <>
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
                paddingTop: "30px",
                paddingBottom: "30px",
                maxWidth: "850px",
              }}
            >
              To change your Member Dashboard login email username, get general
              guidance with your Member Dashboard, and other general support
              issues,{" "}
              {chamberInfo?.supportLink ? (
                <>
                  go to {/* eslint-disable-next-line */}
                  <a target="_blank" href={chamberInfo.supportLink || ""}>
                    {chamberInfo?.supportLink}
                  </a>
                  , or
                </>
              ) : (
                ""
              )}{" "}
              contact {/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  (window.location.href = `mailto:${chamberInfo?.supportContactEmail}?subject=Chamber Perks Dashboard Question &body=Please%20type%20your%20question%20here.`)
                }
              >
                {chamberInfo?.supportContactEmail}
              </a>
            </span>
            <span
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "18px",
                paddingTop: "30px",
                paddingBottom: "30px",
                maxWidth: "850px",
              }}
            >
              For technical support issues and feedback, contact{" "}
              {/* eslint-disable-next-line */}
              <a
                onClick={() =>
                  (window.location.href = `mailto:${enumStrings.supportContactEmail}?subject=Member Dashboard Technical Issue ${chamberInfo?.name} &body=Please%20type%20your%20feedback%20here.`)
                }
              >
                {enumStrings.supportContactEmail}
              </a>
            </span>
          </>
        )}
      </div>
    </span>
  );
};

export default ChamberMemberAdminSettings;

import React, { ReactElement, useContext, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import {
  UserType,
  useGetPremiumChamberMarketerLinkQuery,
} from "../../../graphql/types";
import useRedirect from "../../common/Hooks/useRedirect";
import "react-toastify/dist/ReactToastify.css";
import { UserTypeContext } from "../../../UserTypeContext";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import Loader from "../../common/Loader/Loader";

const useStyles = makeStyles((themes: any) => ({
  buttonGroupRoot: {
    paddingBottom: "0px",
    "& .MuiToggleButton-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
      backgroundColor: "white",
      color: "#737373",
      fontWeight: "bold",
      fontSize: "17px",
      "&:hover": {
        boxShadow: "3px 2px 5px 0px rgba(0,0,0,0.1)",
        backgroundColor: "white",
      },
      "&.Mui-selected": {
        backgroundColor: "#E0A731",
        color: "white",
        "&:hover": {
          boxShadow: "0px 2px 5px 3px rgba(0,0,0,0.1)",
          backgroundColor: "#E0A731",
        },
      },
    },
  },
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},

  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  chamberContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row-reverse",
    width: "431px",
    margin: "auto",
    backgroundColor: "white",
    marginBottom: "20px",
    borderRadius: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      justifyContent: "center",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      justifyContent: "center",
    },
  },
  imageContainer: {
    width: "100px",
    height: "100px",
    borderRadius: "200px",
    backgroundColor: "#F2F2F6",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "95px",
      height: "95px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "150px",
      height: "150px",
    },
  },
  images: {
    borderRadius: "200px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  chamberTitle: {
    margin: "auto",
    alignItems: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2em",
    },
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  chamberInput: {
    backgroundColor: "#F2F2F6",
    paddingBottom: "10px",
    borderRadius: "15px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "55px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "55px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        borderRadius: "15px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#E1A731",
      },
    },
    "& label.Mui-focused": {
      color: "#E1A731",
    },
    "& label.Mui": {
      color: "black",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    flexGrow: 1,
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    flex: "1",
    marginTop: "33px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
  },
  buttonContainers: {
    marginTop: "46px",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  chamberInputLogo: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
    padding: "8px",
    outline: "none",
    backgroundColor: "#F2F2F6",
    border: "1.55px solid black",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "10px",
    marginTop: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "265px",
      height: "100px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "431px",
      height: "100px",
    },
  },
  labelLogo: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    justifyContent: "space-between",
    color: "#9A9A9C",
    font: "inherit",
    fontSize: "1.18676em",
  },
  avatarImages: {
    borderRadius: "200px",
    margin: "auto",
    fontSize: "30px",
    backgroundColor: "#67b917",

    [theme.breakpoints.down("sm")]: {
      width: "85px",
      height: "85px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "140px",
      height: "140px",
    },
  },
  settingsButtonContainer: {
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
  },
}));

// declare var Dropbox: any;

const DropboxEmbedding = ({
  dropBoxLink,
  hide = false,
  isPremium = false,
}: {
  dropBoxLink: string;
  hide?: boolean;
  isPremium?: boolean;
}): ReactElement => {
  // useEffect(() => {
  //   if (dropBoxLink.length === 0) {
  //     return;
  //   }
  //   const options = {
  //     link: dropBoxLink,
  //     file: {
  //       zoom: "best",
  //     },
  //     folder: {
  //       view: "grid",
  //       headerSize: "normal",
  //     },
  //   };

  //   // Check if Dropbox is defined (the Dropbox Chooser script is loaded)
  //   if (typeof Dropbox !== "undefined") {
  //     if (!Dropbox.isBrowserSupported()) {
  //       return;
  //     }

  //     Dropbox.embed(
  //       options,
  //       document.getElementById(
  //         `${isPremium ? "dropbox-embed-premium" : "dropbox-embed-basic"}`
  //       )
  //     );
  //   }

  //   return () => {
  //     // Cleanup code if needed (runs when the component unmounts)
  //     if (typeof Dropbox !== "undefined") {
  //       if (!Dropbox.isBrowserSupported()) {
  //         return;
  //       }

  //       const embed = Dropbox.embed(
  //         options,
  //         document.getElementById(
  //           `${isPremium ? "dropbox-embed-premium" : "dropbox-embed-basic"}`
  //         )
  //       );
  //       Dropbox.unmount(embed);
  //     }
  //   };
  // }, [dropBoxLink, isPremium]);

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const {
    state: { userType },
  } = useContext(UserTypeContext);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingBottom: "0px",
        textAlign: "center",
        position: hide ? "absolute" : undefined,
        left: hide ? "-999999999px" : undefined,
      }}
    >
      {isPremium && dropBoxLink === "" ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "100px",
            paddingLeft: "100px",
            fontSize: "20px",
          }}
        >
          <span>
            Your Chamber Perks App subscription includes a complimentary basic
            version of the Chamber Marketer. This version contains all the
            necessary tools to assist you in launching the Chamber Perks App. If
            your chamber is interested in exploring the paid premium version,
            please don't hesitate to contact us at{" "}
            {/* eslint-disable-next-line */}
            <a
              onClick={() =>
                (window.location.href = `mailto:marketing@chamberperksapp.com?subject=Chamber Marketer Premium Inquiry ${chamberInfo?.name} Feedback &body=Please%20type%20your%20message%20here.`)
              }
            >
              marketing@chamberperksapp.com
            </a>{" "}
            for further information or to initiate a discussion.
          </span>
        </div>
      ) : (
        // <div
        //   id={`${isPremium ? "dropbox-embed-premium" : "dropbox-embed-basic"}`}
        //   style={{ height: "100%" }}
        // />

        <div
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "20px",
              paddingTop: "30px",
              paddingBottom: "30px",
              maxWidth: "600px",
            }}
          >
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {userType === UserType.ChamberAdmin
                ? "Chamber Marketer"
                : "Marketing Toolkit"}{" "}
              access guide:
            </span>
            <br />
            <br />
            <div style={{ textAlign: "left" }}>
              <ol>
                <li>
                  To access the Chamber Perks App marketing material you DO NOT
                  need to join the Dropbox.
                </li>
                <li style={{ marginTop: "10px" }}>
                  To find the marketing material you are looking for scroll down
                  and click a folder.
                </li>
                <li style={{ marginTop: "10px" }}>
                  Download the file you want by clicking the download icon.
                </li>
                <li style={{ marginTop: "10px" }}>
                  If you see the "log in or sign up" popup, click the download
                  link at the bottom to download.
                </li>
                <li style={{ marginTop: "10px" }}>
                  <a target="_blank" href={dropBoxLink} rel="noreferrer">
                    Click here{" "}
                  </a>
                  to open your {isPremium ? "premium" : "basic"}{" "}
                  {userType === UserType.ChamberAdmin
                    ? "Chamber Marketer"
                    : "Marketing Toolkit"}
                  .
                </li>
              </ol>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ClickHere = ({ link }: { link: string }): ReactElement => {
  return <></>;
  // return (
  //   <div style={{ marginBottom: "-10px" }}>
  // {/* eslint-disable-next-line */}
  // <a target="_blank" href={link}>
  //   Click here
  // </a>{" "}
  //     to open this marketing toolkit if it doesn't show above
  //   </div>
  // );
};

const CPAMarketingToolkit = (): ReactElement => {
  const classes = useStyles();
  useRedirect(RedirectUri.manageAMemberChamberAdmin);
  const {
    state: { userType },
  } = useContext(UserTypeContext);

  let rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.ChamberMemberAdmin][
      RedirectUri.cpaMarketingToolkit
    ];

  if (userType === UserType.ChamberAdmin) {
    rootAdminObj =
      // @ts-ignore
      enumStrings?.groups[UserType.ChamberAdmin][
        RedirectUri.cpaMarketingToolkit
      ];
  }

  const [chamberMarketerVersion, setChamberMarketerVersion] = useState<
    "premium" | "basic"
  >("basic");

  const handleChangeChamberMarketerVersion = (
    event: React.MouseEvent<HTMLElement>,
    newVersion: "premium" | "basic" | null
  ) => {
    if (newVersion) {
      setChamberMarketerVersion(newVersion);
    }
  };

  const chamberInfo = useGetChamberFromUserIdInAuth();

  const {
    data: premiumChamberMarketerLinkData,
    loading: loadingPremiumChamberMarketerLinkData,
  } = useGetPremiumChamberMarketerLinkQuery({
    variables: { chamberId: chamberInfo?.id || "" },
    skip:
      !chamberInfo?.id ||
      (!(userType === UserType.RootAdmin) &&
        !(userType === UserType.ChamberAdmin)),
  });

  const dropBoxLink = useMemo(() => {
    if (userType === UserType.ChamberAdmin) {
      return "https://www.dropbox.com/scl/fo/n3qzu1rrlv93nzjslgokk/h?rlkey=xg5ax7imaf9w64g7omeoiiayl&dl=0";
    }

    return "https://www.dropbox.com/scl/fo/hnjp7wztcr5nzcded9efc/h?rlkey=f0jem7hz6bb94w7cz3o6vm9o6&dl=0";
  }, [userType]);

  const downloadText = useMemo(() => {
    return <></>;
    // return (
    //   <>
    //     <h2 style={{ fontWeight: "bold", margin: 0, paddingTop: "10px" }}>
    //       To download content without signing up, click folder, hover over file,
    //       and click{" "}
    //       <img
    //         alt="DownloadIcon"
    //         src={DownloadIcon}
    //         style={{ height: "15px", marginBottom: "4px" }}
    //       />{" "}
    //       button
    //     </h2>
    //   </>
    // );
  }, []);

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
              paddingRight: "20px",
            }}
          >
            {rootAdminObj.description}
          </span>
        </div>
      </div>
      <div className={classes.content}>
        {userType === UserType.ChamberMemberAdmin ? (
          <>
            {downloadText}
            <DropboxEmbedding dropBoxLink={dropBoxLink} />
            <ClickHere link={dropBoxLink} />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              width: "100%",
            }}
          >
            {loadingPremiumChamberMarketerLinkData ||
            !premiumChamberMarketerLinkData ? (
              <Loader />
            ) : (
              <>
                <ToggleButtonGroup
                  className={classes.buttonGroupRoot}
                  value={chamberMarketerVersion}
                  exclusive
                  onChange={handleChangeChamberMarketerVersion}
                >
                  <ToggleButton value="basic">Basic</ToggleButton>
                  <ToggleButton value="premium">Premium</ToggleButton>
                </ToggleButtonGroup>
                {chamberMarketerVersion === "premium" &&
                (
                  premiumChamberMarketerLinkData?.getChamber
                    ?.premiumChamberMarketerLink || ""
                ).length === 0
                  ? ""
                  : downloadText}
                <DropboxEmbedding
                  dropBoxLink={dropBoxLink}
                  hide={chamberMarketerVersion === "premium"}
                />
                <DropboxEmbedding
                  isPremium
                  dropBoxLink={
                    premiumChamberMarketerLinkData?.getChamber
                      ?.premiumChamberMarketerLink || ""
                  }
                  hide={chamberMarketerVersion === "basic"}
                />
                {chamberMarketerVersion === "premium" &&
                premiumChamberMarketerLinkData?.getChamber
                  ?.premiumChamberMarketerLink ? (
                  <div>
                    Note: if the premium marketing toolkit is not showing,
                    please ensure you are signed in to your dropbox account
                  </div>
                ) : null}
                {chamberMarketerVersion === "premium" &&
                !premiumChamberMarketerLinkData?.getChamber
                  ?.premiumChamberMarketerLink ? null : (
                  <ClickHere
                    link={
                      chamberMarketerVersion === "premium"
                        ? premiumChamberMarketerLinkData?.getChamber
                            ?.premiumChamberMarketerLink || ""
                        : dropBoxLink
                    }
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
    </span>
  );
};

export default CPAMarketingToolkit;

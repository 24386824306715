import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles({
  loading: {
    color: "#E1A731",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
});

const Loader = (): ReactElement => {
  const classes = useStyles();

  return <CircularProgress className={classes.loading} />;
};

export default Loader;

import { FormHelperText, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { ReactElement } from "react";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme: any) => ({
  companyInput: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    height: "55px",
    [theme.breakpoints.down("sm")]: {
      width: "330px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "360px",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white !important",
      borderRadius: "15px !important",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      color: "#37474f",
    },
    "& .MuiInputBase-input.MuiFilledInput-input.Mui-disabled": {
      color: "black",
      opacity: 1,
      "-webkit-text-fill-color": "black",
    },
  },
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}
const CurrencyNumberFormatCustom = (
  props: NumberFormatCustomProps
): ReactElement => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix="$ "
      decimalScale={2}
    />
  );
};

export const CurrencyNumberFormat = ({
  inputValue,
  errorString,
  handleChange,
  label,
  showError,
  minWidth,
  disabled,
}: {
  inputValue: any;
  errorString: any;
  handleChange: any;
  label: string;
  showError: boolean;
  minWidth?: boolean;
  disabled?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        disabled={disabled}
        type="text"
        label={label}
        className={classes.companyInput}
        style={{
          backgroundColor: "white",
          ...(minWidth && { width: "100px" }),
        }}
        variant="filled"
        id="currencyNumber"
        name="currencyNumber"
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: CurrencyNumberFormatCustom as any,
          disableUnderline: true,
        }}
        sx={{
          "& .MuiFilledInput-root:before": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:after": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:hover:before": {
            borderBottom: "none",
          },
        }}
        value={inputValue}
        onChange={handleChange}
        error={showError}
        helperText={showError && errorString}
      />
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "5px",
          marginBottom: "-5px",
          textAlign: "center",
        }}
      >
        {errorString}
      </FormHelperText>
    </div>
  );
};

const PercentNumberFormatCustom = (
  props: NumberFormatCustomProps
): ReactElement => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      suffix=" %"
      decimalScale={0}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue !== undefined) {
          return floatValue <= 100;
        }
        return true;
      }}
    />
  );
};

export const PercentNumberFormat = ({
  inputValue,
  errorString,
  handleChange,
  label,
  showError,
}: {
  inputValue: any;
  errorString: any;
  handleChange: any;
  label: string;
  showError: boolean;
}) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        type="text"
        label={label}
        className={classes.companyInput}
        style={{
          backgroundColor: "white",
        }}
        variant="filled"
        id="percentNumber"
        name="percentNumber"
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: PercentNumberFormatCustom as any,
        }}
        sx={{
          "& .MuiFilledInput-root:before": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:after": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        value={inputValue}
        onChange={handleChange}
        error={showError}
        helperText={showError && errorString}
      />
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "5px",
          marginBottom: "-5px",
          textAlign: "center",
        }}
      >
        {errorString}
      </FormHelperText>
    </div>
  );
};

const MonthNumberFormatCustom = (
  props: NumberFormatCustomProps
): ReactElement => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values): void => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
      decimalScale={0}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue !== undefined) {
          return floatValue <= 100;
        }
        return true;
      }}
    />
  );
};

export const MonthNumberFormat = ({
  inputValue,
  errorString,
  handleChange,
  label,
  showError,
  minWidth,
}: {
  inputValue: any;
  errorString: any;
  handleChange: any;
  label: string;
  showError: boolean;
  minWidth?: boolean;
}) => {
  const classes = useStyles();

  return (
    <div>
      <TextField
        type="text"
        label={label}
        className={classes.companyInput}
        style={{
          backgroundColor: "white",
          ...(minWidth && { width: "100px" }),
        }}
        variant="filled"
        id="monthNumber"
        name="monthNumber"
        InputProps={{
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          inputComponent: MonthNumberFormatCustom as any,
        }}
        sx={{
          "& .MuiFilledInput-root:before": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:after": {
            borderBottom: "none",
          },
          "& .MuiFilledInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
        }}
        value={inputValue}
        onChange={handleChange}
        error={showError}
        helperText={showError && errorString}
      />
      <FormHelperText
        component="legend"
        style={{
          color: "#f44336",
          paddingLeft: "10px",
          paddingTop: "5px",
          marginBottom: "-5px",
          textAlign: "center",
        }}
      >
        {errorString}
      </FormHelperText>
    </div>
  );
};

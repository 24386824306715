import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import {
  GetAllMemberLoginCredentialsDocument,
  GetAllMemberLoginCredentialsQuery,
  GetAllMemberLoginCredentialsStatusEnum,
  useCreateAllMemberLoginCredentialsMutation,
  useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery,
} from "../../../graphql/types";
import enumStrings from "../../enumStrings";
import { getApolloContext } from "@apollo/client";
import useGetChamberFromUserIdInAuth from "../../common/Hooks/useGetChamberFromUserIdInAuth";
import { getLocalTimestamp } from "../Root/RootAdmin";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: "100%",
      borderRadius: "5px",
      textAlign: "center",
      maxHeight: "650px",
      width: "740px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(4),
      position: "relative",
    },
    yesButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
    okButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
  })
);

const DownloadSuccessModal = ({
  open,
  handleClose,
  rawCSVText,
  numOfMembersNotLoggedInYet,
}: {
  open: boolean;
  handleClose: () => void;
  rawCSVText: string;
  numOfMembersNotLoggedInYet: number;
}): ReactElement => {
  const classes = useStyles();

  const handleManualDownloadOfCsv = useCallback(() => {
    if (rawCSVText) {
      const blob = new Blob([rawCSVText], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "All Member Login Credentials.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [rawCSVText]);

  useEffect(() => {
    handleManualDownloadOfCsv();
    // empty dependency array cuz only wanna run this once when the modal opens ... bruh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>Download Successful</h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0 }}>
              A CSV file containing member company name, Member Dashboard
              contact name, Member Dashboard account email, and Member Dashboard
              account temporary password for{" "}
              <span style={{ fontWeight: "bold" }}>
                {numOfMembersNotLoggedInYet}
              </span>{" "}
              of your members who have{" "}
              <span style={{ fontWeight: "bold" }}>not yet logged in</span> to
              their Member Dashboard and have an active membership status has
              been automatically downloaded.
              <br />
              <br />
              If the file was not automatically downloaded, click Download.
              Otherwise click Close.
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.yesButton}
            onClick={handleManualDownloadOfCsv}
            variant="contained"
          >
            Download
          </Button>
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DownloadPendingModal = ({
  open,
  setAllMemberLoginCredentialsRawCSVText,
  setDownloadFailureModalOpen,
}: {
  open: boolean;
  setAllMemberLoginCredentialsRawCSVText: (value: string) => void;
  setDownloadFailureModalOpen: (value: boolean) => void;
}) => {
  const classes = useStyles();

  const modalOpenTime = useMemo(() => {
    return new Date();
  }, []);

  const apolloClient = useContext(getApolloContext());

  const handleAllMembereLoginCredentialsDownload = useCallback(
    async ({ downloadProcessId }: { downloadProcessId: string }) => {
      // if 15 minutes have passed since the modal opened, close the modal
      if (new Date().getTime() - modalOpenTime.getTime() > 15 * 60 * 1000) {
        setDownloadFailureModalOpen(true);
        return;
      }

      if (downloadProcessId && downloadProcessId.length > 0) {
        const allMemberLoginCredentialsData = (await apolloClient.client?.query(
          {
            query: GetAllMemberLoginCredentialsDocument,
            variables: {
              processId: downloadProcessId,
            },
            fetchPolicy: "network-only",
          }
        )) as {
          data: GetAllMemberLoginCredentialsQuery | undefined;
        };

        if (
          allMemberLoginCredentialsData?.data?.getAllMemberLoginCredentials
            ?.status
        ) {
          const downloadStatus =
            allMemberLoginCredentialsData?.data?.getAllMemberLoginCredentials
              ?.status;

          if (
            downloadStatus === GetAllMemberLoginCredentialsStatusEnum.Processing
          ) {
            // pause for 5 seconds before trying again
            await new Promise((resolve) => setTimeout(resolve, 5000));

            await handleAllMembereLoginCredentialsDownload({
              downloadProcessId,
            });
            return;
          }
          if (
            downloadStatus === GetAllMemberLoginCredentialsStatusEnum.Success
          ) {
            if (
              allMemberLoginCredentialsData?.data?.getAllMemberLoginCredentials
                ?.successCSVRawTextOfAllMemberLoginCredentials
            ) {
              setAllMemberLoginCredentialsRawCSVText(
                allMemberLoginCredentialsData?.data
                  ?.getAllMemberLoginCredentials
                  ?.successCSVRawTextOfAllMemberLoginCredentials
              );
            } else {
              setDownloadFailureModalOpen(true);
            }
            return;
          }
          if (
            downloadStatus === GetAllMemberLoginCredentialsStatusEnum.Failure
          ) {
            setDownloadFailureModalOpen(true);
            return;
          }
        }
      }
    },
    [
      apolloClient.client,
      modalOpenTime,
      setAllMemberLoginCredentialsRawCSVText,
      setDownloadFailureModalOpen,
    ]
  );

  const [createAllMemberLoginCredentialsMutation] =
    useCreateAllMemberLoginCredentialsMutation();

  useEffect(() => {
    const executeDownloadAllMemberLoginCredentials = async () => {
      const createRes = await createAllMemberLoginCredentialsMutation();
      if (createRes.data?.createAllMemberLoginCredentials?.processId) {
        handleAllMembereLoginCredentialsDownload({
          downloadProcessId:
            createRes.data.createAllMemberLoginCredentials.processId,
        });
      }
    };
    executeDownloadAllMemberLoginCredentials();
    // empty dependency array cuz only wanna run this once when the modal opens ... bruh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>Download in Progress</h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0 }}>
              Please do not refresh or close your browser or navigate away from
              this page.
              <br />
              <br />
              Downloading may take up to 5 minutes.
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#E1A731",
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const DownloadFailureModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h1 style={{ fontWeight: "bold" }}>Something Went Wrong</h1>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
          }}
        >
          <span>
            <h3 style={{ margin: 0 }}>
              Unable to download All Member Login Credentials, please try again.
              <br />
              Please contact {enumStrings.supportContactEmail} if this issue
              persists.
            </h3>
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const DownloadAllMemberLoginCredentialsModal = ({
  open,
  handleClose,
  numOfMembersNotLoggedInYet,
}: {
  open: boolean;
  handleClose: () => void;
  numOfMembersNotLoggedInYet: number;
}): ReactElement => {
  const chamberInfo = useGetChamberFromUserIdInAuth();

  const classes = useStyles();

  const [downloadSuccessModalOpen, setDownloadSuccessModalOpen] =
    useState(false);

  const [downloadPendingModalOpen, setDownloadPendingModalOpen] =
    useState(false);

  const [downloadFailureModalOpen, setDownloadFailureModalOpen] =
    useState(false);

  const [rawCSVText, setRawCSVText] = useState("");

  const setAllMemberLoginCredentialsRawCSVText = useCallback(
    (value: string) => {
      setRawCSVText(value);
      setDownloadSuccessModalOpen(true);
    },
    [setRawCSVText, setDownloadSuccessModalOpen]
  );

  // const [
  //   sampleMemberLoginCredentialsLoading,
  //   setSampleMemberLoginCredentialsLoading,
  // ] = useState(false);

  // const apolloClient = useContext(getApolloContext());

  // const downloadSampleMemberLoginCredentialsData = useCallback(async () => {
  //   setSampleMemberLoginCredentialsLoading(true);

  //   const sampleMemberLoginCredentialsData = (await apolloClient.client?.query({
  //     query: GetSampleMemberLoginCredentialsDocument,
  //     fetchPolicy: "network-only",
  //   })) as {
  //     data: GetSampleMemberLoginCredentialsQuery | undefined;
  //   };

  //   if (
  //     sampleMemberLoginCredentialsData?.data?.getSampleMemberLoginCredentials
  //       ?.sampleCSVRawTextOfAllMemberLoginCredentials
  //   ) {
  //     const blob = new Blob(
  //       [
  //         sampleMemberLoginCredentialsData?.data
  //           ?.getSampleMemberLoginCredentials
  //           ?.sampleCSVRawTextOfAllMemberLoginCredentials || "",
  //       ],
  //       { type: "text/csv;charset=utf-8;" }
  //     );
  //     const link = document.createElement("a");
  //     const url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute(
  //       "download",
  //       "Sample - All Member Login Credentials.csv"
  //     );
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }

  //   setSampleMemberLoginCredentialsLoading(false);
  // }, [apolloClient.client]);

  const {
    data: allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliData,
  } = useGetAllMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliQuery({
    variables: {
      chamberId: chamberInfo?.id || "",
    },
    skip: !chamberInfo?.id,
  });

  return (
    <>
      {downloadPendingModalOpen ? (
        <DownloadPendingModal
          open={downloadPendingModalOpen}
          setAllMemberLoginCredentialsRawCSVText={
            setAllMemberLoginCredentialsRawCSVText
          }
          setDownloadFailureModalOpen={setDownloadFailureModalOpen}
        />
      ) : null}
      {downloadSuccessModalOpen && rawCSVText && rawCSVText.length > 0 ? (
        <DownloadSuccessModal
          open={downloadSuccessModalOpen}
          handleClose={handleClose}
          rawCSVText={rawCSVText}
          numOfMembersNotLoggedInYet={numOfMembersNotLoggedInYet}
        />
      ) : null}
      <DownloadFailureModal
        open={downloadFailureModalOpen}
        handleClose={handleClose}
      />
      <Modal
        className={classes.modal}
        style={{
          ...(downloadFailureModalOpen ||
          downloadSuccessModalOpen ||
          downloadPendingModalOpen
            ? { display: "none" }
            : {}),
        }}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h1 style={{ fontWeight: "bold" }}>
            {
              enumStrings.chamberAdminListAllChambersText
                .downloadAllMemberCredentials.initialModal.title
            }
          </h1>
          <div
            style={{
              margin: "auto",
              overflow: "scroll",
              paddingLeft: "20px",
              paddingRight: "20px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "scroll",
              }}
            >
              {allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliData
                ?.getChamber
                ?.allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli ? (
                <span>
                  <h3 style={{ margin: 0, textAlign: "left" }}>
                    You previously downloaded all member login credentials on:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {getLocalTimestamp(
                        allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilliData
                          ?.getChamber
                          ?.allMemberLoginCredentialSuccessfulDownloadTimestampUtcMilli ||
                          0,
                        true
                      )}
                    </span>
                  </h3>
                  <br />
                </span>
              ) : null}
              <span>
                <h3 style={{ textAlign: "left", margin: 0 }}>
                  Click Download to download a CSV file containing Member
                  Dashboard login Credentials for any of your members who have{" "}
                  <span style={{ fontWeight: "bold" }}>not yet logged in</span>{" "}
                  to their Member Dashboard and have an active membership
                  status. The spreadsheet will list each member's company name,
                  contact name, account email, and account temporary password.
                  Your chamber currently has{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {numOfMembersNotLoggedInYet}
                  </span>{" "}
                  members who have not yet logged in to their Member Dashboard
                  and have an active membership status. You may want to follow
                  up with your members to ensure you have the correct contact
                  name.
                  <br />
                  <br />
                  You will be able to use the CSV file to send Member Dashboard
                  login credentials to your members using a mass emailing system
                  such as Mailchimp or Constant Contact.
                  <br />
                  <br />
                  Downloading may take up to 5 minutes.
                </h3>
              </span>
              <br />
              <span
                style={{
                  textAlign: "left",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Please Note:</span> After
                clicking Download, new Member Dashboard account temporary
                passwords will be generated for members who have not yet logged
                in to their Member Dashboard and have an active membership
                status, and any temporary passwords created in the past will no
                longer be valid.
              </span>
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className={classes.yesButton}
                    onClick={() => {
                      setDownloadPendingModalOpen(true);
                    }}
                    variant="contained"
                  >
                    Download
                  </Button>
                  {/* <span
                    onClick={() => {
                      if (!sampleMemberLoginCredentialsLoading) {
                        downloadSampleMemberLoginCredentialsData();
                      }
                    }}
                    style={{
                      textDecoration: "underline",
                      cursor: sampleMemberLoginCredentialsLoading
                        ? "progress"
                        : "pointer",
                      marginTop: "-4px",
                    }}
                  >
                    Click here to download sample
                  </span> */}
                </div>
                <Button
                  className={classes.okButton}
                  onClick={handleClose}
                  variant="contained"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadAllMemberLoginCredentialsModal;

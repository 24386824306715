import React, { ReactElement, useMemo, useState } from "react";
import { ThemeProvider, Button, TextField, Grid } from "@material-ui/core";
import theme from "../../../themes";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../images/Gold_Logo.png";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { commonCognitoPasswordRegex } from "../../common/utilities/commonPasswordRegex";
import PasswordField from "../../common/PasswordField";

interface ResetPasswordInput {
  code: string;
  newPassword: string;
  confirmPassword: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  loginForm: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  img: {
    width: "250px",
    display: "block",
    margin: "auto",
    paddingTop: "9.2px",
  },
  heading: {},
  formFields: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "500px",
    height: "500px",
    background: "white",
    padding: "29px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    margin: "auto",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "22px",
    paddingBottom: "9px",
  },
  textField: {
    backgroundColor: "white",
  },
  textFieldLabel: {
    fontSize: "18px",
  },
  formBody: {
    width: "90%",
    maxWidth: "585px",
  },
  invalidError: {
    textAlign: "center",
    color: "red",
  },
  icon: {
    margin: "auto",
  },
}));
const ResetPassword = ({ email }: { email: string }): ReactElement => {
  const classes = useStyles();
  const initialValues: ResetPasswordInput = {
    code: "",
    newPassword: "",
    confirmPassword: "",
  };
  const validationSchema = yup.object({
    code: yup.string().required("code is required"),
    newPassword: yup
      .string()
      .required("Please Enter your password")
      .matches(
        commonCognitoPasswordRegex,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });
  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState("");
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [stage, setStage] = useState(1);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input: ResetPasswordInput) => {
      setLoader(true);
      try {
        try {
          // Collect confirmation code and new password, then
          Auth.forgotPasswordSubmit(email, input.code, input.newPassword)
            .then((data) => {
              setStage(2);
            })
            .catch((err) => {
              setInvalidError(true);
              if (err.name === "LimitExceededException") {
                setCustomError(
                  "Attempt limit exceeded, please try after some time."
                );
              }
              if ((err.name = "UserNotFoundException")) {
                setCustomError(
                  "Verification code is incorrect please try again."
                );
              }
              setLoader(false);
              setButtonDisabled(false);
            });
        } catch (error) {}

        setButtonDisabled(true);
      } catch (error) {
        setInvalidError(true);
        if (error?.code === "NetworkError") {
          setCustomError("Please connect to the internet");
        }
        setLoader(false);
        setButtonDisabled(false);
      }
    },
  });

  const enumStringEmail = useMemo(() => {
    let emailPart1 = email.split("@")[0].split("+")[0];
    let emailPart2 = email.split("@")[1];
    return `${emailPart1}@${emailPart2}`;
  }, [email]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        {stage === 1 && (
          <form
            style={{
              height: "700px",
            }}
            onSubmit={formik.handleSubmit}
            className={classes.formFields}
          >
            <img
              src={Logo}
              width="180px"
              height="180px"
              className={classes.icon}
              alt="capIcon"
            />
            <div style={{ marginTop: "20px" }}>
              <h3>
                {enumStrings.resetPassword.pleaseVerifyEmailText(
                  enumStringEmail
                )}
              </h3>
            </div>{" "}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                style={{
                  marginTop: "10px",
                }}
                InputProps={{
                  className: classes.textField,
                }}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                fullWidth
                id="code"
                name="code"
                label="Verification Code *"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
              />
              <PasswordField
                id="newPassword"
                fieldName="newPassword"
                label="New Password *"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={formik.errors.newPassword}
                touched={formik.touched.newPassword}
                hideText
                showVisibilityEyeball
              />
              <PasswordField
                id="confirmPassword"
                fieldName="confirmPassword"
                label="Confirm Password *"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.errors.confirmPassword}
                touched={formik.touched.confirmPassword}
                hideText
                showVisibilityEyeball
              />
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                size="large"
                color="primary"
                variant="contained"
                disabled={buttonDisabled || loader}
              >
                {enumStrings.resetPassword.changePasswordText}
              </Button>
            </div>
            {invalidError ? (
              <div className={classes.invalidError}>{customError}</div>
            ) : null}{" "}
          </form>
        )}
        {stage === 2 && (
          <div
            style={{
              height: "450px",
            }}
            className={classes.formFields}
          >
            <img
              src={Logo}
              width="132px"
              height="132px"
              className={classes.icon}
              alt="capIcon"
              style={{
                marginRight: "auto",
                marginLeft: "auto",
                marginBottom: "35px",
              }}
            />
            <h3 style={{ textAlign: "center" }}>
              {enumStrings.resetPassword.resetPasswordSuccessText}
            </h3>
            <div className={classes.buttonContainer}>
              <Button size="large" color="primary" variant="contained">
                <Link to={RedirectUri.login} style={{ color: "white" }}>
                  {enumStrings.resetPassword.returnToLoginText}
                </Link>
              </Button>
            </div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ResetPassword;

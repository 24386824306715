import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { ChamberMemberReturn, UserType } from "../../graphql/types";
import { RedirectUri } from "../enumStrings";
import { useHistory } from "react-router-dom";
import { TextField, makeStyles, Button, Chip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../themes";
import LogoImage from "./utilities/LogoImage";
import useGetChamberFromUserIdInAuth from "./Hooks/useGetChamberFromUserIdInAuth";
import SendLoginInformationModal from "./SendLoginInformationModal";
import { getLocalTimestamp } from "../pages/Root/RootAdmin";

type OmittedRedeemSettingsChamberMember = ChamberMemberReturn & {
  numberOfPerksOffered: number;
};
type AllChamberMemberDataType = OmittedRedeemSettingsChamberMember[];
type TableChamberMemberDataType = OmittedRedeemSettingsChamberMember & {
  name: string;
  numberOfPerksOffered: number;
};
type AllTableChamberMemberDataType = TableChamberMemberDataType[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableChamberMemberDataType,
  comparator: (a: any, b: any) => number
): AllTableChamberMemberDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
}

interface FullPageChamberMemberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageChamberMemberTableHead(
  props: FullPageChamberMemberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold", fontSize: "20px" }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// @ts-ignore
const useStyles = makeStyles((themes: any) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    zIndex: 9,
  },
  searchBar: {
    zIndex: 9,
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
  createPerkText: {
    fontWeight: "bold !important",
    color: "#E1A731 !important",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const getHeadCells = (): HeadCell[] => {
  return [
    {
      id: "memberLogo",
      label: "",
    },
    {
      id: "name",
      label: "Member Name",
    },
    // {
    //   id: "createdAtUtcMilli",
    //   label: "Member Creation Date",
    // },
    {
      id: "numberOfPerksOffered",
      label: "# of Perks Offered",
      alignCenter: true,
    },
    {
      id: "numberOfUsers",
      label: "# of App Users",
      alignCenter: true,
    },

    {
      id: "hasSignedUp",
      label: "Logged in to\nMember Dashboard",
      alignCenter: true,
    },
  ];
};

const LoggedInToMemberDashboardTableCell = ({
  chamberMemberInfo,
  redirectToMemberProfile,
  isSandbox,
}: {
  chamberMemberInfo: TableChamberMemberDataType;
  redirectToMemberProfile: () => void;
  isSandbox: boolean | null | undefined;
}) => {
  const [showSendLoginInfoModal, setShowLoginInfoModal] = React.useState(false);

  const openShowSendLoginInfoModal = React.useCallback(() => {
    setShowLoginInfoModal(true);
  }, []);
  const closeShowSendLoginInfoModal = React.useCallback(() => {
    setShowLoginInfoModal(false);
  }, []);

  // if sandbox chamber, just to show the signed in case visual... for 44% of the time assign chamberMemberInfo.hasSignedUp to true
  if (isSandbox) {
    chamberMemberInfo.hasSignedUp = Math.random() < 0.44;
  }

  return (
    <>
      {showSendLoginInfoModal ? (
        <SendLoginInformationModal
          isSuspended={chamberMemberInfo.isSuspended ? true : false}
          handleClose={closeShowSendLoginInfoModal}
          open={showSendLoginInfoModal}
          memberAdminPortalEmail={
            chamberMemberInfo.adminConsoleLoginEmail || ""
          }
          memberCompanyName={chamberMemberInfo.name}
          redirectToMemberProfile={redirectToMemberProfile}
          chamberMemberId={chamberMemberInfo.id}
          type="memberTable"
          credentialsSentAtUtcMilli={
            chamberMemberInfo?.credentialsSentAtUtcMilli
          }
        />
      ) : null}
      <TableCell align="center" style={{ fontSize: "16px", cursor: "default" }}>
        {chamberMemberInfo.hasSignedUp ? (
          <Chip
            label="Has logged in"
            variant="outlined"
            size="small"
            style={{
              color: "#34694F",
              backgroundColor: "#DCEEDC",
              borderColor: "#34694F",
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Chip
              label="Never logged in"
              variant="outlined"
              size="small"
              style={{
                color: "#AF2F2E",
                backgroundColor: "#FFE2DE",
                borderColor: "#AF2F2E",
              }}
            />
            {chamberMemberInfo.isSuspended ? (
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 13,
                  maxWidth: 150,
                  marginTop: 5,
                }}
              >
                Set membership status to active to send login credentials.
              </span>
            ) : (
              <>
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={openShowSendLoginInfoModal}
                  style={{
                    backgroundColor: "#E1A731",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    marginTop: "10px",
                    whiteSpace: "pre-wrap",
                    width: "165px",
                  }}
                >
                  {chamberMemberInfo?.credentialsSentAtUtcMilli
                    ? "Resend Login Credentials"
                    : "Send Login Credentials"}
                </Button>
                {chamberMemberInfo?.credentialsSentAtUtcMilli ? (
                  <span
                    style={{
                      color: "grey",
                      marginTop: "5px",
                      fontSize: "12.5px",
                      textAlign: "center",
                    }}
                  >
                    Last sent:{" "}
                    {getLocalTimestamp(
                      chamberMemberInfo?.credentialsSentAtUtcMilli || 0,
                      true
                    )}
                  </span>
                ) : null}
              </>
            )}
          </div>
        )}
      </TableCell>
    </>
  );
};

export default function FullPageChamberMemberTable({
  userType,
  allChamberMemberData,
}: {
  userType: UserType;
  allChamberMemberData: AllChamberMemberDataType;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("name");
  const history = useHistory();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = React.useCallback(
    (chamberMemberInfo: TableChamberMemberDataType) => {
      if (userType === UserType.ChamberAdmin) {
        history.push(RedirectUri.updateAMemberChamberAdmin, {
          chamberMemberId: chamberMemberInfo.id,
        });
      }
    },
    [history, userType]
  );

  const [searchInput, setSearchInput] = React.useState("");
  const handleChange = (e: any) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  const userIdInAuthChamberInfo = useGetChamberFromUserIdInAuth();

  const totalNumberOfMemberAppUser = React.useMemo(() => {
    let users = 0;

    for (const chamberMember of allChamberMemberData) {
      users += chamberMember.numberOfUsers || 0;
    }

    return userIdInAuthChamberInfo?.isSandbox ? 1322 : users;
  }, [allChamberMemberData, userIdInAuthChamberInfo?.isSandbox]);

  const allTableChamberMemberData: AllTableChamberMemberDataType =
    React.useMemo(() => {
      if (!allChamberMemberData) {
        return [];
      }

      const allChamberMembersArr = allChamberMemberData.map(
        (chamberMemberData) => {
          return {
            ...chamberMemberData,
            numberOfUsers: chamberMemberData.numberOfUsers || 0,
          };
        }
      );

      if (searchInput.length > 0) {
        return allChamberMembersArr.filter((i) => {
          return i.name.toLowerCase().match(searchInput.toLowerCase());
        });
      }

      return allChamberMembersArr;
    }, [allChamberMemberData, searchInput]);

  const visibleRows = React.useMemo(
    () => stableSort(allTableChamberMemberData, getComparator(order, orderBy)),
    [allTableChamberMemberData, order, orderBy]
  );

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div className={classes.searchContainer}>
        <TextField
          style={{
            borderBottom: "none",
            border: "1px solid black",
          }}
          type="text"
          placeholder="Search by Member Company Name"
          onChange={handleChange}
          value={searchInput}
          className={classes.searchBar}
          InputProps={{
            disableUnderline: true,
            startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
          }}
        />
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageChamberMemberTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={getHeadCells()}
            />
            <TableBody>
              {visibleRows.map((chamberMemberInfo) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={chamberMemberInfo.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                      onClick={() => handleClick(chamberMemberInfo)}
                    >
                      <LogoImage
                        logoSource={chamberMemberInfo.logo}
                        size="large"
                        squared
                        key={chamberMemberInfo.id}
                        chamberName={chamberMemberInfo.name}
                      />
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                      onClick={() => handleClick(chamberMemberInfo)}
                    >
                      {chamberMemberInfo.name}
                    </TableCell>
                    {/* <TableCell style={{ fontSize: "16px" }}>
                      {getLocalTimestamp(
                        chamberMemberInfo.createdAtUtcMilli || 0,
                        true
                      )}
                    </TableCell> */}
                    <TableCell
                      align="center"
                      style={{ fontSize: "16px" }}
                      onClick={() => handleClick(chamberMemberInfo)}
                    >
                      {/* show random number between 1 and 5 for demonstration purposes for sandbay chamber */}
                      {userIdInAuthChamberInfo?.isSandbox
                        ? Math.floor(Math.random() * 5) + 1
                        : chamberMemberInfo.numberOfPerksOffered}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "16px" }}
                      onClick={() => handleClick(chamberMemberInfo)}
                    >
                      {/* show random number between 50 and 150 for demonstration purposes for sandbay chamber */}
                      {userIdInAuthChamberInfo?.isSandbox
                        ? Math.floor(Math.random() * 150) + 50
                        : chamberMemberInfo.numberOfUsers}
                    </TableCell>
                    <LoggedInToMemberDashboardTableCell
                      chamberMemberInfo={chamberMemberInfo}
                      redirectToMemberProfile={() =>
                        handleClick(chamberMemberInfo)
                      }
                      isSandbox={userIdInAuthChamberInfo?.isSandbox}
                    />
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={5}>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "17px",
                    }}
                  >
                    <span style={{ fontWeight: "bold", marginLeft: "20px" }}>
                      Total app users who selected a member as an employer
                    </span>{" "}
                    {totalNumberOfMemberAppUser}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

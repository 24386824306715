import React from "react";
import { Box, Chip, makeStyles, OutlinedInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import theme from "../../../themes";
import CancelIcon from "@material-ui/icons/Cancel";
import { ChamberMemberCategory } from "../../../graphql/types";
import Loader from "../Loader/Loader";
import enumStrings from "../../enumStrings";
import { useGetCategoryIdToHumanReadableName } from "../Hooks/useGetCategoryIdToHumanReadableName";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "black",
    },

    "&$focused $notchedOutline": {
      borderColor: "#E1A731",
    },
  },
  focused: {},
  notchedOutline: {},
}));

const useSelectStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "15px !important",
  },
}));

export const useStyles = makeStyles({
  touchableRoot: {
    marginBottom: 20,
  },
  select: {
    borderRadius: "15px",
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent !important",
    },
    "&:before": {
      borderColor: "#E1A731",
    },
    "&:after": {
      borderColor: "#E1A731",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingRight: 2,
    paddingLeft: 2,
  },
  categoryButtonRoot: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: 115,
    width: 115,
    borderRadius: 20,
    backgroundColor: "white",
    elevation: 2,
    padding: 3,
  },
  iconRoot: {
    height: 50,
    width: 50,
  },
  iconImage: {
    flex: 1,
    width: "auto",
    height: "auto",
    resizeMode: "contain",
  },
  categoryText: { textAlign: "center" },
  formControl: {
    backgroundColor: "#F2F2F6",
    borderRadius: "15px",
    marginTop: theme.spacing(7),
    width: 431,
    [theme.breakpoints.down("xs")]: {
      width: "264px",
    },
  },
  inputTitle: {
    paddingLeft: "20px",
    color: "#6F6F70",
    "&.Mui-focused": {
      color: "#E1A731",
    },
    top: "-6px",
  },
});

const Categories = ({
  selectedCategories,
  handleCategoriesChange,
  disableCategories,
  handleDelete,
}: {
  selectedCategories: any;
  handleCategoriesChange: any;
  disableCategories: boolean;
  handleDelete: any;
}) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const selectClasses = useSelectStyles();

  const { categoryIdToHumanReadableName, allCategories } =
    useGetCategoryIdToHumanReadableName();

  if (!categoryIdToHumanReadableName || !allCategories) {
    return <Loader />;
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel
          id="demo-multiple-chip-label"
          className={classes.inputTitle}
        >
          {enumStrings.categoriesText.categoriesTitle}
        </InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          classes={selectClasses}
          className={classes.select}
          value={selectedCategories}
          // @ts-ignore
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
                width: 250,
                marginTop: 75,
              },
            },
            variant: "menu",
            getContentAnchorEl: null,
          }}
          onChange={handleCategoriesChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label={enumStrings.categoriesText.categoriesTitle}
              classes={outlinedInputClasses}
            />
          }
          renderValue={(selected: any) => (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {selected.map((value: ChamberMemberCategory) => {
                return (
                  <Chip
                    key={value}
                    label={categoryIdToHumanReadableName[value]}
                    style={{ margin: "3px" }}
                    clickable
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event: any) => event.stopPropagation()}
                      />
                    }
                    onDelete={() => handleDelete(value)}
                  />
                );
              })}
            </Box>
          )}
        >
          {(allCategories.slice() || [{ id: "a", name: "a" }])
            .sort(function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((category) => {
              return (
                <MenuItem
                  disabled={disableCategories}
                  key={category.id}
                  value={category.id}
                >
                  {category.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default Categories;

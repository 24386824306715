import React, { useMemo } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: "100%",
      borderRadius: "5px",
      textAlign: "center",
      maxHeight: "680px",
      width: "740px",
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(4),
      position: "relative",
    },
    yesButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
    okButton: {
      margin: "10px",
      marginTop: "20px",
      width: "230px",
      backgroundColor: "#37474f",
      color: "#e9e9e9",
      fontWeight: "bold",
      height: "40px",
    },
  })
);

const ChamberMemberMemberProfileAndPerksBannersAgreementModal = ({
  open,
  handleClose,
  handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement,
  chamberName,
  isCategoryBanner = false,
}: {
  open: boolean;
  handleClose: () => void;
  handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement: () => void;
  chamberName: string;
  isCategoryBanner?: boolean;
}) => {
  const classes = useStyles();

  const chamberOrBoardText = useMemo(() => {
    if (chamberName.toLowerCase().includes("chamber")) {
      return "Chamber of Commerce";
    }
    return "Board of Trade";
  }, [chamberName]);

  return (
    <Modal className={classes.modal} open={open}>
      <div
        className={classes.paper}
        style={{ justifyContent: "space-between" }}
      >
        <h2>Accept this disclaimer to upload content</h2>
        <div
          style={{
            display: "flex",
            flex: 1,
            textAlign: "left",
            paddingRight: "25px",
            paddingLeft: "25px",
            paddingTop: "25px",
            backgroundColor: "white",
            borderRadius: "15px",
            overflow: "scroll",
          }}
        >
          <div className="container">
            <h1>
              Advertising Disclaimer for{" "}
              {isCategoryBanner
                ? "Category Advertising Banner"
                : "Member Profile & Perks Banners"}
            </h1>
            <div className="agreement">
              <p>
                By clicking ‘I Accept,’ the Member agrees to the advertising
                guidelines set forth herein.
              </p>

              <h2>1. Content Submission</h2>
              <p>
                The Member agrees to submit advertising content (‘Content’) that
                is appropriate, professional, and in alignment with the
                {chamberOrBoardText}'s advertising guidelines. The content must
                be uploaded in the required format.
              </p>

              <h2>2. Approval Process</h2>
              <p>
                All banners uploaded by the Member is subject to the
                {chamberOrBoardText}’s review and approval. The
                {chamberOrBoardText} reserves the right to reject any banners
                that it deems inappropriate, offensive, or not in line with the
                {chamberOrBoardText}’s advertising guidelines.
              </p>

              <h2>3. Responsibilities of the Member</h2>
              <p>
                The Member is responsible for ensuring that the Banners comply
                with all applicable laws and regulations. The Member shall
                indemnify and hold harmless the {chamberOrBoardText} from any
                claims, damages, or liabilities arising from the Content of the
                banners.
              </p>

              <h2>4. Modification and Removal</h2>
              <p>
                The {chamberOrBoardText} reserves the right to request a banner
                be edited or has the rights to remove any banners at its sole
                discretion without prior notice to the Member.
              </p>

              <h2>5. Advertising Disclaimer</h2>
              <p>
                By clicking ‘I Accept,’ the Member acknowledges that they have
                read, understood, and agreed to the above advertising
                disclaimer.
              </p>

              <p>
                This Disclaimer applies for all current and future banners
                effective as of the date the Member clicks ‘I Accept.
              </p>
            </div>
          </div>
        </div>
        <br />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Button
            className={classes.yesButton}
            onClick={() => {
              handleAcceptanceOfChamberMemberMemberProfileAndPerksBannersAgreement();
              handleClose();
            }}
            variant="contained"
          >
            I Accept
          </Button>
          <Button
            className={classes.okButton}
            onClick={handleClose}
            variant="contained"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChamberMemberMemberProfileAndPerksBannersAgreementModal;

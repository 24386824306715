import React, { ReactElement, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { useGetRootAdminStatsQuery, UserType } from "../../../graphql/types";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
import Loader from "../../common/Loader/Loader";
import ViewStatsBoxes from "../../common/ViewStatsBoxes";
import { DateTime } from "luxon";
import RootAdminStatTable from "../../common/RootAdminStatTable";
import CommonLineChart from "../../common/utilities/CommonLineChart";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
}));

export const getLocalTimestamp = (
  utcMilliseconds: number,
  removeHours?: boolean
) => {
  if (utcMilliseconds === 0) {
    return "";
  }

  // Create a Luxon DateTime object from UTC milliseconds
  const utcDateTime = DateTime.fromMillis(utcMilliseconds, { zone: "utc" });

  // Convert to the local time zone
  const localDateTime = utcDateTime.toLocal();

  // Format the local date and time
  const formattedDate = localDateTime.toFormat(
    removeHours ? "LLL dd, yyyy" : "LLL dd, yyyy, hh:mm a"
  );

  return formattedDate;
};

const RootAdmin = (): ReactElement => {
  const classes = useStyles();
  const rootAdminObj =
    enumStrings.groups[UserType.RootAdmin][RedirectUri.rootAdmin];

  const { data: rootAdminStatsData, loading } = useGetRootAdminStatsQuery({
    fetchPolicy: "network-only",
  });

  const [rootAdminStatsLoading, setRootAdminStatsLoading] = useState(true);

  useEffect(() => {
    if (rootAdminStatsData?.getRootAdminStats && !loading) {
      setRootAdminStatsLoading(false);
    }
  }, [loading, rootAdminStatsData?.getRootAdminStats]);

  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </div>
      </div>

      <ViewStatsBoxes
        boxes={[
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.totalUsersSignedUp,
            boxContent: (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {rootAdminStatsLoading ? (
                  <Loader />
                ) : (
                  <>
                    {
                      rootAdminStatsData?.getRootAdminStats
                        ?.totalNumberOfAppUsers
                    }
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginTop: "5px",
                        padding: "30px",
                        paddingTop: "0",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          With an employer selected:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithAnEmployer
                            }{" "}
                            (
                            {Math.round(
                              ((rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithAnEmployer ||
                                0) /
                                (rootAdminStatsData?.getRootAdminStats
                                  ?.totalNumberOfAppUsers || 0)) *
                                100
                            ).toFixed(0)}
                            %)
                          </span>
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          Without an employer selected:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithoutAnEmployer
                            }{" "}
                            (
                            {Math.round(
                              ((rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersSignedUpWithoutAnEmployer ||
                                0) /
                                (rootAdminStatsData?.getRootAdminStats
                                  ?.totalNumberOfAppUsers || 0)) *
                                100
                            ).toFixed(0)}
                            %)
                          </span>
                        </span>
                      </div>
                      <br />
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          With New Perk Notification Enabled:{" "}
                          <span
                            style={{
                              color: "#E1A731",
                            }}
                          >
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersWithNewPerkNotificationEnabled
                            }{" "}
                            (
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalPercentageOfAppUsersWithNewPerkNotificationEnabled
                            }
                            %)
                          </span>
                        </span>
                      </div>

                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <span style={{ color: "black" }}>
                          With Nearby Perk Notification Enabled:{" "}
                          <span style={{ color: "#E1A731" }}>
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalNumberOfAppUsersWithNearbyPerkNotificationEnabled
                            }{" "}
                            (
                            {
                              rootAdminStatsData?.getRootAdminStats
                                ?.totalPercentageOfAppUsersWithNearbyPerkNotificationEnabled
                            }
                            %)
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ),
          },
          {
            boxTitle: "New Chamber Perks App™ User Accounts Per Month",
            boxContent: (
              <div style={{ width: "100%" }}>
                {!rootAdminStatsData?.getRootAdminStats?.timeseriesAppUsersData
                  ?.monthly ? (
                  <Loader />
                ) : (
                  <CommonLineChart
                    series={[
                      {
                        id: "appUsers",
                        name: "New App User Accounts",
                        data: rootAdminStatsData?.getRootAdminStats?.timeseriesAppUsersData?.monthly?.map(
                          (point) => ({
                            timestamp: point.monthAndYear,
                            value: point.numberOfAppUsers,
                          })
                        ),
                        color: "#E1A731", // Using your theme color
                      },
                    ]}
                    isMonthly={true}
                    showLegend={true}
                    enableSeriesSelector={false}
                    height={300}
                  />
                )}
              </div>
            ),
          },
          {
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.membersInCPAText,
            boxContent: (
              <div style={{ width: "100%" }}>
                {rootAdminStatsLoading ? (
                  <Loader />
                ) : (
                  rootAdminStatsData?.getRootAdminStats?.totalChambers
                )}
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "5px",
                    padding: "30px",
                    paddingTop: "0",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {
                        enumStrings.rootAdminText.viewStatBoxes
                          .totalMemberCompanies
                      }
                      :{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalChamberMembers
                        }
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Number of Suspended Member Companies:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalSuspendedChamberMembers
                        }
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Companies Offering Live Perks:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.numberOfChamberMembersOfferingLiveDiscounts
                        }{" "}
                        (
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.percentOfChamberMembersOfferingLiveDiscounts
                        }
                        %)
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "35px",
                      paddingBottom: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                      }}
                    >
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.newestChamberMembers.length
                      }{" "}
                      Newest Members
                    </span>
                    <RootAdminStatTable
                      headCells={[
                        {
                          id: "name",
                          label: "Member Name",
                        },
                        {
                          id: "chamberName",
                          label: "Chamber Name",
                        },
                        {
                          id: "createdAtUtcMilli",
                          label: "Creation Date",
                          convertUtcToTimestamp: true,
                          defaultSort: true,
                          sortOrder: "desc",
                        },
                      ]}
                      loading={rootAdminStatsLoading}
                      allChartData={
                        rootAdminStatsData?.getRootAdminStats
                          ?.newestChamberMembers
                      }
                      overflowHorizontally
                      chartSize="medium"
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.totalPerks,
            boxContent: (
              <div style={{ width: "100%" }}>
                {
                  rootAdminStatsData?.getRootAdminStats
                    ?.totalNumberOfLiveDiscounts
                }
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginTop: "5px",
                    padding: "30px",
                    paddingTop: "0",
                    textAlign: "left",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Perk Views:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalDiscountViews
                        }
                      </span>
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      Total Perk Redemptions:{" "}
                      <span
                        style={{
                          color: "#E1A731",
                        }}
                      >
                        {
                          rootAdminStatsData?.getRootAdminStats
                            ?.totalDiscountRedemptions
                        }
                      </span>
                    </span>
                  </div>

                  <br />
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span style={{ color: "black" }}>
                      {
                        enumStrings.rootAdminChamberText
                          .numberOfLivePerksOfferedTo
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        All Members:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveAllMemberDiscounts
                      }
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        Local Members Only:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveLocalMemberOnlyDiscounts
                      }
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        Anyone:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveGeneralPublicDiscounts
                      }
                    </span>
                    <span
                      style={{
                        margin: "5px",
                        fontSize: "16px",
                        flex: "40%",
                      }}
                    >
                      <span
                        style={{
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        Member Company Employees Only:
                      </span>{" "}
                      {
                        rootAdminStatsData?.getRootAdminStats
                          ?.totalNumberOfLiveCompanyEmployeesOnlyDiscounts
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "35px",
                      paddingBottom: "0px",
                    }}
                  >
                    <span
                      style={{
                        color: "black",
                      }}
                    >
                      {
                        rootAdminStatsData?.getRootAdminStats?.newestDiscounts
                          .length
                      }{" "}
                      Newest Perks
                    </span>
                    <RootAdminStatTable
                      headCells={[
                        {
                          id: "title",
                          label: "Title",
                        },
                        {
                          id: "offeredTo",
                          label: "Redeemable By",
                        },
                        {
                          id: "chamberName",
                          label: "Chamber Name",
                        },
                        {
                          id: "views",
                          label: "Views",
                        },
                        {
                          id: "numberOfRedemptions",
                          label: "Redemptions",
                        },
                        {
                          id: "createdAtUtcMilli",
                          label: "Creation Date",
                          convertUtcToTimestamp: true,
                          defaultSort: true,
                          sortOrder: "desc",
                        },
                        {
                          id: "deletedAtUtcMilli",
                          label: "Deletion Date",
                          convertUtcToTimestamp: true,
                        },
                      ]}
                      loading={rootAdminStatsLoading}
                      allChartData={
                        rootAdminStatsData?.getRootAdminStats?.newestDiscounts
                      }
                      overflowHorizontally
                    />
                  </div>
                </div>
              </div>
            ),
          },
          {
            fullWidth: true,
            boxTitle: enumStrings.rootAdminText.viewStatBoxes.chamberDetails,
            boxContent: (
              <RootAdminStatTable
                overflowHorizontally
                chartSize="large"
                headCells={[
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.chamberBoardLead
                      .split("/")
                      .join("/ "),
                    sticky: true,
                  },
                  {
                    id: "subscriptionUTCMilli",
                    label: "Subscription Start Date",
                    utcMilliTimestampObjKey: "subscriptionDate",
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                  {
                    id: "numberOfAppUsers",
                    label: "App users who've selected this chamber",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfAppUsersSignedUpWithAnEmployer",
                    label:
                      "App users who've selected an employer from this chamber",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfAppUsersSignedUpWithoutAnEmployer",
                    label:
                      "App users who've selected this chamber and not any chamber's employer",
                  },
                  {
                    id: "numberOfAppUsersWithNewPerkNotificationEnabled",
                    label: "App Users With New Perk Notification Enabled",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfAppUsersWithNewPerkNotificationEnabled",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfAppUsersWithNearbyPerkNotificationEnabled",
                    label: "App Users With Nearby Perk Notification Enabled",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfAppUsersWithNearbyPerkNotificationEnabled",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfLiveDiscounts",
                    label: "Live Perks",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfDiscountViews",
                    label: "Live Perk Views",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberOfDiscountRedemptions",
                    label: "Live Perk Redemptions",
                  },
                  {
                    id: "numberOfMembersFromInitialSubscriptionAgreement",
                    label: "Initial Member Companies",
                  },
                  {
                    id: "numberOfChamberMembersLiveOnApp",
                    label: "Active Member Companies",
                    disableRightBorder: true,
                  },
                  {
                    id: "numberChangeInNumberOfChamberMembersBetweenNowAndInitial",
                    label: "#",
                    disableRightBorder: true,
                    indicateDeltaFor:
                      "numberChangeInNumberOfChamberMembersBetweenNowAndInitial",
                  },
                  {
                    id: "percentageChangeInNumberOfChamberMembersBetweenNowAndInitial",
                    label: "%",
                    isPercent: true,
                    indicateDeltaFor:
                      "percentageChangeInNumberOfChamberMembersBetweenNowAndInitial",
                  },
                  {
                    id: "numberOfSuspendedChamberMembers",
                    label: "Suspended Member Companies",
                  },
                  {
                    id: "numberOfChamberMembersOfferingLiveDiscounts",
                    label: "Companies Offering Live Perks",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfChamberMembersOfferingLiveDiscounts",
                    label: "%",
                    isPercent: true,
                  },
                  {
                    id: "numberOfChamberMembersLoggedInToAdminPortal",
                    label: "Companies Logged In To Dashboard",
                    disableRightBorder: true,
                  },
                  {
                    id: "percentageOfChamberMembersLoggedInToAdminPortal",
                    label: "%",
                    isPercent: true,
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats?.chambersSortedByNewest
                }
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.perkPerMemberCategory,
            boxSubTitle:
              enumStrings.rootAdminText.viewStatBoxes
                .perkPerMemberCategorySubTitle,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "categoryName",
                    label: enumStrings.rootAdminText.viewStatBoxes.categoryName,
                    splitOn: "/",
                  },
                  {
                    id: "numberOfDiscounts",
                    label:
                      enumStrings.rootAdminText.viewStatBoxes.numberOfPerks,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                  {
                    id: "percentageVersusOtherCategories",
                    label:
                      enumStrings.rootAdminText.viewStatBoxes.percentOfPerks,
                    isPercent: true,
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats
                    ?.discountsPerCategoryBreakdown
                }
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.chamberPerksPerCategory,
            boxSubTitle:
              enumStrings.rootAdminText.viewStatBoxes
                .perkPerMemberCategorySubTitle,
            boxContent: rootAdminStatsData?.getRootAdminStats
              ?.discountsPerCategoryBreakdown ? (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.chamberBoardLead,
                    sticky: true,
                  },
                  ...[
                    ...rootAdminStatsData?.getRootAdminStats?.discountsPerCategoryBreakdown.map(
                      ({ categoryName }) => ({
                        id: categoryName,
                        label: categoryName.split("/").join("/ "),
                        defaultValue: 0,
                      })
                    ),
                  ],
                ]}
                spreadDataIntoAllChartData={{
                  createObjFromId: "discountsPerCategoryBreakdown",
                  idType: "arrOfObj",
                  objKey: "categoryName",
                  objValue: "numberOfDiscounts",
                }}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats?.chambersSortedByNewest
                }
                overflowHorizontally
              />
            ) : (
              <></>
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.exportChamberLeads,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.chamberBoardLead,
                  },
                  {
                    id: "createdAtUtcMilli",
                    label: enumStrings.rootAdminText.creationTimeLocalTZ,
                    convertUtcToTimestamp: true,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats
                    ?.contactableChambersSortedByNewest
                }
              />
            ),
          },
          {
            boxTitle:
              enumStrings.rootAdminText.viewStatBoxes.exportChamberMemberLeads,
            boxContent: (
              <RootAdminStatTable
                headCells={[
                  {
                    id: "chamberName",
                    label: enumStrings.rootAdminText.viewStatBoxes.chamberName,
                  },
                  {
                    id: "name",
                    label: enumStrings.rootAdminText.memberCompanyLead,
                  },
                  {
                    id: "createdAtUtcMilli",
                    label: enumStrings.rootAdminText.creationTimeLocalTZ,
                    convertUtcToTimestamp: true,
                    defaultSort: true,
                    sortOrder: "desc",
                  },
                ]}
                loading={rootAdminStatsLoading}
                allChartData={
                  rootAdminStatsData?.getRootAdminStats
                    ?.contactableCompaniesSortedByNewest
                }
              />
            ),
          },
        ]}
      />
    </span>
  );
};

export default RootAdmin;

import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import { PrizeDrawParticipantReturn } from "../../../graphql/types";

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array: any[], comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ParticipantTable({
  data,
}: {
  data: PrizeDrawParticipantReturn[];
}) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof PrizeDrawParticipantReturn>("ballotsEarned");

  const handleRequestSort = (property: keyof PrizeDrawParticipantReturn) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(data, getComparator(order, orderBy));

  return (
    <Box sx={{ maxWidth: "95%", margin: "auto" }}>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "400px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "16px" }} // Increased font size
                >
                  <TableSortLabel
                    active={orderBy === "email"}
                    direction={orderBy === "email" ? order : "asc"}
                    onClick={() => handleRequestSort("email")}
                    sx={{ fontSize: "16px" }} // Increased font size
                  >
                    User Email
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "16px" }} // Increased font size
                >
                  <TableSortLabel
                    active={orderBy === "ballotsEarned"}
                    direction={orderBy === "ballotsEarned" ? order : "asc"}
                    onClick={() => handleRequestSort("ballotsEarned")}
                    sx={{ fontSize: "16px" }} // Increased font size
                  >
                    Ballots Earned
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((user, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ fontSize: "16px" }}>
                    {" "}
                    {/* Increased font size */}
                    {user.email}
                  </TableCell>
                  <TableCell align="center" sx={{ fontSize: "16px" }}>
                    {" "}
                    {/* Increased font size */}
                    {user.ballotsEarned}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

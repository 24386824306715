import React, { useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";

interface TimeSeriesDataPoint {
  timestamp: string; // ISO string for date or 'YYYY-MM' for monthly data
  value: number;
}

interface DataSeries {
  id: string;
  name: string;
  data: TimeSeriesDataPoint[];
  color?: string;
}

interface CommonLineChartProps {
  title?: string;
  series: DataSeries[];
  isMonthly?: boolean;
  height?: number;
  showLegend?: boolean;
  enableSeriesSelector?: boolean;
  defaultSelectedSeries?: string[];
}

const formatDate = (timestamp: string, isMonthly: boolean) => {
  if (isMonthly) {
    const [year, month] = timestamp.split("-");
    const date = new Date(parseInt(year), parseInt(month) - 1);
    return date.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    });
  }
  return new Date(timestamp).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

export const CommonLineChart: React.FC<CommonLineChartProps> = ({
  title,
  series,
  isMonthly = false,
  height = 400,
  showLegend = true,
  enableSeriesSelector = false,
  defaultSelectedSeries,
}) => {
  const [selectedSeriesIds, setSelectedSeriesIds] = useState<string[]>(
    defaultSelectedSeries || series.map((s) => s.id)
  );

  // Filter series based on selection
  const filteredSeries = series
    .filter((s) => selectedSeriesIds.includes(s.id))
    .map((s) => ({
      data: s.data.map((d) => d.value),
      label: s.name,
      color: s.color,
    }));

  // Get x-axis labels from the first series (assuming all series have same timestamps)
  const xLabels =
    series[0]?.data.map((point) => formatDate(point.timestamp, isMonthly)) ||
    [];

  // Calculate average from all visible series
  const allValues = filteredSeries.flatMap((s) => s.data);
  const average = (
    allValues.length > 0
      ? allValues.reduce((sum, val) => sum + val, 0) / allValues.length
      : 0
  ).toFixed(0);

  // Create average line series
  const averageSeries = {
    data: new Array(xLabels.length).fill(average),
    label: `Average (${average})`,
    color: "#989898",
    showMark: false,
    connectNulls: true,
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          mb: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        {title && (
          <h2
            style={{
              marginBottom: "0px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {title}
          </h2>
        )}
        {enableSeriesSelector && (
          <FormControl
            sx={{
              maxWidth: 450,
              width: "100%",
            }}
            size="small"
          >
            <InputLabel
              id="series-select-label"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
                "&.Mui-focused": {
                  color: "#E1A731",
                },
              }}
            >
              Select Series
            </InputLabel>
            <Select
              labelId="series-select-label"
              id="series-select"
              multiple
              value={selectedSeriesIds}
              label="Select Series"
              sx={{
                minHeight: 50,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#E1A731",
                },
              }}
              onChange={(e) => {
                const value = e.target.value;
                setSelectedSeriesIds(
                  typeof value === "string" ? [value] : value
                );
              }}
            >
              {series.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <LineChart
        height={height}
        series={[...filteredSeries, averageSeries]}
        xAxis={[
          {
            scaleType: "point",
            data: xLabels,
          },
        ]}
        sx={{
          ".MuiLineElement-root": {
            strokeWidth: 2,
          },
          ".MuiMarkElement-root": {
            stroke: "#fff",
            scale: "0.6",
            fill: "currentColor",
          },
        }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
            padding: 0,
            hidden: !showLegend,
          },
        }}
      />
    </Box>
  );
};

export default CommonLineChart;

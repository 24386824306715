import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { UserType } from "../../../graphql/types";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import useIsChamber from "../../common/Hooks/useIsChamber";
import PerksLinkChamberList from "./ChamberList";

const useStyles = makeStyles((themes: any) => ({
  title: {
    marginLeft: "24px",
    marginRight: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  titleContent: {
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },
  content: {
    display: "flex",
    background: "#FFFFFF",
    height: "80vh",
    borderRadius: "16px",
    margin: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    marginTop: "33px",
    flexDirection: "column",
  },
}));

const PerksLink = (): ReactElement => {
  const classes = useStyles();

  const headerInfo = // @ts-ignore
    enumStrings?.groups[UserType.ChamberAdmin][RedirectUri.perksLink];

  const isChamber = useIsChamber();

  return (
    <div>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{headerInfo.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            Link your {isChamber ? "Chamber's" : "Board's"} perks with another
            Chamber or Board on the Chamber Perks App™.
            <br />
            App users will be able to view and redeem perks from all linked
            Chambers and Boards.
          </span>
          <div style={{ paddingTop: "10px" }} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
      </div>
      <div className={classes.content}>
        {false ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Loader />
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PerksLinkChamberList />
          </div>
        )}
      </div>
    </div>
  );
};

export default PerksLink;

import React, { useState } from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import {
  GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery,
  GetPerkPartnerDiscountStatsForRootAdminQuery,
  UserType,
} from "../../../graphql/types";
import { FormControl, InputLabel, Select, MenuItem, Box } from "@mui/material";

interface PerkPartnerStatsLineChartProps {
  timeseriesData:
    | GetPerkPartnerDiscountStatsForRootAdminQuery["getPerkPartnerDiscountStats"]["timeseriesData"]
    | GetPerkPartnerDiscountStatsForPerkPartnerDiscountAdminUserQuery["getPerkPartnerDiscountStats"]["timeseriesData"]
    | undefined
    | null;
  userType: UserType | "";
}

const formatMonthYear = (monthYear: string) => {
  const [year, month] = monthYear.split("-");
  const date = new Date(parseInt(year), parseInt(month) - 1);
  return date.toLocaleDateString("en-US", { month: "short", year: "numeric" });
};

export const PerkPartnerStatsLineChart: React.FC<
  PerkPartnerStatsLineChartProps
> = ({ timeseriesData, userType }) => {
  const [selectedChamberId, setSelectedChamberId] = useState<string>("all");

  if (!timeseriesData?.allChambers?.timeseriesData) {
    return null;
  }

  // Get the selected dataset based on the chamber selection
  const selectedDataset =
    selectedChamberId === "all"
      ? timeseriesData.allChambers
      : timeseriesData.chambers.find(
          (chamber) => chamber.id === selectedChamberId
        );

  if (!selectedDataset?.timeseriesData) {
    return null;
  }

  const data = selectedDataset.timeseriesData;

  // Prepare data for the chart
  const xLabels = data.map((point) => formatMonthYear(point.monthAndYear));
  const viewData = data.map((point) => point.totalViewCount);
  const openData = data.map((point) => point.totalOpenCount);

  // Only include redeem data if not a perk partner discount admin
  const series = [
    {
      data: viewData,
      label: "Views",
      color: "#2196f3",
    },
    {
      data: openData,
      label: "Opens",
      color: "#4caf50",
    },
  ];

  // Only add redeem data for non-perk partner discount admin users
  if (userType === UserType.RootAdmin) {
    const redeemData = data.map((point) => point.totalRedeemCount);
    series.push({
      data: redeemData,
      label: "Redirects",
      color: "#f44336",
    });
  }

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          mb: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <h2
          style={{
            marginBottom: "0px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          Perk Stats Over Time
        </h2>
        <FormControl
          sx={{
            maxWidth: 450,
            width: "100%",
          }}
          size="small"
        >
          <InputLabel
            id="chamber-select-label"
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              "&.Mui-focused": {
                color: "#E1A731",
              },
            }}
          >
            Select Chamber
          </InputLabel>
          <Select
            labelId="chamber-select-label"
            id="chamber-select"
            value={selectedChamberId}
            label="Select Chamber"
            sx={{
              minHeight: 50,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#E1A731",
              },
            }}
            onChange={(e) => setSelectedChamberId(e.target.value)}
          >
            <MenuItem value="all">{timeseriesData.allChambers.name}</MenuItem>
            {timeseriesData.chambers.map((chamber) => (
              <MenuItem key={chamber.id} value={chamber.id}>
                {chamber.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <LineChart
        height={400}
        series={series}
        xAxis={[
          {
            scaleType: "point",
            data: xLabels,
          },
        ]}
        sx={{
          ".MuiLineElement-root": {
            strokeWidth: 2,
          },
          ".MuiMarkElement-root": {
            stroke: "#fff",
            scale: "0.6",
            fill: "currentColor",
          },
        }}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export default PerkPartnerStatsLineChart;

import React, { ReactElement } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";

export enum ButtonConfigType {
  YesNoConfig = "yesNoConfig",
  OkConfig = "okConfig",
}

export interface YesNoButtonConfig {
  buttonType: ButtonConfigType.YesNoConfig;
  yesButtonAction: () => {};
}

export interface OkButtonConfig {
  buttonType: ButtonConfigType.OkConfig;
  okButtonAction: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    invalidError: {
      textAlign: "center",
      color: "red",
    },
    modal: {
      border: "unset",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      borderRadius: "5px",
      textAlign: "center",
      width: "500px",
      minHeight: "300px",
      maxWidth: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "#cfd8dc",
      padding: theme.spacing(2, 4, 3),
    },
    yesButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    customButton: {
      margin: "10px",
      backgroundColor: "#e1a731",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    noButton: {
      margin: "10px",
      width: "100px",
      backgroundColor: "#ff0000",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
    okButton: {
      backgroundColor: "#37474f",
      margin: "10px",
      width: "100px",
      color: "#e9e9e9",
      fontWeight: "bold",
    },
  })
);

const CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal =
  ({
    open,
    handleClose,
    handleUseSuggestedPlusAddressedAlternative,
    currentAdminConsoleLoginEmail,
    suggestedPlusAddressedEmail,
  }: {
    open: boolean;
    handleClose: () => void;
    handleUseSuggestedPlusAddressedAlternative: () => void;
    currentAdminConsoleLoginEmail: string;
    suggestedPlusAddressedEmail: string;
  }): ReactElement => {
    const classes = useStyles();

    return (
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h3
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            A dashboard account with the email username{" "}
            <span style={{ fontWeight: "bold" }}>
              {currentAdminConsoleLoginEmail}
            </span>{" "}
            already exists.
            {"\n\n"}An alternative dashboard email username that can be used to
            create an account for this member is{" "}
            <span style={{ fontWeight: "bold" }}>
              {suggestedPlusAddressedEmail}
            </span>
            {"\n"}This alternative email username uses plus addressing; all
            emails sent to this alternative email address will still go to the
            original email's inbox.
            {"\n\n"}Click{" "}
            <span style={{ fontWeight: "bold" }}>Use Alternative</span> to
            create this member using the alternative plus-addressed email
            username. Otherwise, click{" "}
            <span style={{ fontWeight: "bold" }}>Back</span> to try another
            email username.
          </h3>
          <br />
          <Button
            onClick={handleUseSuggestedPlusAddressedAlternative}
            className={classes.customButton}
            variant="contained"
          >
            Use Alternative
          </Button>
          <Button
            onClick={handleClose}
            className={classes.okButton}
            variant="contained"
          >
            Back
          </Button>
        </div>
      </Modal>
    );
  };

export default CreateChamberMemberSuggestedAlternativePlusAddressedEmailUsernameModal;

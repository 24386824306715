import React, { ReactElement, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import theme from "../../../themes";
import enumStrings, { RedirectUri } from "../../enumStrings";
import { useGetAllChambersQuery, UserType } from "../../../graphql/types";
import SearchIcon from "@material-ui/icons/Search";
import ChamberCard from "./ChamberCard";
import Loader from "../../common/Loader/Loader";
import PositionedTooltips from "../../common/utilities/HoverToolTip";
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.delete.main,
    color: theme.palette.delete.contrastText,
    toolbar: theme.mixins.toolbar,
  },
  background: {},
  content: {
    background: "#FFFFFF",
    height: "40%",
    flexGrow: 1,
    borderRadius: "16px",
    margin: "14px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    flex: "1 0 calc(46% - 10px)",
    marginTop: "25px",
    "&:nth-of-type(3)": {
      height: "40%!important",
      width: "10%",
    },
    "&:nth-of-type(4)": {
      height: "40%!important",
      backgroundColor: "#F6F6F6",
      width: "10%",
      opacity: 0,
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  },
  title: {
    marginLeft: "24px",
    fontSize: "30px",
    color: "white",
    fontWeight: "bold",
    marginBottom: "30px",
    marginTop: "15px",
    display: "flex",
  },
  contentContainer: {
    height: "100vh",
    backgroundColor: "#F6F6F6",
  },
  boxTitle: {
    fontWeight: "bold",
    fontSize: "4.3vh",
    textAlign: "center",
    marginTop: "11px",
  },
  boxValue: {
    color: "#E1A731",
    fontWeight: "bold",
    fontSize: "21vh",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    paddingBottom: "9px",
    paddingLeft: "24px",
  },
  titleContent: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("lg")]: {
      paddingTop: "30px",
    },
  },

  searchContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "69px",
    paddingTop: "29px",
  },
  searchBar: {
    position: "absolute",
    outline: "none",
    background: "#FFFFFF",
    borderRadius: "15px",
    paddingLeft: "10px",
    paddingTop: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "231px",
      height: "41px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "391px",
      height: "41px",
    },
  },
}));
const ListAllChamberAdmin = (): ReactElement => {
  const classes = useStyles();
  const [input, setInput] = useState("");

  const { data } = useGetAllChambersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      getAllSandbox: true,
    },
  });
  const handleChange = (e: any) => {
    e.preventDefault();
    setInput(e.target.value);
  };

  let chamberData = data?.getAllChambers || [];

  if (input.length > 0) {
    chamberData = (data?.getAllChambers || [])
      .filter((i) => {
        return i.name.toLowerCase().match(input.toLowerCase());
      })
      ?.slice()
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  } else if (input.length === 0) {
    chamberData =
      data?.getAllChambers
        ?.slice()
        .sort((a, b) => (a.name > b.name ? 1 : -1)) || [];
  }

  const rootAdminObj =
    // @ts-ignore
    enumStrings?.groups[UserType.RootAdmin][RedirectUri.listAllChamberAdmin];
  const updateAChamberRoute = RedirectUri.updateAChamberRoute;
  return (
    <span>
      <div className={classes.titleContent}>
        <div
          className={classes.title}
          style={{
            flexDirection: "column",
          }}
        >
          <span>{rootAdminObj.title}</span>
          <span
            style={{
              marginLeft: "1px",
              fontWeight: "normal",
              fontSize: "16px",
            }}
          >
            {rootAdminObj.description}
          </span>
          <PositionedTooltips
            //  @ts-ignore
            title={rootAdminObj?.title}
            //  @ts-ignore
            description={rootAdminObj?.toolTipDescription}
          />{" "}
        </div>
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.searchContainer}>
          <TextField
            style={{ borderBottom: "none" }}
            type="text"
            placeholder="Search"
            onChange={handleChange}
            value={input}
            className={classes.searchBar}
            InputProps={{
              disableUnderline: true,
              startAdornment: <SearchIcon style={{ color: "#AEAEAE" }} />,
            }}
          />
        </div>{" "}
        {chamberData.length !== 0 ? (
          chamberData.map((chamberInfo, index) => (
            <ChamberCard
              redirectStates={{ chamberId: chamberInfo.id }}
              chamberURL={updateAChamberRoute}
              chamberName={chamberInfo.name}
              chamberLogo={chamberInfo.logo}
              indexVal={index}
              key={index}
            />
          ))
        ) : (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
    </span>
  );
};

export default ListAllChamberAdmin;

import React, { useCallback, useEffect, useMemo } from "react";
import {
  makeStyles,
  FormControlLabel,
  Switch,
  FormHelperText,
  SwitchProps,
  styled,
  Checkbox,
} from "@material-ui/core";
import DatePicker from "react-datepicker";

import "react-toastify/dist/ReactToastify.css";

import { DiscountInput, MonthEnum } from "../../../graphql/types";
import { FormikErrors } from "formik";
import enumStrings from "../../enumStrings";
import HoverToolTip from "../utilities/HoverToolTip";

import "react-datepicker/dist/react-datepicker.css";
import { endOfDay, lastDayOfMonth, startOfDay } from "date-fns";

const useStyles = makeStyles((theme) => ({
  companyInputWithToolTipContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "30px",
  },
  toggleSwitchWithTitle: {
    display: "flex",
    margin: "auto",
    alignItems: "baseline",
    marginTop: "35px",
    justifyContent: "center",
    textAlign: "center",
  },
  dayCheckbox: {
    color: "#E1A731!important",
    alignItems: "center",
    textAlign: "center",
  },
  dateRangePicker: {
    backgroundColor: "#F2F2F6",
    borderRadius: "7px",
    border: "1px solid black",
    cursor: "pointer",
    caretColor: "transparent",
    height: "40px",
    width: "235px",
    textAlign: "left",
    paddingLeft: "16px",
    paddingTop: "0px",
    fontSize: "15px",
    "&:focus": {
      backgroundColor: "#F2F2F6",
      borderRadius: "7px",
      border: "1px solid black",
      cursor: "pointer",
      outline: "none",
    },
  },
  rootDateRangePicker: {
    margin: "auto",
    "@global": {
      ".react-datepicker__close-icon::after": {
        backgroundColor: "transparent !important",
        outline: "none",
        color: "#757575",
        fontSize: "25px",
        paddingBottom: "7px",
      },
    },
  },
}));

const ShowInAppSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  margin: "10px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,

    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
  "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#e1a731",
    border: "1px solid black",
  },
  "& .MuiSwitch-root": {
    margin: "10px",
  },
}));

export const monthsArr = Object.values(MonthEnum);

const OnlyOfferDuringCertianMonthsForm = ({
  formikSetFieldValue,
  formikValuesMonthsAvailable,
  formikValuesStartTimeUtcMilli,
  formikValuesExpiryTimeUtcMilli,
  formikErrors,
  isMonthToggleChecked,
  setMonthToggle,
}: {
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<DiscountInput>>;
  formikValuesMonthsAvailable: DiscountInput["monthsAvailable"];
  formikValuesStartTimeUtcMilli: DiscountInput["startTimeUtcMilli"];
  formikValuesExpiryTimeUtcMilli: DiscountInput["expiryTimeUtcMilli"];
  formikErrors: FormikErrors<DiscountInput>;
  isMonthToggleChecked: boolean;
  setMonthToggle: (value: boolean) => void;
}) => {
  const classes = useStyles();

  const handleMonthToggleSwitch = () => {
    if (!isMonthToggleChecked === false) {
      formikSetFieldValue("monthsAvailable", undefined);
    }
    setMonthToggle(!isMonthToggleChecked);
  };

  const handleMonthSelection = (isChecked: boolean, month: MonthEnum) => {
    const monthsInFormik = formikValuesMonthsAvailable
      ? [...formikValuesMonthsAvailable]
      : [];
    if (isChecked) {
      monthsInFormik.push(month);
    }
    if (!isChecked) {
      monthsInFormik.splice(monthsInFormik.indexOf(month), 1);
    }
    formikSetFieldValue("monthsAvailable", monthsInFormik);
  };

  const handleSetStartAndExpiryDate = useCallback(
    (update: [Date | null, Date | null]) => {
      const [startDate, expiryDate] = update;

      const newStartTimeUtcMilli =
        startDate === null ? null : startOfDay(startDate).getTime();
      const newExpiryTimeUtcMilli =
        expiryDate === null ? null : endOfDay(expiryDate).getTime();

      formikSetFieldValue("startTimeUtcMilli", newStartTimeUtcMilli);

      // must not be able to set expiry time to before current time
      if (
        newExpiryTimeUtcMilli &&
        newExpiryTimeUtcMilli < new Date().getTime()
      ) {
        return;
      }
      formikSetFieldValue("expiryTimeUtcMilli", newExpiryTimeUtcMilli);
    },
    [formikSetFieldValue]
  );

  const startDate = useMemo(() => {
    if (formikValuesStartTimeUtcMilli) {
      return new Date(formikValuesStartTimeUtcMilli);
    }
    return null;
  }, [formikValuesStartTimeUtcMilli]);

  const endDate = useMemo(() => {
    if (formikValuesExpiryTimeUtcMilli) {
      return new Date(formikValuesExpiryTimeUtcMilli);
    }
    return null;
  }, [formikValuesExpiryTimeUtcMilli]);

  const resetStartAndExpiryDate = useCallback(() => {
    formikSetFieldValue("startTimeUtcMilli", null);
    formikSetFieldValue("expiryTimeUtcMilli", null);
  }, [formikSetFieldValue]);

  const [canShowStartAndExpiryTimeSetter, setCanShowStartAndExpiryTimeSetter] =
    React.useState(false);

  useEffect(() => {
    if (
      formikValuesMonthsAvailable &&
      formikValuesMonthsAvailable.length === 1
    ) {
      setCanShowStartAndExpiryTimeSetter(true);
    } else {
      resetStartAndExpiryDate();
      setCanShowStartAndExpiryTimeSetter(false);
    }
  }, [formikValuesMonthsAvailable, resetStartAndExpiryDate]);

  const fullYear = useMemo(() => {
    let selectedMonthNum = 1;

    if (formikValuesMonthsAvailable && formikValuesMonthsAvailable.length > 0) {
      const month = formikValuesMonthsAvailable[0];
      selectedMonthNum = monthsArr.indexOf(month);
    }

    // handle the case if start date is in another year
    let fullYear = new Date().getFullYear();

    // if the month is before the current or after month, then it's for next year
    if (selectedMonthNum < new Date().getMonth()) {
      fullYear += 1;
    }

    if (formikValuesStartTimeUtcMilli) {
      fullYear = new Date(formikValuesStartTimeUtcMilli).getFullYear();
    }

    return fullYear;
  }, [formikValuesMonthsAvailable, formikValuesStartTimeUtcMilli]);

  const monthAndYearLabel = useMemo(() => {
    if (
      formikValuesMonthsAvailable &&
      formikValuesMonthsAvailable.length === 1
    ) {
      // ensure only first letter is capital the rest are lowercase
      return (
        formikValuesMonthsAvailable[0].charAt(0).toUpperCase() +
        formikValuesMonthsAvailable[0].slice(1).toLowerCase() +
        " " +
        fullYear
      );
    }
    return "";
  }, [formikValuesMonthsAvailable, fullYear]);

  const minDate = useMemo(() => {
    let selectedMonthNum = 1;

    if (formikValuesMonthsAvailable && formikValuesMonthsAvailable.length > 0) {
      const month = formikValuesMonthsAvailable[0];
      selectedMonthNum = monthsArr.indexOf(month);
    }

    return new Date(fullYear, selectedMonthNum, 1);
  }, [formikValuesMonthsAvailable, fullYear]);

  const maxDate = useMemo(() => {
    return lastDayOfMonth(minDate);
  }, [minDate]);

  return (
    <div>
      <div className={classes.toggleSwitchWithTitle}>
        <div
          className={classes.companyInputWithToolTipContainer}
          style={{ marginRight: "10px" }}
        >
          <h1 style={{ marginBottom: "0px", marginTop: "0px" }}>
            {enumStrings.disocuntText.onlyShowDuringCertainMonthsText}{" "}
          </h1>
          <ShowInAppSwitch
            checked={isMonthToggleChecked}
            onChange={handleMonthToggleSwitch}
            id="IsMonthToggle"
            name="IsMonthToggle"
          />
          <div
            style={{
              marginTop: "0px",
            }}
          >
            <HoverToolTip
              description={
                enumStrings.disocuntText.tooltip.monthToggle +
                " " +
                enumStrings.disocuntText.tooltip.startAndExpiryDate
              }
              showIt
            />
          </div>
        </div>
      </div>
      {isMonthToggleChecked ? (
        <div style={{ textAlign: "center", alignItems: "center" }}>
          {Object.values(MonthEnum).map((val, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleMonthSelection(e.target.checked, val)
                    }
                    checked={(formikValuesMonthsAvailable || []).includes(val)}
                    className={classes.dayCheckbox}
                  />
                }
                label={val.slice(0, 3)}
                labelPlacement="bottom"
              />
            );
          })}
          <FormHelperText
            component="legend"
            style={{
              color: "#f44336",
              paddingLeft: "10px",
              paddingTop: "5px",
              textAlign: "center",
            }}
          >
            {formikErrors.monthsAvailable}
          </FormHelperText>
          {canShowStartAndExpiryTimeSetter ? (
            <div
              style={{
                marginTop: "0px",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                  paddingLeft: "30px",
                  marginRight: "10px",
                }}
              >
                <span
                  style={{
                    margin: "0px",
                    fontSize: "24px",
                  }}
                >
                  Optionally Set Start and Expiry Date in {monthAndYearLabel}
                </span>
                <HoverToolTip
                  description={
                    enumStrings.disocuntText.tooltip.startAndExpiryDate
                  }
                  showIt
                />
              </div>
              <div id="root-portal" className={classes.rootDateRangePicker}>
                <DatePicker
                  className={classes.dateRangePicker}
                  portalId="root-portal"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  selectsRange={true}
                  minDate={minDate}
                  maxDate={maxDate}
                  startDate={startDate}
                  endDate={endDate}
                  popperPlacement="top"
                  onChange={(update) => handleSetStartAndExpiryDate(update)}
                  dateFormat="MMM d, yyyy"
                  isClearable
                  onFocus={(e) => e.target.blur()}
                  placeholderText="Click here to set dates"
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default OnlyOfferDuringCertianMonthsForm;

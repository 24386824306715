import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  ChamberMemberReturn,
  GetAllChamberMembersQuery,
  GetAllRewardCampaignsForChamberQuery,
  RewardCampaignReturn,
} from "../../../graphql/types";
import { getLocalTimestamp } from "../Root/RootAdmin";
import { Button, Chip, makeStyles, Modal, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { RedirectUri } from "../../enumStrings";
import theme from "../../../themes";
import { EndCampaignModal } from "./updateChamberRewardCampaign";
import LogoImage from "../../common/utilities/LogoImage";
import { checkIfRewardCampaignIsActive } from "../../common/utilities/rewardCampaign";
import ParticipantTable from "./ParticipantTable";
import HoverToolTip from "../../common/utilities/HoverToolTip";
import enumStrings from "../../enumStrings";

type OmittedRedeemSettingsRewardCampaign = RewardCampaignReturn & {
  chamberMemberInfo: ChamberMemberReturn;
};
type AllRewardCampaignDataType =
  | GetAllRewardCampaignsForChamberQuery["getAllRewardCampaignsForChamber"]
  | undefined
  | null;
type TableRewardCampaignDataType = OmittedRedeemSettingsRewardCampaign;
type AllTableRewardCampaignDataType = TableRewardCampaignDataType[];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: AllTableRewardCampaignDataType,
  comparator: (a: any, b: any) => number
): AllTableRewardCampaignDataType {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  id: string;
  label: string;
  alignCenter?: boolean;
  preventTextWrap?: boolean;
}

interface FullPageRewardCampaignTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function FullPageRewardCampaignTableHead(
  props: FullPageRewardCampaignTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              whiteSpace: headCell.preventTextWrap ? "nowrap" : undefined,
            }}
            key={headCell.id}
            align={headCell.alignCenter ? "center" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells: HeadCell[] = [
  {
    id: "memberLogo",
    label: "",
  },
  {
    id: "name",
    label: "Sponsoring Member",
  },
  {
    id: "campaignName",
    label: "Campaign Name",
  },
  {
    id: "prizeDescription",
    label: "Prize Description",
  },
  {
    id: "startDateUtcMilli",
    label: "Start Date",
    preventTextWrap: true,
  },
  {
    id: "endDateUtcMilli",
    label: "End Date",
    preventTextWrap: true,
  },
  {
    id: "prizeDrawParticipants",
    label: "Chamber Perks App™ Users in Prize Draw",
  },
  {
    id: "status",
    label: "Campaign Status",
  },
  {
    id: "updateActiveCampaign",
    label: "",
  },
];

const useStyles = makeStyles(() => ({
  modal: {
    border: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    height: "700px",
    borderRadius: "5px",
    textAlign: "center",
    width: "740px",
    maxWidth: "90%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#cfd8dc",
    padding: theme.spacing(4),
    position: "relative",
  },
  modalButton: {
    margin: "10px",
    marginTop: "20px",
    width: "230px",
    backgroundColor: "#e1a731",
    color: "#e9e9e9",
    fontWeight: "bold",
    height: "40px",
  },
}));

export default function FullPageRewardCampaignsTable({
  allRewardCampaignData,
  allChamberMemberData,
  mode,
}: {
  allChamberMemberData: GetAllChamberMembersQuery | undefined;
  allRewardCampaignData: AllRewardCampaignDataType;
  mode: "active" | "inactive";
}) {
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>("chamberMemberName");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    setOrder("desc");
    setOrderBy("startDateUtcMilli");
  }, [allRewardCampaignData]);

  const allTableRewardCampaignData: AllTableRewardCampaignDataType =
    React.useMemo(() => {
      if (
        !allRewardCampaignData ||
        !allChamberMemberData?.getAllChamberMembers ||
        (allChamberMemberData?.getAllChamberMembers || []).length === 0
      ) {
        return [];
      }

      const allRewardCampaignsArr = (allRewardCampaignData || []).map(
        (rewardCampaignData) => ({
          ...rewardCampaignData,
          chamberMemberInfo: (
            allChamberMemberData?.getAllChamberMembers || []
          ).find(
            (chamberMember) =>
              chamberMember.id === rewardCampaignData.sponsoringChamberMemberId
          ),
        })
      ) as AllTableRewardCampaignDataType;

      if (mode === "active") {
        return allRewardCampaignsArr.filter((campaign) => {
          const { campaignIsActive, campaignIsUpcoming } =
            checkIfRewardCampaignIsActive({
              campaign,
            });

          return campaignIsActive || campaignIsUpcoming;
        });
      }

      return allRewardCampaignsArr.filter((campaign) => {
        const { campaignIsActive, campaignIsUpcoming } =
          checkIfRewardCampaignIsActive({
            campaign,
          });

        return !campaignIsActive && !campaignIsUpcoming;
      });
    }, [allChamberMemberData, allRewardCampaignData, mode]);

  const visibleRows = React.useMemo(
    () => stableSort(allTableRewardCampaignData, getComparator(order, orderBy)),
    [allTableRewardCampaignData, order, orderBy]
  );

  const [memberModalOpenedForCampaignsId, setMemberModalOpenedForCampaignsId] =
    React.useState<string>("");

  const [participantModalOpen, setParticipantModalOpen] =
    React.useState<boolean>(false);

  const cpaUsersParticipatingInDrawList = React.useMemo(() => {
    if (
      memberModalOpenedForCampaignsId.length > 0 &&
      (allRewardCampaignData || []).length > 0
    ) {
      return (
        <ParticipantTable
          data={
            (allRewardCampaignData || []).find(
              (campaign) => campaign.id === memberModalOpenedForCampaignsId
            )?.prizeDrawParticipants || []
          }
        />
      );
    }

    return <div>None so far</div>;
  }, [allRewardCampaignData, memberModalOpenedForCampaignsId]);

  const [endCampaignModalOpen, setEndCampaignModalOpen] = React.useState(false);
  const [endModalCampaignId, setEndModalCampaignId] = React.useState("");

  if ((allTableRewardCampaignData || []).length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >
          {mode === "active"
            ? "No active reward campaign found"
            : "No previous reward campaigns found"}
        </Typography>
      </div>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {endCampaignModalOpen && (
        <EndCampaignModal
          campaignId={endModalCampaignId}
          closeModal={() => setEndCampaignModalOpen(false)}
        />
      )}

      {participantModalOpen && (
        <Modal className={classes.modal} open={participantModalOpen}>
          <div
            className={classes.paper}
            style={{ justifyContent: "space-between" }}
          >
            <h1 style={{ fontWeight: "bold" }}>
              Chamber Perks App™ Users in Prize Draw
            </h1>
            <div
              style={{
                padding: "50px",
                paddingBottom: 0,
                paddingTop: 20,
                textAlign: "left",
                fontSize: "16px",
                height: "100%",
                overflowY: "scroll",
              }}
            >
              {cpaUsersParticipatingInDrawList}
            </div>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Button
                className={classes.modalButton}
                style={{
                  backgroundColor: "#37474f",
                  color: "#e9e9e9",
                }}
                onClick={() => setParticipantModalOpen(false)}
                variant="contained"
              >
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "16px",
          boxShadow: "unset",
        }}
      >
        <TableContainer sx={{ maxHeight: "80vh" }}>
          <Table
            sx={{
              minWidth: 750,
              maxWidth: 1300,
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <FullPageRewardCampaignTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((rewardCampaignInfo) => {
                const { campaignIsActive, campaignIsUpcoming } =
                  checkIfRewardCampaignIsActive({
                    campaign: rewardCampaignInfo,
                  });

                const openRewardUpdatePage = () => {
                  if (rewardCampaignInfo?.isCreatedByCpaAdmin) {
                    return;
                  }

                  if (mode === "active") {
                    history.push(RedirectUri.updateReward, {
                      rewardCampaignId: rewardCampaignInfo.id,
                    });
                  }
                };

                let cursorType = mode === "active" ? "pointer" : "default";

                if (rewardCampaignInfo?.isCreatedByCpaAdmin) {
                  cursorType = "default";
                }

                return (
                  <TableRow
                    hover
                    style={{
                      cursor: "unset",
                    }}
                    onClick={undefined}
                    role="checkbox"
                    tabIndex={-1}
                    key={rewardCampaignInfo.id}
                  >
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: cursorType,
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        if (mode === "active") {
                          openRewardUpdatePage();
                        }
                      }}
                    >
                      <LogoImage
                        logoSource={
                          rewardCampaignInfo?.chamberMemberInfo?.logo || ""
                        }
                        size="large"
                        squared
                        showPointerCursor
                        key={
                          rewardCampaignInfo?.chamberMemberInfo?.id ||
                          "Deleted Member"
                        }
                        chamberName={
                          rewardCampaignInfo?.chamberMemberInfo?.name ||
                          "Deleted Member"
                        }
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: cursorType,
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        openRewardUpdatePage();
                      }}
                    >
                      {rewardCampaignInfo?.chamberMemberInfo?.name ||
                        "Deleted Member"}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: cursorType,
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        openRewardUpdatePage();
                      }}
                    >
                      {rewardCampaignInfo.campaignName}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: cursorType,
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        openRewardUpdatePage();
                      }}
                    >
                      {rewardCampaignInfo.prizeDescription}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: cursorType,
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        openRewardUpdatePage();
                      }}
                    >
                      {getLocalTimestamp(
                        rewardCampaignInfo.startDateUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "16px",
                        cursor: cursorType,
                      }}
                      component="th"
                      scope="row"
                      onClick={() => {
                        openRewardUpdatePage();
                      }}
                    >
                      {getLocalTimestamp(
                        rewardCampaignInfo.endDateUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {rewardCampaignInfo?.isCreatedByCpaAdmin ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            height: "100%",
                            flex: 1,
                          }}
                        >
                          N/A
                          <div style={{ marginLeft: 7 }}>
                            <HoverToolTip
                              description={`This campaign is created by Chamber Perks App™ and the reward winner will be selected by the Chamber Perks App™ team.\n\nPlease contact ${enumStrings.supportContactEmail} if you have any questions.`}
                              showIt
                              disablePadding
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          {
                            (rewardCampaignInfo?.prizeDrawParticipants || [])
                              .length
                          }
                          <br />
                          <br />
                        </>
                      )}

                      {(rewardCampaignInfo?.prizeDrawParticipants || [])
                        .length > 0 && (
                        <Button
                          className={classes.modalButton}
                          style={{
                            backgroundColor: "#37474f",
                            color: "#e9e9e9",
                            width: "120px",
                            height: "50px",
                            fontSize: "11.5px",
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={() => {
                            setMemberModalOpenedForCampaignsId(
                              rewardCampaignInfo.id
                            );
                            setParticipantModalOpen(true);
                          }}
                          variant="contained"
                        >
                          Open List And Pick Winner
                        </Button>
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px", textAlign: "center" }}
                      component="th"
                      scope="row"
                    >
                      {campaignIsActive || campaignIsUpcoming ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Chip
                            label={
                              Date.now() < rewardCampaignInfo.startDateUtcMilli
                                ? "Upcoming"
                                : "Active"
                            }
                            variant="outlined"
                            size="medium"
                            style={{
                              color:
                                Date.now() <
                                rewardCampaignInfo.startDateUtcMilli
                                  ? "#525100"
                                  : "#34694F",

                              backgroundColor:
                                Date.now() <
                                rewardCampaignInfo.startDateUtcMilli
                                  ? "#FEF9E8"
                                  : "#DCEEDC",
                              borderColor:
                                Date.now() <
                                rewardCampaignInfo.startDateUtcMilli
                                  ? "#525100"
                                  : "#34694F",
                            }}
                          />
                          {!rewardCampaignInfo?.isCreatedByCpaAdmin && (
                            <Button
                              className={classes.modalButton}
                              style={{
                                backgroundColor: "#37474f",
                                color: "#e9e9e9",
                                width: "70px",
                                height: "30px",
                                fontSize: "11.5px",
                                margin: 0,
                                padding: 0,
                                marginTop: 10,
                              }}
                              onClick={() => {
                                setEndCampaignModalOpen(() => {
                                  setEndModalCampaignId(rewardCampaignInfo.id);
                                  return true;
                                });
                              }}
                              variant="contained"
                            >
                              End
                            </Button>
                          )}
                        </div>
                      ) : (
                        <Chip
                          label="Ended"
                          variant="outlined"
                          size="medium"
                          style={{
                            color: "#727272",
                            backgroundColor: "#E3E4E6",
                            borderColor: "#727272",
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {campaignIsActive || campaignIsUpcoming ? (
                        <Button
                          type="submit"
                          size="large"
                          color="primary"
                          variant="contained"
                          style={{
                            backgroundColor: "#E1A731",
                            fontSize: "15px",
                            fontWeight: "bold",
                            borderRadius: "10px",
                            width: "90px",
                            height: "40px",
                            display: rewardCampaignInfo?.isCreatedByCpaAdmin
                              ? "none"
                              : undefined,
                          }}
                          onClick={() => {
                            history.push(RedirectUri.updateReward, {
                              rewardCampaignId: rewardCampaignInfo.id,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

import { useCallback, useEffect, useState } from "react";
import {
  ChamberMemberCategory,
  useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation,
} from "../../../graphql/types";

const useHandleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement = ({
  categoryId,
  chamberMemberHasAcceptedAgreement,
}: {
  categoryId: ChamberMemberCategory;
  chamberMemberHasAcceptedAgreement: boolean | undefined | null;
}) => {
  const [
    chamberMembeCategoryAdvertisingBannerAgreementIsAccepted,
    setChamberMembeCategoryAdvertisingBannerAgreementIsAccepted,
  ] = useState(false);

  useEffect(() => {
    if (chamberMemberHasAcceptedAgreement) {
      setChamberMembeCategoryAdvertisingBannerAgreementIsAccepted(true);
    }
  }, [chamberMemberHasAcceptedAgreement]);

  const [
    acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember,
  ] =
    useAcceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMemberMutation();

  const handleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement =
    useCallback(async () => {
      acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember(
        {
          variables: {
            categoryId,
          },
          refetchQueries: [
            "getActiveCategoryAdvertisingBannersForChamberMember",
          ],
        }
      );

      setChamberMembeCategoryAdvertisingBannerAgreementIsAccepted(true);
    }, [
      acceptChamberMemberAgreementForActiveCategoryAdvertisingBannerOfChamberMember,
      categoryId,
    ]);

  return {
    chamberMembeCategoryAdvertisingBannerAgreementIsAccepted,
    handleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement,
  };
};

export default useHandleAcceptanceOfChamberMembeCategoryAdvertisingBannerAgreement;

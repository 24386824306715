import React, { ReactElement, useEffect, useState } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Login from "./components/pages/Login/Login";
import Root from "./components/pages/Root/Root";
import enumStrings, { RedirectUri } from "./components/enumStrings";
import useInitialAuth from "./components/common/Hooks/useInitalAuth";
import getApolloClient from "./apolloClient";
import { ApolloClient, ApolloProvider } from "@apollo/client";
import GeneralError from "./components/common/ErrorPages/GeneralError";
import useRedirect from "./components/common/Hooks/useRedirect";
import ForgotPassword from "./components/pages/Login/ForgotPassword";
import NewPassword from "./components/pages/Login/NewPassword";
import DeleteCPAData from "./components/common/DeleteCPAData";
import CPAPrivacyPolicy from "./components/common/CPAPrivacyPolicy";
import LoggedInApp from "./LoggedInApp";

const App = (): ReactElement => {
  const [client, setClient] = useState(
    null as unknown as ApolloClient<unknown>
  );
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useInitialAuth(setIsLoggedIn);
  const redirectToError = useRedirect(RedirectUri.errorGeneral);

  // initialize graphql apollo client
  useEffect(() => {
    getApolloClient(redirectToError).then((newClient) => {
      setClient(newClient);
      setIsLoading(false);
    });
  }, [isLoggedIn, redirectToError]);

  if (isLoading || isLoggedIn === null) {
    return <div>{enumStrings.loadingText}</div>;
  }
  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route exact path={RedirectUri.errorGeneral}>
          <GeneralError />
        </Route>
        <Route exact path={RedirectUri.home}>
          <Root />
        </Route>
        <Route exact path={RedirectUri.deleteCpaData}>
          <DeleteCPAData />
        </Route>
        <Route exact path={RedirectUri.cpaPrivacyPolicy}>
          <CPAPrivacyPolicy />
        </Route>
        <Route exact path={RedirectUri.newPassword}>
          <NewPassword />
        </Route>
        {isLoggedIn === false ? (
          <div>
            {isLoggedIn === false &&
            window.location.href !== RedirectUri.login ? (
              <Route exact path={RedirectUri.forgotPassword}>
                <ForgotPassword />
              </Route>
            ) : null}
            {isLoggedIn === false &&
            window.location.href !== RedirectUri.forgotPassword ? (
              <Route exact path={RedirectUri.login}>
                <Login />
              </Route>
            ) : null}
          </div>
        ) : (
          <LoggedInApp />
        )}
      </Switch>
    </ApolloProvider>
  );
};

export default App;

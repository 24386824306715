import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import {
  GetAllAdminUsersForPerkPartnerDiscountQuery,
  useCreatePerkPartnerDiscountAdminUserMutation,
  useDeletePerkPartnerDiscountAdminUserMutation,
  useGetAllAdminUsersForPerkPartnerDiscountQuery,
} from "../../../graphql/types";
import Loader from "../Loader/Loader";
import { Button, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CSVLink } from "react-csv";
import enumStrings from "../../enumStrings";
import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { getLocalTimestamp } from "../../pages/Root/RootAdmin";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import ConfirmationModal, {
  ButtonConfigType,
  YesNoButtonConfig,
} from "../ConfirmationModal/ConfirmationModal";

export default function PerkPartnerDiscountAdminUsers({
  perkPartnerDiscountId,
  perkPartnerCompanyName,
}: {
  perkPartnerDiscountId: string;
  perkPartnerCompanyName: string;
}) {
  const { data } = useGetAllAdminUsersForPerkPartnerDiscountQuery({
    variables: {
      perkPartnerDiscountId,
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  const [
    createPerkPartnerDiscountAdminUserModalIsOpen,
    setCreatePerkPartnerDiscountAdminUserModalIsOpen,
  ] = useState(false);

  const openCreatePerkPartnerDiscountAdminUserModal = useCallback(() => {
    setCreatePerkPartnerDiscountAdminUserModalIsOpen(true);
  }, []);

  const closeCreatePerkPartnerDiscountAdminUserModal = useCallback(() => {
    setCreatePerkPartnerDiscountAdminUserModalIsOpen(false);
  }, []);

  if (isLoading) {
    return (
      <div style={{ marginTop: "46px" }}>
        <Loader />
      </div>
    );
  }

  return (
    <Grid
      container
      style={{
        marginTop: "56px",
      }}
      spacing={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {createPerkPartnerDiscountAdminUserModalIsOpen && (
        <CreatePerkPartnerDiscountAdminUserModal
          open={createPerkPartnerDiscountAdminUserModalIsOpen}
          handleClose={closeCreatePerkPartnerDiscountAdminUserModal}
          perkPartnerCompanyName={perkPartnerCompanyName}
          perkPartnerDiscountId={perkPartnerDiscountId}
        />
      )}
      <h1 style={{ marginBottom: "0px", textAlign: "center" }}>
        Perk Partner Admin Users
      </h1>

      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={openCreatePerkPartnerDiscountAdminUserModal}
        style={{
          backgroundColor: "#E1A731",
          fontSize: "16px",
          fontWeight: "bold",
          borderRadius: "10px",
          width: "225px",
          marginTop: "10px",
          marginBottom: "39px",
          height: "50px",
        }}
      >
        Create Admin User
      </Button>
      {data?.getAllAdminUsersForPerkPartnerDiscount &&
      data.getAllAdminUsersForPerkPartnerDiscount.length > 0 ? (
        <PerkPartnerDiscountAdminUsersTable
          allChartData={data.getAllAdminUsersForPerkPartnerDiscount}
        />
      ) : (
        <h3 style={{ marginBottom: "39px" }}>No Admin Users</h3>
      )}
    </Grid>
  );
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(
  array: any[],
  comparator: (a: any, b: any) => number
): any[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export enum DeltaType {
  INCREASE = "increase",
  DECREASE = "decrease",
}

interface HeadCell {
  id: string;
  label: string;
  defaultSort?: boolean;
  sortOrder?: Order;
}

interface FullPageChamberMemberTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
}

function PerkPartnerDiscountAdminUsersTableHeader(
  props: FullPageChamberMemberTableProps
) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              fontWeight: "bold",
              fontSize: "20px",
            }}
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  // @ts-ignore
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells: HeadCell[] = [
  {
    id: "adminConsoleLoginEmail",
    label: "Email",
  },
  {
    id: "createdAtUtcMilli",
    label: "Creation Date",
    defaultSort: true,
    sortOrder: "desc",
  },
  {
    id: "delete",
    label: "",
  },
];

function PerkPartnerDiscountAdminUsersTable({
  allChartData,
}: {
  allChartData: GetAllAdminUsersForPerkPartnerDiscountQuery["getAllAdminUsersForPerkPartnerDiscount"];
}) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<string>(headCells[0].id);

  React.useEffect(() => {
    for (const cell of headCells) {
      if (cell.defaultSort) {
        setOrderBy(cell.id);
        if (cell.sortOrder) {
          setOrder(cell.sortOrder);
        }
        break;
      }
    }

    return;
    // @ts-ignore
    // eslint-disable-next-line
  }, []); // this is left empty to ensure it ONLY runs on table mount and only then and never again

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(allChartData || [], getComparator(order, orderBy)),
    [allChartData, order, orderBy]
  );

  const [
    perkPartnerAdminUserToBeDeletedId,
    setPerkPartnerAdminUserToBeDeletedId,
  ] = useState("");

  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] =
    useState(false);

  const openDeleteConfirmationModal = (idOfUserToBeDeleted: string): void => {
    setPerkPartnerAdminUserToBeDeletedId(idOfUserToBeDeleted);
    setDeleteConfirmationModalIsOpen(true);
  };

  const closeDeleteConfirmationModal = (): void => {
    setPerkPartnerAdminUserToBeDeletedId("");
    setDeleteConfirmationModalIsOpen(false);
  };

  const [deleteLoading, setLoadingDelete] = useState(false);

  const [deletePerkPartnerDiscountAdminUser] =
    useDeletePerkPartnerDiscountAdminUserMutation();

  const yesButtonAction = useCallback(async (): Promise<void> => {
    setLoadingDelete(true);
    await deletePerkPartnerDiscountAdminUser({
      variables: {
        id: perkPartnerAdminUserToBeDeletedId,
      },
      refetchQueries: ["getAllAdminUsersForPerkPartnerDiscount"],
      awaitRefetchQueries: true,
    });
    closeDeleteConfirmationModal();
    setLoadingDelete(false);
  }, [deletePerkPartnerDiscountAdminUser, perkPartnerAdminUserToBeDeletedId]);

  const buttonConfig: YesNoButtonConfig = useMemo(() => {
    return {
      buttonType: ButtonConfigType.YesNoConfig,
      noButtonAction: closeDeleteConfirmationModal,
      yesButtonAction,
    };
  }, [yesButtonAction]);

  const emailOfUserToBeDeleted = useMemo(() => {
    const userToBeDeleted = (allChartData || []).find(
      (user) => user.id === perkPartnerAdminUserToBeDeletedId
    );
    return userToBeDeleted?.adminConsoleLoginEmail || "";
  }, [allChartData, perkPartnerAdminUserToBeDeletedId]);

  return (
    <Box
      sx={{
        maxWidth: "95%",
        margin: "auto",
      }}
    >
      {" "}
      {deleteConfirmationModalIsOpen && (
        <ConfirmationModal
          open={deleteConfirmationModalIsOpen}
          loading={deleteLoading}
          handleClose={closeDeleteConfirmationModal}
          message={`Are you sure you want to delete ${emailOfUserToBeDeleted}?`}
          buttonConfig={buttonConfig}
        />
      )}
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          boxShadow: "unset",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: "400px",
            minHeight: "100px",
          }}
        >
          <Table
            sx={{
              margin: "auto",
            }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
            style={{
              tableLayout: undefined,
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <PerkPartnerDiscountAdminUsersTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />

            <TableBody
              style={{
                position: "relative",
              }}
            >
              {visibleRows.map((perkPartnerDiscountAdminUser, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell
                      style={{ fontSize: "16px" }}
                      component="th"
                      scope="row"
                    >
                      {perkPartnerDiscountAdminUser.adminConsoleLoginEmail}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {getLocalTimestamp(
                        perkPartnerDiscountAdminUser.createdAtUtcMilli || 0,
                        true
                      )}
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        openDeleteConfirmationModal(
                          perkPartnerDiscountAdminUser.id
                        )
                      }
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

const useCreatePerkPartnerDiscountAdminUserModalStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      paper: {
        borderRadius: "5px",
        textAlign: "center",
        width: "500px",
        minHeight: "300px",
        maxWidth: "90%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#cfd8dc",
        padding: theme.spacing(2, 4, 3),
      },
      modal: {
        border: "unset",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      yesButton: {
        margin: "10px",
        width: "100px",
        backgroundColor: "#e1a731",
        color: "#e9e9e9",
        fontWeight: "bold",
      },
      customButton: {
        margin: "10px",
        backgroundColor: "#e1a731",
        color: "#e9e9e9",
        fontWeight: "bold",
      },
      noButton: {
        margin: "10px",
        width: "100px",
        backgroundColor: "#ff0000",
        color: "#e9e9e9",
        fontWeight: "bold",
      },
      okButton: {
        margin: "10px",
        width: "100px",
        backgroundColor: "#37474f",
        color: "#e9e9e9",
        fontWeight: "bold",
      },
      companyInput: {
        backgroundColor: "#F2F2F6",
        paddingBottom: "10px",
        borderRadius: "15px",
        marginTop: "50px",
        width: "431px",
        height: "55px",
        maxWidth: "85%",
        "& .MuiOutlinedInput-root": {
          borderRadius: "15px",
          "& fieldset": {
            borderColor: "black",
            borderRadius: "15px",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#E1A731",
          },
        },
        "& label.Mui-focused": {
          color: "#E1A731",
        },
        "& label.Mui": {
          color: "black",
        },
      },
      invalidError: {
        textAlign: "center",
        color: "red",
      },
    })
);

const validationSchema = yup.object({
  perkPartnerDiscountAdminConsoleLoginEmail: yup
    .string()
    .email("Not a valid email")
    .required("Dashboard email username is required"),
});

const initialValues = {
  perkPartnerDiscountAdminConsoleLoginEmail: "",
};

const CreatePerkPartnerDiscountAdminUserModal = ({
  open,
  handleClose,
  perkPartnerCompanyName,
  perkPartnerDiscountId,
}: {
  open: boolean;
  handleClose: () => void;
  perkPartnerCompanyName: string;
  perkPartnerDiscountId: string;
}) => {
  const classes = useCreatePerkPartnerDiscountAdminUserModalStyles();

  const [invalidError, setInvalidError] = useState(false);
  const [customError, setCustomError] = useState(
    "An Error Occured, Please try again."
  );
  const [
    plusAddressedEmailSuggestionModalIsOpen,
    setPlusAddressedEmailSuggestionModalIsOpen,
  ] = useState(false);

  const [suggestedPlusAddressedEmail, setSuggestedPlusAddressedEmail] =
    useState("");

  const [
    loadingCreatePerkPartnerDiscountAdminUserModal,
    setLoadingCreatePerkPartnerDiscountAdminUserModal,
  ] = useState(false);
  const [
    passwordForNewPerkPartnerDiscountAdminUser,
    setPasswordForNewPerkPartnerDiscountAdminUser,
  ] = useState("");
  const [
    successfullyCreatedPerkPartnerDiscountAdminUser,
    setSuccessfullyCreatedPerkPartnerDiscountAdminUser,
  ] = useState(false);

  const [createPerkPartnerDiscountAdminUser] =
    useCreatePerkPartnerDiscountAdminUserMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (input) => {
      if (!perkPartnerDiscountId) {
        return;
      }

      setLoadingCreatePerkPartnerDiscountAdminUserModal(true);
      // api call here
      const { data } = await createPerkPartnerDiscountAdminUser({
        variables: {
          input: {
            adminConsoleLoginEmail:
              input.perkPartnerDiscountAdminConsoleLoginEmail,
            perkPartnerDiscountId,
          },
        },
        refetchQueries: ["getAllAdminUsersForPerkPartnerDiscount"],
        awaitRefetchQueries: true,
      });

      if (
        data?.createPerkPartnerDiscountAdminUser
          .perkPartnerDiscountAdminUserCreatedSuccessfully === false
      ) {
        setInvalidError(true);
        setCustomError(
          `Unable to create perk partner admin user. ${
            (
              data?.createPerkPartnerDiscountAdminUser.failureReason || ""
            ).includes("UsernameExistsException")
              ? "An account with the given email already exists."
              : data?.createPerkPartnerDiscountAdminUser.failureReason || ""
          }`
        );

        if (
          (
            data?.createPerkPartnerDiscountAdminUser.failureReason || ""
          ).includes("UsernameExistsException")
        ) {
          setSuggestedPlusAddressedEmail(
            (data?.createPerkPartnerDiscountAdminUser.failureReason || "")
              .trim()
              .split(" ")[1]
          );
          setPlusAddressedEmailSuggestionModalIsOpen(true);
        }

        setLoadingCreatePerkPartnerDiscountAdminUserModal(false);
        return;
      }

      setPasswordForNewPerkPartnerDiscountAdminUser(
        data?.createPerkPartnerDiscountAdminUser.password || ""
      );
      setSuccessfullyCreatedPerkPartnerDiscountAdminUser(
        data?.createPerkPartnerDiscountAdminUser
          .perkPartnerDiscountAdminUserCreatedSuccessfully || false
      );
      setLoadingCreatePerkPartnerDiscountAdminUserModal(false);

      const button = document.getElementById(
        "new-perk-partner-admin-user-modal"
      );
      button?.click();
    },
  });

  return (
    <>
      <Modal
        className={classes.modal}
        open={plusAddressedEmailSuggestionModalIsOpen}
        onClose={() => setPlusAddressedEmailSuggestionModalIsOpen(false)}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h3
            style={{
              whiteSpace: "pre-wrap",
            }}
          >
            A dashboard account with the email username{" "}
            <span style={{ fontWeight: "bold" }}>
              {formik.values.perkPartnerDiscountAdminConsoleLoginEmail}
            </span>{" "}
            already exists.
            {"\n\n"}An alternative email username that can be used to create an
            account for the dashboard is{" "}
            <span style={{ fontWeight: "bold" }}>
              {suggestedPlusAddressedEmail}
            </span>
            {"\n"}This alternative email username uses plus addressing; all
            emails sent to this alternative email address will still go to the
            original email's inbox.
            {"\n\n"}Click{" "}
            <span style={{ fontWeight: "bold" }}>Use Alternative</span> to use
            the plus-addressed email username. Otherwise, click{" "}
            <span style={{ fontWeight: "bold" }}>Back</span> to try another
            email username.
          </h3>
          <br />
          <Button
            onClick={() => {
              setInvalidError(false);
              formik.resetForm();
              formik.setFieldValue(
                "perkPartnerDiscountAdminConsoleLoginEmail",
                suggestedPlusAddressedEmail.trim().toLowerCase()
              );
              setPlusAddressedEmailSuggestionModalIsOpen(false);
            }}
            className={classes.customButton}
            variant="contained"
          >
            Use Alternative
          </Button>
          <Button
            onClick={() => {
              setPlusAddressedEmailSuggestionModalIsOpen(false);
            }}
            className={classes.okButton}
            variant="contained"
          >
            Back
          </Button>
        </div>
      </Modal>
      <Modal
        className={classes.modal}
        open={open}
        onClose={
          loadingCreatePerkPartnerDiscountAdminUserModal
            ? () => null
            : handleClose
        }
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          {loadingCreatePerkPartnerDiscountAdminUserModal ? (
            <Loader />
          ) : passwordForNewPerkPartnerDiscountAdminUser.length > 0 &&
            successfullyCreatedPerkPartnerDiscountAdminUser ? (
            <>
              <h3
                style={{
                  whiteSpace: "pre-wrap",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Creation successful!</span>
                {"\n\n"}A CSV file containing the new admin user's email
                username and a temporary password has been automatically
                downloaded. If the download hasn't started click below to
                download.{"\n\n"}
                <span style={{ fontWeight: "bold" }}>Please note:</span> To log
                in to the dashboard, this perk partner admin must use the new
                email username and the temporary password contained in the CSV
                file. They will be prompted to set a permanent password after
                logging in with the temporary password.{"\n\n"}
              </h3>
              <CSVDownloadButton
                username={formik.values.perkPartnerDiscountAdminConsoleLoginEmail.trim()}
                password={passwordForNewPerkPartnerDiscountAdminUser}
                perkPartnerCompanyName={perkPartnerCompanyName}
                handleClose={handleClose}
              />
            </>
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: "100%",
              }}
            >
              <h3 style={{ marginTop: "25px", marginBottom: "-20px" }}>
                Type the dashboard email username for the new perk partner admin
                below and click create.
              </h3>
              <TextField
                type="text"
                label="New Dashboard Email Username *"
                className={classes.companyInput}
                variant="outlined"
                id="perkPartnerDiscountAdminConsoleLoginEmail"
                name="perkPartnerDiscountAdminConsoleLoginEmail"
                value={formik.values.perkPartnerDiscountAdminConsoleLoginEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.perkPartnerDiscountAdminConsoleLoginEmail &&
                  Boolean(
                    formik.errors.perkPartnerDiscountAdminConsoleLoginEmail
                  )
                }
                helperText={
                  formik.touched.perkPartnerDiscountAdminConsoleLoginEmail &&
                  formik.errors.perkPartnerDiscountAdminConsoleLoginEmail
                }
              />
              {invalidError ? (
                <div className={classes.invalidError}>{customError}</div>
              ) : null}
              <div style={{ paddingTop: "20px" }}>
                <Button
                  type="submit"
                  className={classes.yesButton}
                  variant="contained"
                >
                  Create
                </Button>
                <Button
                  onClick={handleClose}
                  className={classes.noButton}
                  variant="contained"
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

const CSVDownloadButton = ({
  password,
  username,
  perkPartnerCompanyName,
  handleClose,
}: {
  password: string;
  username: string;
  perkPartnerCompanyName: string;
  handleClose: () => void;
}): ReactElement => {
  const classes = useCreatePerkPartnerDiscountAdminUserModalStyles();
  const csvData = [
    ["Dashboard Email Username", "Dashboard Temporary Password"],
    [username, password],
  ];
  return (
    <div>
      <CSVLink
        id="new-perk-partner-admin-user-modal"
        data={csvData}
        filename={`${perkPartnerCompanyName} perk partner dashboard credentials.csv`}
        style={{
          textDecoration: "none",
        }}
      >
        <Button
          className={classes.yesButton}
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        >
          {enumStrings.chamberModal.downloadText}
        </Button>
      </CSVLink>
      <Button
        className={classes.yesButton}
        variant="contained"
        onClick={() => {
          handleClose();
        }}
      >
        Close
      </Button>
    </div>
  );
};

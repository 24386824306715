import React, { ReactElement } from "react";
import {
  makeStyles,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@material-ui/core";
import HoverToolTip from "./utilities/HoverToolTip";
import enumStrings from "../enumStrings";
import { FormikErrors } from "formik";
import { DiscountInput } from "../../graphql/types";

const useStyles = makeStyles(() => ({
  notificationRadioGroup: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "5px",
    width: "100%",
  },
  radio: {
    color: "#E1A731!important",
  },
}));

const DiscountRedemptionOptionRadioGroup = ({
  formikSetFieldValue,
  formikValues,
  formikErrors,
}: {
  formikSetFieldValue: (a: string, b: boolean | any[]) => void;
  formikValues: DiscountInput;
  formikErrors: FormikErrors<DiscountInput>;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "475px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "0px",
          marginTop: "40px",
        }}
      >
        <h1 style={{ marginBottom: "0px", textAlign: "center" }}>
          {enumStrings.disocuntText.redemptionOptionText}
        </h1>
        <HoverToolTip
          description={
            enumStrings.disocuntText.redemptionOptionTooltipText.title
          }
          showIt
        />
      </div>
      <RadioGroup
        row
        aria-label="isReoccuring"
        name="isReoccuring"
        id="isReoccuring"
        onChange={(e) => {
          formikSetFieldValue(
            "isReoccuring",
            e.target.value === "1" ? true : false
          );
        }}
        className={classes.notificationRadioGroup}
        value={
          formikValues.isReoccuring === undefined
            ? null
            : formikValues.isReoccuring
            ? 1
            : 0
        }
      >
        {formikValues.isReoccuring === undefined ? (
          <FormHelperText
            component="legend"
            style={{
              color: "#f44336",
              paddingTop: "0px",
              textAlign: "center",
            }}
          >
            {formikErrors.isReoccuring}
          </FormHelperText>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value={1}
            labelPlacement="end"
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center" }}>
                Reoccurring Perk
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={
                    enumStrings.disocuntText.redemptionOptionTooltipText
                      .reoccurring
                  }
                  showIt
                />
              </div>
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            value={0}
            style={{ marginRight: "0px" }}
            control={<Radio className={classes.radio} />}
            label={
              <div style={{ display: "flex", alignItems: "center", margin: 0 }}>
                One Time Perk
                <div style={{ width: "10px" }} />
                <HoverToolTip
                  disablePadding
                  description={
                    enumStrings.disocuntText.redemptionOptionTooltipText.oneTime
                  }
                  showIt
                />
              </div>
            }
            labelPlacement="end"
          />
        </div>
      </RadioGroup>
    </div>
  );
};

export default DiscountRedemptionOptionRadioGroup;

import React from "react";
import { useCSVDownloader, useCSVReader } from "react-papaparse";
export interface DeleteButtonProps {
  dataToExportAsCSV?: any[];
  setDataAfterCSVImport: (data: any[]) => void;
}

function CSVButtons({
  dataToExportAsCSV,
  setDataAfterCSVImport,
}: DeleteButtonProps) {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { CSVReader } = useCSVReader();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      {dataToExportAsCSV && (
        <>
          <CSVDownloader
            type={Type.Link}
            bom={true}
            filename={"PrivateEmails_Export.csv"}
            data={dataToExportAsCSV}
            style={{
              cursor: "pointer",
              backgroundColor: "#E1A731",
              fontWeight: "bold",
              color: "white",
              borderRadius: "10px",
              borderWidth: "0px",
              boxShadow: "black 0px 4px 8px -7px",
              width: "150px",
              height: "35px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Export CSV
          </CSVDownloader>
          <div style={{ width: "20px" }} />
        </>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CSVReader
          onUploadAccepted={(results: any) => {
            setDataAfterCSVImport(results);
          }}
        >
          {({ getRootProps, acceptedFile, ProgressBar }: any) => (
            <>
              <button
                type="button"
                style={{
                  cursor: "pointer",
                  backgroundColor: "#E1A731",
                  fontWeight: "bold",
                  color: "white",
                  borderRadius: "10px",
                  borderWidth: "0px",
                  boxShadow: "black 0px 4px 8px -7px",
                  width: "150px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "5px",
                }}
                {...getRootProps()}
              >
                Import CSV
              </button>
              <ProgressBar />
            </>
          )}
        </CSVReader>
        <CSVDownloader
          type={Type.Link}
          bom={true}
          filename={"PrivateEmails_ImportSample.csv"}
          data={[
            {
              email: "abc@123.com",
            },
            {
              email: "xyz@123.com",
            },
            {
              email: "efg@456.com",
            },
          ]}
          style={{
            fontSize: "10px",
          }}
        >
          Download Import CSV Sample
        </CSVDownloader>
      </div>
    </div>
  );
}

export default CSVButtons;
